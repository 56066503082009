<template>
  <v-row align-content="center" justify="center">
    <v-col cols="12" md="8" lg="8" xl="9" class="text-center">

      <!-- PROJECTS -->
      <v-menu bottom right nudge-bottom="10" transition="scale-transition" offset-y rounded="lg" origin="center center">
        <template v-slot:activator="{ on }">
          <v-btn rounded :dark="getFilters.projects.length > 0" :outlined="getFilters.projects.length == 0" class="ma-2"
            v-on="on">
            <v-avatar left> <v-icon>mdi-book-open-page-variant</v-icon> </v-avatar>Project
            {{
              getFilters.projects.length > 0
              ? " (" + getFilters.projects.length + ")"
              : ""
            }}
          </v-btn>
        </template>
        <v-card width="400" v-on:click.stop>
          <v-card-text>
            <v-col>
              <v-row>
                <v-autocomplete disabled :items="getProjects" outlined dense chips small-chips deletable-chips label="Project"
                  multiple item-text="name" item-value="project_id" v-model="getFilters.projects"
                  @input="handleUpdateFilter"></v-autocomplete>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- ARCHIVES -->
      <v-menu bottom right nudge-bottom="10" transition="scale-transition" offset-y rounded="lg" origin="center center">
        <template v-slot:activator="{ on }">
          <v-btn rounded :dark="getFilters.archives.length > 0" :outlined="getFilters.archives.length == 0" class="ma-2"
            v-on="on">
            <v-avatar left> <v-icon>mdi-archive-outline</v-icon> </v-avatar>Archive
            {{
              getFilters.archives.length > 0
              ? " (" + getFilters.archives.length + ")"
              : ""
            }}
          </v-btn>
        </template>
        <v-card width="400" v-on:click.stop>
          <v-card-text>
            <v-col>
              <v-row>
                <v-autocomplete :items="getArchives" outlined dense chips small-chips deletable-chips label="Archive"
                  multiple item-text="name" item-value="archive_id" v-model="getFilters.archives"
                  @input="handleUpdateFilter"></v-autocomplete>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- COUNTRY/REGION -->
      <v-menu bottom right nudge-bottom="10" transition="scale-transition" offset-y rounded="lg" origin="center center">
        <template v-slot:activator="{ on }">
          <v-btn rounded :dark="getFilters.regions.length > 0" :outlined="getFilters.regions.length == 0" class="ma-2"
            v-on="on">
            <v-avatar left> <v-icon>mdi-earth</v-icon> </v-avatar>Country / Region
            {{
              getFilters.regions.length > 0
              ? " (" + getFilters.regions.length + ")"
              : ""
            }}
          </v-btn>
        </template>
        <v-card width="400" v-on:click.stop>
          <v-card-text>
            <v-col>
              <v-row>
                <v-autocomplete :items="getRegions" outlined dense chips small-chips deletable-chips label="Country / Region"
                  multiple item-text="name" item-value="region_id" v-model="getFilters.regions"
                  @input="handleUpdateFilter"></v-autocomplete>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- COORDINATES -->
      <v-menu bottom right nudge-bottom="10" transition="scale-transition" offset-y rounded="lg" origin="center center">
        <template v-slot:activator="{ on }">
          <v-btn rounded :dark="!checkArrays(getFilters.longitude, [-180, 180]) ||
            !checkArrays(getFilters.latitude, [-90, 90])
            " :outlined="checkArrays(getFilters.longitude, [-180, 180]) &&
    checkArrays(getFilters.latitude, [-90, 90])
    " class="ma-2" v-on="on">
            <v-avatar left> <v-icon>mdi-map-marker</v-icon> </v-avatar>Coordinates
          </v-btn>
        </template>
        <v-card width="400" v-on:click.stop>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col>
                  <v-range-slider v-model="getFilters.latitude" :max="90" :step="5" dense label="Latitude" ticks
                    track-color="#f7f3e3" color="#2B2118" :thumb-size="24" thumb-label="always" :min="-90"
                    class="align-center" @change="handleUpdateFilter"></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-text-field label="Lat. Min" type="number" :max="getFilters.latitude[1] > getFilters.latitude[0]
                      ? 90
                      : getFilters.latitude[1]
                    " min="-90" suffix="°N" outlined :value="getFilters.latitude[0]" @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>

                <v-col sm="6">
                  <v-text-field label="Lat. Max" type="number" :min="getFilters.latitude[1] > getFilters.latitude[0]
                      ? -90
                      : getFilters.latitude[1]
                    " max="90" suffix="°N" outlined :value="getFilters.latitude[1]" @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-range-slider v-model="getFilters.longitude" :max="180" :step="10" dense track-color="#f7f3e3"
                    label="Longitude" ticks color="#2B2118" :thumb-size="24" thumb-label="always" :min="-180"
                    class="align-center" @change="handleUpdateFilter"></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-text-field label="Lon. Min" type="number" v-model="getFilters.longitude[0]"
                    :max="lon_max > lon_min ? 180 : lon_max" min="-180" suffix="°E" outlined @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Lon. Max" type="number" v-model="getFilters.longitude[1]"
                    :min="lon_max > lon_min ? -180 : lon_max" max="180" suffix="°E" outlined @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-menu>

      <!-- ALTITUDE -->
      <v-menu bottom right nudge-bottom="10" transition="scale-transition" offset-y rounded="lg" origin="center center">
        <template v-slot:activator="{ on }">
          <v-btn rounded :dark="!checkArrays(getFilters.altitude, [-7000, 7000])"
            :outlined="checkArrays(getFilters.altitude, [-7000, 7000])" class="ma-2" v-on="on">
            <v-avatar left> <v-icon>mdi-altimeter</v-icon> </v-avatar>Altitude /
            Depth
          </v-btn>
        </template>
        <v-card width="400" v-on:click.stop>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col>
                  <v-range-slider v-model="getFilters.altitude" :max="7000" :step="50" dense track-color="#f7f3e3"
                    label="Altitude / Depth" ticks color="#2B2118" thumb-label="always" :min="-7000" class="align-center"
                    @change="handleUpdateFilter"></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-text-field label="Altitude Min" type="number" v-model="getFilters.altitude[0]" min="-7000" step="50"
                    outlined @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-text-field label="Altitude Max" type="number" v-model="getFilters.altitude[1]" max="7000" step="50"
                    outlined @change="handleUpdateFilter">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-btn rounded color="#2B2118" small dark class="ma-2" @click="handleResetFilters">
        <v-avatar left> <v-icon>mdi-undo-variant</v-icon> </v-avatar>Reset
        filters
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DataLibraryFilters",
  data() {
    return {
      lon_max:180,
      lon_min:-180,
      lat_max:90,
      lat_min:-90
    };
  },
  computed: {
    ...mapGetters("publicdatalibrary", ["getArchives", "getRegions", "getFilters","getProjects"]),
  },
  methods: {
    ...mapActions("publicdatalibrary", ["fetchDatasets", "resetFilters"]),
    checkArrays(array1, array2) {
      var is_same =
        array1.length == array2.length &&
        array1.every(function (element, index) {
          return element === array2[index];
        });
      return is_same;
    },
    handleUpdateFilter() {
      this.fetchDatasets()
    },
    handleResetFilters(){
      this.resetFilters()
      this.handleUpdateFilter()
    }
  },
};
</script>

<style></style>