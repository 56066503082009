import axios from "axios";
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

function setDefaultFilters() {
  return {
    families: [],
    taxons: [],
    pollen_habit: [],
    authortaxons: [],
  };
}

const state = {
  taxons: [],
  authortaxons: [],
  taxon_families: [],
  filtered: [],
  pollen_habits: [],
  habit_types: [],
  filters: setDefaultFilters(),
  taxons_complete: [],
};

const getters = {
  getPollenHabits: (state) => state.pollen_habits,
  getTaxonFamilies: (state) =>
    state.taxon_families.filter(
      (t) => t.family_name != "******ADDITIONAL INFORMATION"
    ),
  getTaxonFamiliesAll: (state) => state.taxon_families,
  getHabitTypes: (state) => state.habit_types,
  getTaxons: (state) =>
    state.taxons.filter((t) => t.taxon_name != "******ADDITIONAL INFORMATION"),
  getTaxonsAll: (state) => state.taxons,
  getTaxonsFilters: (state) => state.filters,
  getAuthorTaxons: (state) => state.authortaxons,
  getTaxonsComplete: (state) => state.taxons_complete,
  getFiltered: (state) =>
    state.filtered.filter(
      (t) => t.id_taxon.id_family.family_name != "******ADDITIONAL INFORMATION"
    ),
};

const actions = {
  // GET REQUESTS
  async fetchTaxons({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/taxon/"));
      commit("setTaxons", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },

  async fetchAuthorTaxons({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/author-taxon/"));
      commit("setAuthorTaxons", response.data);
      commit("setTaxonsComplete");
      commit("applyTaxonsFilters");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  async fetchAuthorTaxons2({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/author-taxon/"));
      commit("setAuthorTaxons", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  async fetchAuthorTaxonsDeep({ commit }, authortaxon_list) {
    try {
      if (authortaxon_list) {
        let formData = new FormData();
        formData.append(
          "authortaxon_list",
          JSON.stringify({ list: authortaxon_list.map(String) })
        );
        let headers = {};
        const response = await axios.post(
          urljoin(HOST_ROOT, "api/author-taxon-deep/filter_data/"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
        return response;
      } else {
        const response = await axios.get(
          urljoin(HOST_ROOT, "api/author-taxon-deep/")
        );
        commit("setAuthorTaxons", response.data);
        commit("applyTaxonsFilters");
        return response;
      }
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  async fetchPollenHabits({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/pollen-habit/"));
      commit("setPollenHabits", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchHabitTypes({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/habit-type/"));
      commit("setHabitTypes", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  async fetchTaxonFamilies({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/taxon-family/"));
      commit("setTaxonFamilies", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* ADD REQUESTS */
  async addPollenHabit({ commit, rootState }, pollen_habit) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/pollen-habit/"),
        {
          pollen_habit_name: pollen_habit.pollen_habit_name,
          pollen_habit_code: pollen_habit.pollen_habit_code,
          id_habit_type: pollen_habit.id_habit_type,
        },
        {
          headers: headers,
        }
      );
      commit("newPollenHabit", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addTaxonFamily({ commit, rootState }, taxon_family) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/taxon-family/"),
        taxon_family,
        {
          headers: headers,
        }
      );
      commit("newTaxonFamily", response.data);
      return response.data;
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addTaxon({ commit, rootState }, taxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/taxon/"),
        taxon,
        {
          headers: headers,
        }
      );
      commit("newTaxon", response.data);
      return response.data;
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addAuthorTaxon({ commit, rootState }, authortaxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/author-taxon/"),
        authortaxon,
        {
          headers: headers,
        }
      );
      commit("newAuthorTaxon", response.data);
      commit("setTaxonsComplete");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  async addAuthorTaxon2({ commit, rootState }, authortaxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/author-taxon/"),
        authortaxon,
        {
          headers: headers,
        }
      );
      commit("newAuthorTaxon", response.data);
      return response.data;
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* UPDATE REQUESTS */
  async updatePollenHabit({ commit, rootState }, pollen_habit) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/pollen-habit/${pollen_habit.id_pollen_habit}/`),
        pollen_habit,
        {
          headers: headers,
        }
      );
      commit("updatePollenHabit", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateTaxonFamily({ commit, rootState }, taxon_family) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/taxon-family/${taxon_family.id_family}/`),
        taxon_family,
        {
          headers: headers,
        }
      );
      commit("updateTaxonFamily", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateTaxon({ commit, rootState }, taxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/taxon/${taxon.id_taxon}/`),
        taxon,
        {
          headers: headers,
        }
      );
      commit("updateTaxon", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateAuthorTaxon({ commit, rootState }, authortaxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/author-taxon/${authortaxon.id_authortaxon}/`),
        authortaxon,
        {
          headers: headers,
        }
      );
      commit("updateAuthorTaxon", response.data);
      commit("setTaxonsComplete");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateAuthorTaxon2({ commit, rootState }, authortaxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/author-taxon/${authortaxon.id_authortaxon}/`),
        authortaxon,
        {
          headers: headers,
        }
      );
      commit("updateAuthorTaxon", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* DELETE REQUESTS */
  async deletePollenHabit({ commit, rootState }, id_pollen_habit) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(
        urljoin(HOST_ROOT, `api/pollen-habit/${id_pollen_habit}/`),
        {
          headers: headers,
        }
      );
      commit("removePollenHabit", id_pollen_habit);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteTaxonFamily({ commit, rootState }, id_taxon_family) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(
        urljoin(HOST_ROOT, `api/taxon-family/${id_taxon_family}/`),
        {
          headers: headers,
        }
      );
      commit("removeTaxonFamily", id_taxon_family);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteTaxon({ commit, rootState }, id_taxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/taxon/${id_taxon}/`), {
        headers: headers,
      });
      commit("removeTaxon", id_taxon);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteAuthorTaxon({ commit, rootState }, id_authortaxon) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(
        urljoin(HOST_ROOT, `api/author-taxon/${id_authortaxon}/`),
        {
          headers: headers,
        }
      );
      commit("removeAuthorTaxon", id_authortaxon);
      commit("setTaxonsComplete");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  resetTaxonsFilters({ commit }) {
    commit("resetTaxonsFilters");
    commit("applyTaxonsFilters");
  },
};

const mutations = {
  setTaxons: (state, taxons) =>
    (state.taxons = taxons.sort((a, b) =>
      a.taxon_name.toLowerCase() > b.taxon_name.toLowerCase() ? 1 : -1
    )),
  setAuthorTaxons: (state, authortaxons) => (state.authortaxons = authortaxons),
  /* setAuthorTaxons: (state, authortaxons) =>
    (state.authortaxons = authortaxons.sort((a, b) =>
      a.authortaxon_name.toLowerCase() > b.authortaxon_name.toLowerCase()
        ? 1
        : -1
    )), */
  setTaxonsComplete: (state) =>
    (state.taxons_complete = state.authortaxons.map((x) => {
      var taxon = state.taxons.find((t) => t.id_taxon === x.id_taxon);
      var pollen_habit = state.pollen_habits.find(
        (p) => p.id_pollen_habit === taxon.id_pollen_habit
      );
      var family = state.taxon_families.find(
        (f) => f.id_family === taxon.id_family
      );
      return {
        ...x,
        taxon_name: taxon.taxon_name,
        plant_habit: taxon.plant_habit,
        pollen_habit_code: pollen_habit.pollen_habit_code,
        family_name: family.family_name,
        id_family: family.id_family,
        id_pollen_habit: pollen_habit.id_pollen_habit,
      };
    })),
  setPollenHabits: (state, pollen_habits) =>
    (state.pollen_habits = pollen_habits.sort((a, b) =>
      a.pollen_habit_code.toLowerCase() > b.pollen_habit_code.toLowerCase()
        ? 1
        : -1
    )),
  setHabitTypes: (state, habit_types) => (state.habit_types = habit_types),
  setTaxonFamilies: (state, taxon_families) =>
    (state.taxon_families = taxon_families.sort((a, b) =>
      a.family_name.toLowerCase() > b.family_name.toLowerCase() ? 1 : -1
    )),

  applyTaxonsFilters: (state) => {
    /* let checker = (arr, target) => target.every((v) => arr.includes(v)); */
    state.filtered = state.authortaxons.filter(
      (r) =>
        (state.filters.authortaxons.length == 0
          ? true
          : state.filters.authortaxons.includes(r.id_authortaxon)
          ? true
          : false) &&
        (state.filters.taxons.length == 0
          ? true
          : state.filters.taxons.includes(r.id_taxon.id_taxon)
          ? true
          : false) &&
        (state.filters.families.length == 0
          ? true
          : state.filters.families.includes(r.id_taxon.id_family.id_family)
          ? true
          : false) &&
        (state.filters.pollen_habit.length == 0
          ? true
          : state.filters.pollen_habit.includes(
              r.id_taxon.id_pollen_habit.id_pollen_habit
            )
          ? true
          : false)
    );
  },
  updateTaxonsFilters: (state, filters) => (state.filters = filters),
  resetTaxonsFilters: (state) => (state.filters = setDefaultFilters()),

  /* ADD MUTATIONS */
  newPollenHabit: (state, pollen_habit) =>
    state.pollen_habits.push(pollen_habit),
  newTaxonFamily: (state, taxon_family) =>
    state.taxon_families.push(taxon_family),
  newTaxon: (state, taxon) =>
    state.taxons.push({
      ...taxon,
      id_family: { id_family: taxon.id_family },
      id_pollen_habit: { id_pollen_habit: taxon.id_pollen_habit },
    }),
  newAuthorTaxon: (state, authortaxon) => state.authortaxons.push(authortaxon),

  /* UPDATE mutations */
  updatePollenHabit: (state, updPollenHabit) => {
    const index = state.pollen_habits.findIndex(
      (fam) => fam.id_pollen_habit === updPollenHabit.id_pollen_habit
    );

    if (index !== -1) {
      state.pollen_habits.splice(index, 1, updPollenHabit);
    }
  },
  updateTaxonFamily: (state, updTaxonFamily) => {
    const index = state.taxon_families.findIndex(
      (fam) => fam.id_family === updTaxonFamily.id_family
    );

    if (index !== -1) {
      state.taxon_families.splice(index, 1, updTaxonFamily);
    }
  },
  updateTaxon: (state, updTaxon) => {
    const index = state.taxons.findIndex(
      (fam) => fam.id_taxon === updTaxon.id_taxon
    );

    if (index !== -1) {
      state.taxons.splice(index, 1, updTaxon);
    }
  },
  updateAuthorTaxon: (state, updAuthorTaxon) => {
    const index = state.authortaxons.findIndex(
      (fam) => fam.id_authortaxon === updAuthorTaxon.id_authortaxon
    );

    if (index !== -1) {
      state.authortaxons.splice(index, 1, updAuthorTaxon);
    }
  },

  /* DELETE mutations */

  removePollenHabit: (state, id_pollen_habit) =>
    (state.pollen_habits = state.pollen_habits.filter(
      (g) => g.id_pollen_habit !== id_pollen_habit
    )),
  removeTaxonFamily: (state, id_taxon_family) =>
    (state.taxon_families = state.taxon_families.filter(
      (g) => g.id_family !== id_taxon_family
    )),
  removeTaxon: (state, id_taxon) =>
    (state.taxons = state.taxons.filter((g) => g.id_taxon !== id_taxon)),
  removeAuthorTaxon: (state, id_authortaxon) =>
    (state.authortaxons = state.authortaxons.filter(
      (g) => g.id_authortaxon !== id_authortaxon
    )),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
