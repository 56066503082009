<template>
  <v-main>
    <v-container fill-height="fill-height">
      <v-row align-content="center" justify="center"
        ><v-col justify="center" lg="6">
          <div class="text-center">
            <v-card width="500px">
              <v-card-title>S'identifier</v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="username"
                    label="Nom d'utilisateur"
                    prepend-inner-icon="mdi-account"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Mot de passe"
                    type="password"
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                  <v-btn
                    @click.prevent=""
                    @click="sendLoginRequest"
                    block="block"
                    type="submit"
                    >Sign in</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;
export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    ...mapActions("authlipd",["loginRequest"]),
    async sendLoginRequest() {
      await this.loginRequest({
        username: this.username,
        password: this.password,
      });
      this.$router.push({name:'AdminDatasetsList', params:{ modernfossil: 'fossil' }}).catch((failure) => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          // show a small notification to the user
          console.log(failure);
        }
      });
      this.username = null;
      this.password = null;
    },
  },
};
</script>

<style>
</style>