import Api from "./axios"


const variableService = {

    getVariables(dataset_ids = null) {
        return Api.get("/variables/", {
            params: {
                dataset_ids: JSON.stringify(dataset_ids),
            }
        })
    },
    filterVariablesPost(data) {
        return Api.post("/variables/post_filter/", data)
    }
}

export default variableService;