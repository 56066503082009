import services from '@/services';

const state = {

    dataset_info: null,
    doi_metadata: null,

};


const getters = {

    getDatasetInfo: state => state.dataset_info,
    getDOIMetadata: state => state.doi_metadata,
};

const actions = {

    async fetchDatasetInfo({ commit }, dataset_id) {
        try {
            const response = await services.dataset.getDataset(dataset_id)
            commit('setDatasetInfo', response.data)
        } catch (error) {
            console.error(error)
        }
    },
    async fetchDOIMetadata({ commit }, doi) {
        
        let doi_response = await services.doi.getDOImetadata(doi);
        commit('setDOIInfo', doi_response.data);
    },
    // eslint-disable-next-line 
    async registerDOI({  }, data) {
        try {
            await services.doi.registerDOI(data)

        } catch (error) {
            console.error(error)
        }
    }


};

const mutations = {

    setDatasetInfo: (state, dataset_info) => state.dataset_info = dataset_info,
    setDOIInfo: (state, doi_info) => state.doi_metadata = doi_info,

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};