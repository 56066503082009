import Api from "./axios"

const materialService = {
    getMaterials(options) {
        let project_ids = options.project_ids || [];
        return Api.get("/materials/", {
            params: {
                project_ids: JSON.stringify(project_ids),
            }
        })

    },
    getMaterial(material_id) {
        return Api.get(`/materials/${material_id}/`)
    }
}

export default materialService;