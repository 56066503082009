import Api from "./axios"

const queryService = {

    queryMarineDepthSection(userSelection) {
        let headers = {
            'Content-Type': 'application/json',
        }
        return Api.post("/lsce-query/", userSelection, { headers: headers })
    },

    fossilPollenPercentageQuery(userSelection) {
        let headers = {
            'Content-Type': 'application/json',
        }
        return Api.post("/fossil-pollen-percentage/", userSelection, { headers: headers })
    }
}


export default queryService;