import Api from "./axios"

const projectService = {

    getProjects() {
        return Api.get("/projects/")
    },
    getProject(project_id) {
        return Api.get(`/projects/${project_id}/`)
    }
}

export default projectService;