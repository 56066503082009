import axios from "axios";
/* import otherstate from "./photosstore"; */
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

function setDefaultFilters() {
  return {
    sample_types: [],
    countries: [],
    authors: [],
    longitude: [-180, 180],
    latitude: [-90, 90],
    altitude: [-5000, 5000],
  };
}

const state = {
  authors: [],
  countries: [],
  sample_types: [],
  count_files: [],
  samples: [],
  filters: setDefaultFilters(),
  filtered: [],
};

const getters = {
  getAuthors: (state) => state.authors,
  getCountries: (state) => state.countries,
  getSamples: (state) => state.samples,
  getCountFiles: (state) => state.count_files,
  getSampleTypes: (state) => state.sample_types,
  getSamplesFiltered: (state) => state.filtered,
  getSamplesFilters: (state) => state.filters,
};

const actions = {
  // GET REQUESTS
  async fetchSamples({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/sample/"));
      commit("setSamples", response.data);
      commit("applySamplesFilters");
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  async fetchCountries({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/country/"));
      commit("setCountries", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  async fetchAuthors({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/author/"));
      commit("setAuthors", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  async fetchCountFiles({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/count-file/"));
      commit("setCountFiles", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  async fetchSampleTypes({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/sample-type/"));
      commit("setSampleTypes", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },

  resetSamplesFilters({ commit }) {
    commit("resetSamplesFilters");
    commit("applySamplesFilters");
  },
};

const mutations = {
  /* SET mutations */
  setSamples: (state, samples) =>
    (state.samples = samples.sort((a, b) =>
      a.sample_name_apd.toLowerCase() > b.sample_name_apd.toLowerCase() ? 1 : -1
    )),
  setCountries: (state, countries) =>
    (state.countries = countries.sort((a, b) =>
      a.country_name.toLowerCase() > b.country_name.toLowerCase() ? 1 : -1
    )),
  setAuthors: (state, authors) => (state.authors = authors),
  setCountFiles: (state, count_files) => (state.count_files = count_files),
  setSampleTypes: (state, sample_types) => (state.sample_types = sample_types),

  /* FILTERS mutations */
  updateSamplesFilters: (state, filters) => (state.filters = filters),
  applySamplesFilters: (state) => {
    /* let checker = (arr, target) => target.every((v) => arr.includes(v)); */
    state.filtered = state.samples.filter(
      (r) =>
        r.latitude <= state.filters.latitude[1] &&
        r.latitude >= state.filters.latitude[0] &&
        r.longitude <= state.filters.longitude[1] &&
        r.longitude >= state.filters.longitude[0] &&
        r.altitude <= state.filters.altitude[1] &&
        r.altitude >= state.filters.altitude[0] &&
        (state.filters.authors.length == 0
          ? true
          : state.filters.authors.includes(r.id_author)
          ? true
          : false) &&
        (state.filters.countries.length == 0
          ? true
          : state.filters.countries.includes(r.id_country)
          ? true
          : false) &&
        (state.filters.sample_types.length == 0
          ? true
          : state.filters.sample_types.includes(r.id_sample_type)
          ? true
          : false)
    );
  },
  resetSamplesFilters: (state) => (state.filters = setDefaultFilters()),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
