import axios from "axios";

const state = {
  config: {},
};

const getters = {
  getConfig: (state) => state.config,
  getConfigSelectOptions: (state) => state.config.selectOptions
};

const actions = {
  async fetchConfig({ commit }) {
    try {
      const response = await axios.get("json/config.json", {
        baseURL: window.location.origin,
      });
      commit("setConfig", response.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setConfig: (state, config) => (state.config = config),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
