import axios from "axios";
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

function setDefaultFilters() {
  return {
    archives: [],
    countries: [],
    authors: [],
    longitude: [-180, 180],
    latitude: [-90, 90],
    altitude: [-5000, 5000],
  };
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const state = {
  datasets: [],
  filters: setDefaultFilters(),
  filtered: [],
};

const getters = {
  getDatasets: (state) => state.datasets,
  getAuthors: (state) =>
    state.datasets
      ? [
          ...new Set(
            state.datasets
              .filter((d) => !!d.investigators)
              .map((d) => d.investigators.split(";"))
              .flat()
              .map((a) => a.trim())
              .sort()
          ),
        ]
      : [],
  getArchives: (state) =>
    state.datasets
      ? [
          ...new Set(
            state.datasets
              .filter((d) => !!d.archiveType)
              .map((d) =>
                capitalizeFirstLetter(d.archiveType.split(" - ")[0].trim())
              )
              .sort()
          ),
        ]
      : [],
  getCountries: (state) =>
    state.datasets
      ? [
          ...new Set(
            state.datasets
              .filter((d) => !!d.geo.properties.location)
              .map((d) => d.geo.properties.location.trim())
              .sort()
          ),
        ]
      : [],
  getFiltered: (state) => state.filtered,
  getFilters: (state) => state.filters,
};

const actions = {
  // GET REQUESTS
  async fetchDatasets({ commit }) {
    try {
      const response = await axios.get(
        urljoin(HOST_ROOT, "api/modern-generalinfo/")
      );
      await commit("setDatasets", response.data);
      /* commit("setFossilAuthors"); */
      commit("applyFilters");
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  resetFilters({ commit }) {
    commit("resetFilters");
    commit("applyFilters");
  },
};

const mutations = {
  setDatasets: (state, datasets) => (state.datasets = datasets),
  updateFilters: (state, filters) => (state.filters = filters),
  resetFilters: (state) => (state.filters = setDefaultFilters()),
  applyFilters: (state) => {
    /* let checker = (arr, target) => target.every((v) => arr.includes(v)); */
    state.filtered = state.datasets.filter(
      (r) =>
        r.latitude <= state.filters.latitude[1] &&
        r.latitude >= state.filters.latitude[0] &&
        r.longitude <= state.filters.longitude[1] &&
        r.longitude >= state.filters.longitude[0] &&
        r.altitude <= state.filters.altitude[1] &&
        r.altitude >= state.filters.altitude[0] &&
        (state.filters.authors.length == 0
          ? true
          : r.investigators
          ? state.filters.authors.includes(r.investigators.split(";")[0].trim())
            ? true
            : false
          : false) &&
        (state.filters.countries.length == 0
          ? true
          : r.geo.properties.location
          ? state.filters.countries.includes(r.geo.properties.location.trim())
            ? true
            : false
          : false) &&
        (state.filters.archives.length == 0
          ? true
          : r.archiveType
          ? state.filters.archives.includes(
              capitalizeFirstLetter(r.archiveType.split(" - ")[0].trim())
            )
            ? true
            : false
          : false)

      /* &&
        (state.filters.countries.length == 0
          ? true
          : state.filters.countries.includes(r.id_country)
          ? true
          : false) &&
        (state.filters.archives.length == 0
          ? true
          : state.filters.archive.includes(r.id_sample_type)
          ? true
          : false) */
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
