<template>
  <v-container>
    <!-- MAP -->
    <v-row align-content="center" justify="center">
      <v-col cols="12" xl="12">
        <div id="container">
          <div id="mapContainer"></div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4"
        ><v-text-field
          dense
          v-model="getEditGeo.siteName"
          outlined
          label="Site Name *"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-combobox
          v-model="getEditGeo.properties.location"
          outlined
          dense
          label="Country / Location *"
          @input="setGeo()"
          :items="getConfigSelectOptions['countries']"
        >
        </v-combobox
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.properties.location2"
          outlined
          dense
          label="Region"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="4"
        ><v-select
          v-model="getEditGeo.geometry.type"
          outlined
          dense
          :items="[
            { value: 'Point', text: 'Point' },
            { value: 'LineString', text: 'Range' },
          ]"
          label="Coordinates Type"
        >
        </v-select
      ></v-col>
    </v-row> -->
    <v-row v-if="getEditGeo.geometry.type === 'Point'">
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[1]"
          prepend-inner-icon="mdi-latitude"
          suffix="° N"
          outlined
          dense
          :rules="latRules"
          type="number"
          label="Latitude *"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[0]"
          prepend-inner-icon="mdi-longitude"
          suffix="° E"
          outlined
          dense
          :rules="lonRules"
          label="Longitude *"
          type="number"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[2]"
          prepend-inner-icon="mdi-image-filter-hdr"
          suffix="m"
          outlined
          dense
          type="number"
          label="Elevation / Depth"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
    </v-row>
    <v-row v-if="getEditGeo.geometry.type === 'LineString'">
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[0][1]"
          prepend-inner-icon="mdi-latitude"
          suffix="° N"
          outlined
          dense
          :rules="latRules"
          type="number"
          label="Max Latitude *"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[0][0]"
          prepend-inner-icon="mdi-longitude"
          suffix="° W"
          outlined
          dense
          :rules="lonRules"
          label="Max Longitude *"
          type="number"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[0][2]"
          prepend-inner-icon="mdi-image-filter-hdr"
          suffix="m"
          outlined
          dense
          type="number"
          label="Max Elevation"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[1][1]"
          prepend-inner-icon="mdi-latitude"
          suffix="° N"
          outlined
          dense
          :rules="latRules"
          type="number"
          label="Min Latitude *"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[1][0]"
          prepend-inner-icon="mdi-longitude"
          suffix="° W"
          outlined
          dense
          :rules="lonRules"
          label="Min Longitude *"
          type="number"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.geometry.coordinates[1][2]"
          prepend-inner-icon="mdi-image-filter-hdr"
          suffix="m"
          outlined
          dense
          type="number"
          label="Min Elevation"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.properties.areaofsite"
          suffix=" ha"
          outlined
          dense
          label="Area of site (ha)"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.properties.sitedescript"
          outlined
          dense
          label="Site Description"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
      <v-col cols="12" md="4"
        ><v-text-field
          v-model="getEditGeo.properties.localveg"
          outlined
          dense
          label="Local Vegetation"
          @input="setGeo()"
        >
        </v-text-field
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import L from "leaflet";
import "leaflet-defaulticon-compatibility";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      mapDiv: null,
      center: [0, 0],
      zoom: 4,
      marker: null,
      lonRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v >= -180) || "Longitude should be above -180° W",
        (v) => (v && v <= 180) || "Longitude should not be above 180° W",
      ],
      latRules: [
        (v) => !!v || "This field is required",
        (v) => (v && v >= -90) || "Latitude should be above -90° N",
        (v) => (v && v <= 90) || "Latitude should not be above 90° N",
      ],
    };
  },
  computed: {
    ...mapGetters("admin_fossilpollen", ["getEditGeo","getEditDialog"]),
    ...mapGetters("jsonconfig", ["getConfigSelectOptions"]),
  },
  methods: {
    setupLeafletMap() {
      const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
      });

      L.Marker.prototype.options.icon = DefaultIcon;
      this.mapDiv = L.map("mapContainer").setView([this.getEditGeo.geometry.coordinates[1],
        this.getEditGeo.geometry.coordinates[0]], this.zoom);

      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          minZoom: 1,

          id: "mapbox/satellite-v9",
          accessToken:
            "pk.eyJ1IjoiYWxlbW9yaSIsImEiOiJja2U0MmdkZHowcDFyMnhwZGc0dWgxNTIwIn0.AZJzeynr-RH04CF2OEuAoQ",
        }
      ).addTo(this.mapDiv);

      this.mapDiv.scrollWheelZoom.disable();
      this.marker = L.marker([
        this.getEditGeo.geometry.coordinates[1],
        this.getEditGeo.geometry.coordinates[0],
      ]);
      this.marker.addTo(this.mapDiv);
    },
    setGeo() {
      this.$store.commit("admin_fossilpollen/setEditGeo", {
        ...this.getEditGeo,
      });
    },
  },
  mounted() {
    this.setupLeafletMap();
  },
  watch: {
    getEditDialog(){
      this.mapDiv.off();
      this.mapDiv.remove();
      this.mapDiv.removeLayer(this.marker);
      
      this.setupLeafletMap();
      this.marker = L.marker([
        this.getEditGeo.geometry.coordinates[1],
        this.getEditGeo.geometry.coordinates[0],
      ]).addTo(this.mapDiv);
      window.dispatchEvent(new Event('resize'));
    },
    // eslint-disable-next-line no-unused-vars
    getEditGeo(newgeo) {
      /* this.mapDiv.removeLayer(this.marker);
      this.marker = L.marker([
        newgeo.geometry.coordinates[1],
        newgeo.geometry.coordinates[0],
      ]).addTo(this.mapDiv);
      window.dispatchEvent(new Event('resize'));  */

      this.mapDiv.removeLayer(this.marker);
      
      this.marker = L.marker([
        newgeo.geometry.coordinates[1],
        newgeo.geometry.coordinates[0],
      ]).addTo(this.mapDiv);
      
      
      /* if (oldgeo.geometry.type != newgeo.geometry.type) {
        if (newgeo.geometry.type === "LineString") {
          alert(JSON.stringify(newgeo.geometry));
          this.getEditGeo.geometry.coordinates = [
            oldgeo.geometry.coordinates,
            [1, 1, 1],
          ];
        }
      } */
    },
  },
};
</script>

<style >
#mapContainer {
  width: 100%;
  z-index: 0;
  height: 400px;
}
</style>