<template>
  <v-container>
    <v-card
      ><v-card-title>
        <h4>Taxonomies Dictionnary</h4>
        <v-spacer> </v-spacer>
        <v-banner
          v-if="getNotinapd.length > 0"
          color="warning"
          dark
          elevation="2"
          single-line
          style="font-size: 0.7em"
          ><v-icon slot="icon" dark size="30">
            mdi-alert-octagon-outline </v-icon
          >{{ getNotinapd.length }} variables are not in the APD taxonomy
          dictionnary</v-banner
        >
        <v-spacer></v-spacer>
        <v-btn
          color="#2B2118"
          dark
          class="mr-2"
          @click="checkTaxonomyDictionnary"
          ><v-icon left>mdi-format-list-checks</v-icon> Check dictionnary</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headd" :items="taxonss">
          <template v-slot:item.id_taxon="{ item }">
            <v-icon v-if="item.id_taxon" color="success">
              mdi-check-circle
            </v-icon>
            <v-icon v-if="!item.id_taxon" color="error">
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog v-model="dialogEdit" max-width="900px" scrollable persistent>
        <v-card>
          <v-card-title> Edit Taxonomy </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    dense
                    outlined
                    label="Search an APD taxonomy here..."
                    v-model="searchTaxon"
                  ></v-text-field>
                </v-col>
                <v-col v-if="selectedTaxon.length > 0" cols="12" sm="12">
                  <v-banner
                    v-if="getNotinapd.length > 0"
                    color="success"
                    dark
                    elevation="2"
                    single-line
                    style="font-size: 1em"
                    >Selected taxon:
                    {{
                      '"' +
                      selectedTaxon[0].taxon_name +
                      '" (' +
                      selectedTaxon[0].id_family.family_name +
                      ")"
                    }}</v-banner
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-layout child-flex>
                    <v-data-table
                      item-key="id_taxon"
                      :items="getTaxonsAll"
                      show-select
                      :search="searchTaxon"
                      :headers="headersTaxons"
                      v-model="selectedTaxon"
                      single-select
                      :footer-props="{
                        'items-per-page-options': [5],
                      }"
                      :items-per-page="5"
                    >
                    </v-data-table>
                  </v-layout>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="close"> Close </v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogNewTaxon = true" color="error">
              Can't find taxonomy in APD dictionnary
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="save"> Apply and save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-dialog width="800px" v-model="dialogNewTaxon" persistent>
      <v-card>
        <v-card-title>Assign to new taxonomy</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="family.id_family"
                  :disabled="checkbox"
                  label="Choose a family"
                  :items="getTaxonFamiliesAll"
                  item-text="family_name"
                  item-value="id_family"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="checkbox"
                  dense
                  label="I can't find the family in the previous list"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" v-if="checkbox">
                <v-text-field
                  dense
                  outlined
                  v-model="family.family_name"
                  label="Write here the name of the new family..."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  outlined
                  label="New taxon name"
                  v-model="taxon.taxon_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  dense
                  outlined
                  label="Pollen Habit"
                  :items="getPollenHabits"
                  item-value="id_pollen_habit"
                  item-text="pollen_habit_code"
                  v-model="taxon.id_pollen_habit"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  dense
                  outlined
                  label="Plant habit"
                  v-model="taxon.plant_habit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeNewTaxon">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              (!family.id_family && !family.family_name) ||
              !taxon.taxon_name ||
              !taxon.id_pollen_habit
            "
            @click="saveNewTaxon"
            ><v-icon left>mdi-content-save</v-icon>Save and close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["measurement"],
  data() {
    return {
      dialogEdit: false,
      editedIndex: null,
      checkbox: false,
      selectedTaxon: [],
      dialogNewTaxon: false,
      searchTaxon: "",
      family: {
        id_family: null,
        family_name: "",
      },
      defaultfamily: {
        id_family: null,
        family_name: "",
      },
      taxon: {
        taxon_name: "",
        plant_habit: "",
        id_pollen_habit: null,
      },
      defaulttaxon: {
        taxon_name: "",
        plant_habit: "",
        id_pollen_habit: null,
      },
      editedItem: {
        authortaxon_name: "",
        taxon_name: "",
        id_family: "",
        family_name: "",
        plant_habit: "",
        id_pollen_habit: "",
        pollen_habit_code: "",
        id_taxon: "",
      },
      defaultItem: {
        authortaxon_name: "",
        taxon_name: "",
        id_family: "",
        family_name: "",
        plant_habit: "",
        id_pollen_habit: "",
        pollen_habit_code: "",
        id_taxon: "",
      },
      taxonss: [],
      headersTaxons: [
        {
          text: "APD Taxonomy",
          align: "start",
          filterable: true,
          value: "taxon_name",
        },

        {
          text: "APD family name",
          filterable: true,
          value: "id_family.family_name",
        },
        {
          text: "Plant Habit",
          filterable: false,
          value: "plant_habit",
        },
        {
          text: "Pollen Habit",
          filterable: false,
          value: "id_pollen_habit.pollen_habit_code",
        },
      ],
      headd: [
        {
          text: "Variable Name",
          align: "start",
          value: "authortaxon_name",
        },
        {
          text: "APD taxonomy",
          value: "taxon_name",
        },
        {
          text: "APD family name",
          value: "family_name",
        },
        {
          text: "Plant Habit",
          value: "plant_habit",
        },
        {
          text: "Pollen Habit",
          value: "pollen_habit_code",
        },
        {
          text: "Is APD",
          value: "id_taxon",
        },
        {
          text: "Edit",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "fetchAuthorTaxonsDeep",
      "addAuthorTaxon2",
      "updateAuthorTaxon2",
      "addTaxon",
      "addTaxonFamily",
    ]),
    ...mapActions(["fetchTaxons", "fetchPollenHabits", "fetchTaxonFamilies"]),
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    async checkTaxonomyDictionnary() {
      this.setLoadingMessage(
        "Checking taxonomies on dictionary. Please wait..."
      );
      this.setIsLoading(true);

      let taxonListComplete = await this.fetchAuthorTaxonsDeep(
        this.measurement.columns.map((c) => c.variableName)
      );
      let dict = {};
      for (let taxon of taxonListComplete.data) {
        dict[taxon.authortaxon_name.toString().trim()] = taxon;
      }

      this.taxonss = this.measurement.columns.map((c) => {
        if (Object.keys(dict).includes(c.variableName.toString().trim())) {
          return {
            id_authortaxon: dict[c.variableName.toString().trim()].id_authortaxon,
            authortaxon_name: dict[c.variableName.toString().trim()].authortaxon_name,
            id_taxon: dict[c.variableName.toString().trim()].id_taxon.id_taxon,
            taxon_name: dict[c.variableName.toString().trim()].id_taxon.taxon_name,
            plant_habit: dict[c.variableName.toString().trim()].id_taxon.plant_habit,
            id_pollen_habit:
              dict[c.variableName.toString().trim()].id_taxon.id_pollen_habit.id_pollen_habit,
            pollen_habit_code:
              dict[c.variableName.toString().trim()].id_taxon.id_pollen_habit.pollen_habit_code,
            id_family: dict[c.variableName.toString().trim()].id_taxon.id_family.id_family,
            family_name: dict[c.variableName.toString().trim()].id_taxon.id_family.family_name,
          };
        } else {
          return {
            id_authortaxon: null,
            authortaxon_name: c.variableName.toString().trim(),
            id_taxon: null,
            taxon_name: null,
            plant_habit: null,
            id_pollen_habit: null,
            pollen_habit_code: null,
            id_family: null,
            family_name: null,
          };
        }
      });
      this.setIsLoading(false);
      this.setLoadingMessage("");
    },
    editItem(item) {
      this.editedIndex = this.taxonss.indexOf(item);
      this.editedItem = Object.assign(this.editedItem, item);
      this.selectedTaxon = this.getTaxonsAll.filter(
        (t) => t.id_taxon === item.id_taxon
      );
      console.log(JSON.stringify(this.editedItem));
      this.dialogEdit = true;
    },
    close() {
      this.dialogEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.selectedTaxon = [];
      this.searchTaxon = "";
    },
    closeNewTaxon() {
      this.dialogNewTaxon = false;
      this.checkbox = false;
      this.family = Object.assign({}, this.defaultfamily);
      this.taxon = Object.assign({}, this.defaulttaxon);
    },
    async saveNewTaxon() {
      if (!this.family.id_family) {
        let rispfamily = await this.addTaxonFamily({
          family_name: this.family.family_name,
        });

        this.family.id_family = rispfamily.id_family;
      }

      let resptaxon = await this.addTaxon({
        taxon_name: this.taxon.taxon_name,
        id_family: this.family.id_family,
        id_pollen_habit: this.taxon.id_pollen_habit,
        plant_habit: this.taxon.plant_habit,
      });

      let newtaxon = {
        id_authortaxon: this.taxonss[this.editedIndex].id_authortaxon,
        authortaxon_name: this.taxonss[this.editedIndex].authortaxon_name,
        id_taxon: resptaxon.id_taxon,
        taxon_name: resptaxon.taxon_name,
        plant_habit: resptaxon.plant_habit,
        id_pollen_habit: resptaxon.id_pollen_habit,
        id_family: resptaxon.id_family,
        family_name: this.family.family_name,
      };

      if (!newtaxon.id_authortaxon) {
        let resp = await this.addAuthorTaxon2({
          authortaxon_name: newtaxon.authortaxon_name,
          id_taxon: newtaxon.id_taxon,
        });
        newtaxon.id_authortaxon = resp.id_authortaxon;
      } else {
        await this.updateAuthorTaxon2({
          id_authortaxon: newtaxon.id_authortaxon,
          id_taxon: newtaxon.id_taxon,
        });
      }

      Object.assign(this.taxonss[this.editedIndex], newtaxon);
      this.fetchTaxons();
      this.fetchPollenHabits();
      this.fetchTaxonFamilies();
      this.checkbox = false;
      this.checkTaxonomyDictionnary();
      this.closeNewTaxon();
      this.close();
    },

    async save() {
      if (this.editedIndex > -1) {
        let newtaxon = {
          id_authortaxon: this.taxonss[this.editedIndex].id_authortaxon,
          authortaxon_name: this.taxonss[this.editedIndex].authortaxon_name
            .toString()
            .trim(),
          id_taxon: this.selectedTaxon[0].id_taxon,
          taxon_name: this.selectedTaxon[0].taxon_name,
          plant_habit: this.selectedTaxon[0].plant_habit,
          id_pollen_habit: this.selectedTaxon[0].id_pollen_habit
            .id_pollen_habit,
          pollen_habit_code: this.selectedTaxon[0].id_pollen_habit
            .pollen_habit_code,
          id_family: this.selectedTaxon[0].id_family.id_family,
          family_name: this.selectedTaxon[0].id_family.family_name,
        };

        if (!newtaxon.id_authortaxon) {
          let resp = await this.addAuthorTaxon2({
            authortaxon_name: newtaxon.authortaxon_name,
            id_taxon: newtaxon.id_taxon,
          });
          newtaxon.id_authortaxon = resp.id_authortaxon;
        } else {
          await this.updateAuthorTaxon2({
            id_authortaxon: newtaxon.id_authortaxon,
            id_taxon: newtaxon.id_taxon,
          });
        }

        Object.assign(this.taxonss[this.editedIndex], newtaxon);
      }
      this.close();
    },
  },
  computed: {
    ...mapGetters(["getPollenHabits", "getTaxonFamiliesAll", "getTaxonsAll"]),
    getNotinapd() {
      return this.taxonss.filter((t) => !t.id_taxon);
    },
  },
  mounted() {
    this.fetchTaxons();
    this.fetchPollenHabits();
    this.fetchTaxonFamilies();
  },
  watch: {
    checkbox(newval) {
      if (newval) {
        this.family.id_family = null;
      } else {
        this.family.family_name = null;
      }
    },
  },
};
</script>

<style>
</style>