import axios from "axios";

const TOKEN_API = process.env.VUE_APP_TOKEN_API;

const state = {
  isApiError: false,
  isLoading: false,
  loadingMessage: "",
  apiErrorMessage: "",
  user: {
    isLoggedIn: false,
    token: null,
  },
};

function setToken(token) {
  const days = 1; // Number of days until the cookie expires
  const expires = new Date(Date.now() + days * 864e5).toUTCString(); // Expiration date
  document.cookie = `authToken=${token}; expires=${expires}; path=/; secure; samesite=strict`;
}

function getTokenCookie() {
  const match = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
  if (match) return match[2];
  return null;
}
function removeToken() {
  document.cookie = 'authToken=; Max-Age=-99999999; path=/';
}

const getters = {
  getIsLoggedIn: (state) => state.user.isLoggedIn,
  getIsLoading: (state) => state.isLoading,
  getLoadingMessage: (state) => state.loadingMessage,
  getToken: (state) => {
    if (!state.user.token) {
      state.user.token = getTokenCookie();
    }
    return state.user.token;
  },
  getIsApiError: (state) => state.isApiError,
  getApiErrorMessage: (state) => state.apiErrorMessage,
};

const actions = {
  setApiError({ commit }, isError, errorMessage) {
    commit("setIsApiError", isError);
    commit("setApiErrorMessage", errorMessage);
  },

  async loginRequest({ commit }, credentials) {
    try {
      const response = await axios.post(TOKEN_API, {
        username: credentials.username,
        password: credentials.password,
      });
      commit("loginSuccessful", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  logoutRequest({ commit }) {
    removeToken();
    commit("logout");
  },
  setIsLoading({commit}, isLoadin){
    commit("setIsLoading", isLoadin)
  },
  setLoadingMessage({commit}, loadingMessage){
    commit("setLoadingMessage", loadingMessage)
  },
  logout({ commit }) {
    commit("logout");
  },

   
};

const mutations = {
  setIsApiError: (state, isError) => (state.isApiError = isError),
  setApiErrorMessage: (state, errorMessage) =>
    (state.apiErrorMessage = errorMessage),
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  setLoadingMessage: (state, loadingMessage) =>
    (state.loadingMessage = loadingMessage),
  /* LOGIN */
  loginSuccessful: (state, loginResponse) =>{
    // store token in a cookie
    setToken(loginResponse.token);
    state.user.token = loginResponse.token;
    state.user.isLoggedIn = true;
    /* (state.user = { isLoggedIn: true, token: loginResponse.token }), */},
  logout: (state) => {
    state.user = { token: null, isLoggedIn: false };
    removeToken();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
