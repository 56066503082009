import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
/* import Keycloak from "keycloak-js"; */

/* let initOptions = {
  url: "https://data.ipsl.fr/auth",
  realm: "ESPRI-Test",
  clientId: "apd-frontend",
  onLoad: "login-required",
  "enable-cors":true,
}; */

/* let initOptions = {
  realm: "ESPRI-Test",
  "auth-server-url": "https://data.ipsl.fr/auth",
  url: "https://data.ipsl.fr/auth",
  clientId: "apd-frontend",
  onLoad: "login-required",
  "ssl-required": "external",
  resource: "apd-frontend",
  "public-client": true,
  "confidential-port": 0,
  "enable_cors":true,
};

Vue.config.productionTip = false;

export let keycloak = new Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad,  })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      Vue.$log.info("Authenticated");
      new Vue({
        el: "#app",
        store,
        vuetify,
        router,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
      });
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            Vue.$log.info("Token refreshed" + refreshed);
          } else {
            Vue.$log.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          Vue.$log.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    Vue.$log.error("Authenticated Failed");
  }); */


Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
