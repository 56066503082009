import Services from "@/services";


function setDefaultState() {
    return {
        dataset_info: null,
        site_info: null,
        archive_info: null,
        region_info: null,
        project_info: null,
        doi_info: null,
        data_collections: [],
        measurement_tables: []
    }
}

const state = setDefaultState();

const getters = {
    getDatasetInfo: state => state.dataset_info,
    getSiteInfo: state => state.site_info,
    getArchiveInfo: state => state.archive_info,
    getRegionInfo: state => state.region_info,
    getProjectInfo: state => state.project_info,
    getDOIInfo: state => state.doi_info,
    getDataCollections: state => state.data_collections,
    getMeasurementTables: state => state.measurement_tables
}

const actions = {
    async fetchDatasetInfo({ commit }, dataset_id) {
        let response = await Services.dataset.getDataset(dataset_id);
        commit('setDatasetInfo', response.data);
    },

    async fetchSiteInfo({ commit }, site_id) {
        let response = await Services.site.getSite(site_id);
        commit('setSiteInfo', response.data);
    },
    async fetchArchiveInfo({ commit }, archive_id) {
        let response = await Services.archive.getArchive(archive_id);
        commit('setArchiveInfo', response.data);
    },
    async fetchRegionInfo({ commit }, region_id) {
        let response = await Services.region.getRegion(region_id);
        commit('setRegionInfo', response.data);
    },
    async fetchProjectInfo({ commit }, project_id) {
        let response = await Services.project.getProject(project_id);
        commit('setProjectInfo', response.data);
    },

    async fetchDOIInfo({ commit }, dataset_id) {
        let response = await Services.doi.getDatasetDOI(dataset_id);
        let doi_response = await Services.doi.getDOImetadata(response.data[0].doi);
        commit('setDOIInfo', doi_response.data);
    },
    async fetchDataCollections({ commit }, dataset_id) {
        let response = await Services.data_collection.getDatasetDataCollections(dataset_id);
        commit('setDataCollections', response.data);
    },
    async fetchMeasurementTables({ commit }, dataset_id) {
        let response = await Services.measurement_table.getDatasetMeasurementTables(dataset_id);
        commit('setMeasurementTables', response.data);
    },
    resetState({ commit }) {
        commit('resetState');
    }

}

const mutations = {
    setDatasetInfo: (state, dataset_info) => (state.dataset_info = dataset_info),
    setSiteInfo: (state, site_info) => (state.site_info = site_info),
    setArchiveInfo: (state, archive_info) => (state.archive_info = archive_info),
    setRegionInfo: (state, region_info) => (state.region_info = region_info),
    setProjectInfo: (state, project_info) => (state.project_info = project_info),
    setDOIInfo: (state, doi_info) => (state.doi_info = doi_info),
    setDataCollections: (state, data_collections) => (state.data_collections = data_collections),
    setMeasurementTables: (state, measurement_tables) => (state.measurement_tables = measurement_tables),
    resetState: (state) => Object.assign(state, setDefaultState())



}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}