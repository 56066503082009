import { render, staticRenderFns } from "./DialogPreview.vue?vue&type=template&id=77c67990"
import script from "./DialogPreview.vue?vue&type=script&lang=js"
export * from "./DialogPreview.vue?vue&type=script&lang=js"
import style0 from "./DialogPreview.vue?vue&type=style&index=0&id=77c67990&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports