<template>
  <v-container>
    <v-row v-for="(paleoData, i) in getEditCompleteJson.paleoData" :key="i">
      <v-col>
        <v-card
          elevation="4"
          color="#E0E0E0"
          style="overflow: auto"
          width="100%"
        >
          <v-card-title>
            <h4>Paleodata {{ i }}</h4>
            <v-spacer></v-spacer>
            <v-btn @click="addMeasurement(i)" class="mr-2"
              ><v-icon left>mdi-plus</v-icon>Add table</v-btn
            ><v-btn @click="openDeletePaleodataDialog(i)" color="error"
              ><v-icon left>mdi-delete</v-icon>Delete paleodata</v-btn
            ></v-card-title
          >
          <v-container
            ><v-row>
              <v-col>
                <v-data-table
                  :headers="paleoHeaders"
                  :items="getEditCompleteJson.paleoData[i].measurementTable"
                >
                  <template v-slot:item.filetype="{ item }">
                    <v-chip
                      dark
                      :color="
                                item.filetype === 'samples' ? 'warning' : '#1976D2'
                              "
                      >{{ item.filetype === 'samples' ? 'Samples' : 'Pollen Count' }}</v-chip
                    >
                  </template>
                  <template v-slot:item.size="{ item }">
                    {{ item.columns.length }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon class="ma-1" @click="openDialog(item)">
                      mdi-pencil
                    </v-icon>

                    <v-icon @click="openDeleteTableDialog(i, item)">
                      mdi-delete</v-icon
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="addPaleoData" color="#1976D2" dark
          ><v-icon left>mdi-plus</v-icon>Add paleodata</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog width="400px" v-model="deleteTableDialog">
      <v-card>
        <v-card-title> Delete Table </v-card-title>
        <v-card-text>
          Are you sure you want to delete the table "{{
            toDeleteTable.tableName
          }}"?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDeleteTableDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteTable">
            <v-icon left>mdi-delete</v-icon> DELETE table
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="deletePaleodataDialog">
      <v-card>
        <v-card-title> Delete Paleodata </v-card-title>
        <v-card-text>
          <p>
            Are you sure you want to delete
            {{ " Paleodata " + toDeletePaleodata }}?
          </p>
          <p>
            You will loose
            {{
              toDeletePaleodata != null
                ? getEditCompleteJson.paleoData[toDeletePaleodata]
                    .measurementTable.length
                : ""
            }}
            table{{
              toDeletePaleodata != null
                ? getEditCompleteJson.paleoData[toDeletePaleodata]
                    .measurementTable.length > 1
                  ? "s"
                  : ""
                : ""
            }}.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDeletePaleodataDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deletePaleodata">
            <v-icon left>mdi-delete</v-icon> DELETE paleodata
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogPaleodata
      :dialog.sync="dialog"
      v-bind:measurement="activeMeasurement"
      v-bind:paleochron="paleochron"
    ></DialogPaleodata>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogPaleodata from "./DialogPaleodata.vue";

export default {
  data() {
    return {
      activeMeasurement: { name: null },
      dialog: false,
      paleochron: "paleo",
      toDeleteTable: { tableName: "" },
      toDeleteTableIndexp: null,
      toDeletePaleodata: null,
      deleteTableDialog: false,
      deletePaleodataDialog: false,
      paleoHeaders: [
        { value: "tableName", text: "Table Name" },
        { value: "filetype", text: "File Type" },
        { value: "size", text: "N. columns", sortable: false },
        { value: "actions", text: "Actions", align: "end", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters("admin_pollenmoderne", ["getEditCompleteJson"]),
  },
  methods: {
    addPaleoData() {
      this.getEditCompleteJson.paleoData.push({
        measurementTable: [],
      });
      this.addMeasurement(this.getEditCompleteJson.paleoData.length - 1);
    },
    addMeasurement(index) {
      this.getEditCompleteJson.paleoData[index].measurementTable.push({
        tableName:
          "paleo" +
          index +
          "measurement" +
          this.getEditCompleteJson.paleoData[index].measurementTable.length,

        filename:
          "paleo" +
          index +
          "measurement" +
          this.getEditCompleteJson.paleoData[index].measurementTable.length +
          ".csv",
        columns: [{ variableName: "0" }],
        filetype: "raw count",
        data: [[0], [0]],
      });
    },
    deleteMeasurement(indexp, indexm) {
      this.getEditCompleteJson.paleoData[indexp].measurementTable.splice(
        indexm,
        1
      );
    },
    deletePaleodata() {
      this.getEditCompleteJson.paleoData.splice(this.toDeletePaleodata, 1);
      this.closeDeletePaleodataDialog();
    },
    openDialog(item) {
      this.activeMeasurement = item;
      this.dialog = true;
    },
    openDeleteTableDialog(indexp, item) {
      this.toDeleteTable = item;
      this.toDeleteTableIndexp = indexp;
      this.deleteTableDialog = true;
    },
    closeDeleteTableDialog() {
      this.toDeleteTable = { tableName: "" };
      this.toDeleteTableIndexp = null;
      this.deleteTableDialog = false;
    },
    deleteTable() {
      let indexm = this.getEditCompleteJson.paleoData[
        this.toDeleteTableIndexp
      ].measurementTable.indexOf(this.toDeleteTable);
      this.getEditCompleteJson.paleoData[
        this.toDeleteTableIndexp
      ].measurementTable.splice(indexm, 1);
      if (
        this.getEditCompleteJson.paleoData[this.toDeleteTableIndexp]
          .measurementTable.length === 0
      ) {
        this.getEditCompleteJson.paleoData.splice(this.toDeleteTableIndexp, 1);
      }
      this.closeDeleteTableDialog();
    },
    openDeletePaleodataDialog(indexp) {
      this.toDeletePaleodata = indexp;
      this.deletePaleodataDialog = true;
    },
    closeDeletePaleodataDialog() {
      this.toDeletePaleodata = null;
      this.deletePaleodataDialog = false;
    },
  },
  components: {
    DialogPaleodata,
    /* HotTable, */
  },
};
</script>

<style>
@import "../../../../../node_modules/handsontable/dist/handsontable.full.css";
</style>