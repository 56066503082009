<template>
  <v-data-table
    :headers="headers"
    :items="getEditCompleteJson.funding"
    sort-by="calories"
    class="elevation-0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-circle</v-icon>Add Funding
            </v-btn>
          </template>
          <v-card flat>
            <v-card-title>
              <span class="text-h5">New Funding</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.agency"
                      label="Agency"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.grant"
                      label="Grant"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.investigator"
                      label="Main investigator(s)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.country"
                      label="Country"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.identifier="{ item }">
      <a :href="item.identifier.url">{{ item.identifier.id }}</a>
    </template>
    <template v-slot:item.author="{ item }">
      <span>{{ item.author.map((a) => a.name).join("; ") }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItemConfirm(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <span>No fundings added yet.</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Agency", value: "agency" },
        { text: "Grant", value: "grant" },
        { text: "Main Investigator", value: "investigator" },
        { text: "Country", value: "country" },
        { text: "Actions", value: "actions" },
      ],
      editedIndex: -1,
      editedItem: {
        agency: "",
        grant: "",
        investigator: "",
        country: "",
      },
      defaultItem: {
        agency: "",
        grant: "",
        investigator: "",
        country: "",
      },
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.getEditCompleteJson.funding[this.editedIndex],
          this.editedItem
        );
      } else {
        this.getEditCompleteJson.funding.push(this.editedItem);
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.getEditCompleteJson.funding.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItemConfirm(item) {
      this.editedIndex = this.getEditCompleteJson.funding.indexOf(item);
      this.getEditCompleteJson.funding.splice(this.editedIndex, 1);
      this.editedIndex = -1;
    },
  },
  computed: {
    ...mapGetters("admin_fossilpollen", ["getEditCompleteJson"]),
  },
};
</script>

<style>
</style>