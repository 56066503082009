import Api from "./axios"

const taxonomyService = {
    
        getTaxonomies(options) {
            
            let {taxonomy_levels,variable_ids, dataset_ids, material_ids, with_ancestors} = options;
            return Api.get("/taxonomies/", {
                params: {
                    taxonomy_levels: JSON.stringify(taxonomy_levels),
                    variable_ids: JSON.stringify(variable_ids),
                    dataset_ids: JSON.stringify(dataset_ids),
                    material_ids: JSON.stringify(material_ids),
                    with_ancestors: with_ancestors,
                }
            })
            
        },
        filterTaxonomiesPost(data) {
            console.log(data)
            return Api.post("/taxonomies/post_filter/", data)
        },
        getTaxonomyLevels() {
            return Api.get("/taxonomy-levels/")
        },
        addTaxonomy(data) {
            return Api.post("/taxonomies/", data)
        },
        getHabitTypes() {
            let taxonomy_levels = ['HABITTYPE'];
            return Api.post("/taxonomies/post_filter/", {
                taxonomy_levels: taxonomy_levels,
            })
        },
        getPollenHabits() {
            let taxonomy_levels = ['POLLENHABIT'];
            return Api.post("/taxonomies/post_filter/", {
                taxonomy_levels: taxonomy_levels,
            })
        },
        getPollenFamilies() {
            let taxonomy_levels = ['POLLENFAMILY'];
            return Api.post("/taxonomies/post_filter/", {
                taxonomy_levels: taxonomy_levels,
            })
        },

        getPollenTaxons() {
            let taxonomy_levels = ['POLLENTAXON'];
            return Api.post("/taxonomies/post_filter/", {
                taxonomy_levels: taxonomy_levels,
            })
        }

}

export default taxonomyService;