import Api from "./axios"

const regionService = {

    getRegions(options) {
        let project_ids = options.project_ids || [];
        return Api.get("/regions/", {
            params: {
                project_ids: JSON.stringify(project_ids),
            }
        })

    },
    getRegion(region_id) {
        return Api.get(`/regions/${region_id}/`)
    }
}

export default regionService;