<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h4>CSV data</h4>
        <v-spacer></v-spacer>
        <v-btn @click="inferVariables" text class="mr-2"
          >Infer header from first row</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4">
              <p>
                Add a new variable pressing the right button on the table and
                then "Insert column right". To remove one or multiple variables, press "Remove
                column".
              </p>
              <p>
                You can also copy and paste directly the data from your .csv
                file.
              </p>
            </v-col>
            <v-col cols="12" sm="12">
              <hot-table
                ref="hotTableComponent"
                class="hottable"
                :settings="getHotSettings"
              ></hot-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { HotTable } from "@handsontable/vue";
/* import Handsontable from "handsontable"; */
export default {
  props: ["measurement"],
  data() {
    return {
      csv_data: null,
      csv_header: null,
      hotRef: null,
    };
  },
  components: {
    HotTable,
  },
  methods: {
    inferVariables() {
      this.measurement.columns = this.hotRef.getSourceDataAtRow(0).map((c) => {
        return { variableName: c };
      });
      return;
    },
    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
  },
  computed: {
    getHotSettings() {
      return {
        data: this.measurement.data,
        colHeaders: this.measurement.columns.map((x) => x.variableName),
        colsToRemove: null,
        width: "auto",
        /* startCols: this.measurement.columns.length, */
        height: "350",

        licenseKey: "non-commercial-and-evaluation",
        copyPaste: true,
        stretchH: "all",
        autoColumnSize: {
          useHeaders: true,
        },
        allowRemoveColumn: true,
        allowRemoveRow: true,
        contextMenu: {
          items: {
            copy: {
              name: "Copy",
            },
            col_right: {},
            remove_col: {},
            row_above: {},
            row_below: {},
            remove_row: {},
          },
        },
        afterChange: () => {
          if (this.hotRef) {
            /* this.measurement.columns = this.hotRef.getColHeader().map((c) => {
              return { variableName: c };
            }); */
            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterRemoveRow: () => {
          if (this.hotRef) {
            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterCreateRow: () => {
          if (this.hotRef) {
            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterCreateCol: (index) => {
          if (this.hotRef) {
            let headers = this.hotRef.getColHeader();
            this.arraymove(headers, -1, index);
            /* this.measurement.columns = headers.map((c) => {
              return { variableName: c };
            }); */
            this.measurement.columns.splice(index, 0, { variableName: index });
            /* this.measurement.columns = this.measurement.columns.filter((c) =>
              headers.includes(c.variableName)
            ); */

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterRemoveCol: () => {
          if (this.hotRef) {
            /* keep only the columns that are not selected to be removed */
            this.measurement.columns = this.measurement.columns.filter(
              (c) => !this.colsToRemove.includes(c.variableName)
            );

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        beforeRemoveCol: (index) => {
          if (this.hotRef) {
            if (
              /* if just a column is selected */
              this.hotRef.getSelected()[0][1] == this.hotRef.getSelected()[0][3]
            ) {
              this.colsToRemove = [this.hotRef.getColHeader()[index]];
            } else {
              /* if multiple columns are selected, take the index of the first and last column */
              let indexes = [1, 3].map((x) => this.hotRef.getSelected()[0][x]);
              this.colsToRemove = this.hotRef
                .getColHeader()
                .slice(indexes[0], indexes[1] + 1);
            }

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
      };
    },
  },
  mounted() {
    this.hotRef = this.$refs.hotTableComponent.hotInstance;
    this.measurement.data = this.hotRef.getSourceData();
  },
  watch: {
    measurement() {
      this.hotRef = this.$refs.hotTableComponent.hotInstance;
    },
  },
};
</script>

<style scoped>
@import "../../../../../node_modules/handsontable/dist/handsontable.full.css";
</style>