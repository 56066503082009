<template>
  <v-card v-show="false">
    <v-card-title>
      {{ sample_data.properties.name }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <!-- <h4>Location:</h4>
      <p>{{"Site: "+sample_data.siteName}}</p>
      <p>{{"Altitude (m): "+sample_data.geometry.coordinates[2]}}</p> -->
      <h4>Archive Type:</h4>
      <p><v-chip>{{ sample_data.properties.archive }}</v-chip></p>
    </v-card-text>
    <v-card-actions>
      <v-btn-toggle>
        <v-btn  class="opendialog">Details</v-btn><v-spacer></v-spacer>
        <v-btn v-if="!selected" class="selectmap" color="success">Add to Selection</v-btn>
        <v-btn v-if="selected" class="selectmap" color="error">Discard</v-btn>
      </v-btn-toggle>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CustomPopup",
  props: ["feature", "selected"],
  data: function () {
    return {
      sample_data: this.feature,
    };
  },
};
</script>

<style></style>