<template>
  <div>
    <!-- <v-breadcrumbs :items="getItems">
      <v-breadcrumbs-item
        slot="item"
        slot-scope="{ item }"
        exact
        :disabled="item.disabled"
        v-if="item.visible"
        :to="item.to"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs> -->
    
      <v-col class="text-left ml-6" 
        ><v-btn style="text-align: left" color="black" dark @click="$router.go(-1);"><v-icon left>mdi-step-backward</v-icon>Back</v-btn></v-col
      >
    

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text:
            this.$route.params.fossilormodern === "fossil"
              ? "Fossil Pollen Dataset"
              : "Modern Pollen Dataset",
          disabled: true,
          visible: true,
          to: "breadcrumbs_dashboard",
        },
        {
          text: "DOI",
          disabled: true,
          visible: true,
          to: "breadcrumbs_dashboard",
        },
        {
          text: this.$route.params.editorcreate ? "Create" : "Edit",
          disabled: true,
          visible: this.$route.params.editorcreate,
          to: "breadcrumbs_link_1",
        },
      ],
    };
  },
  computed: {
    getItems() {
      let items =
        this.$route.params.editorcreate &&
        (this.$route.params.editorcreate === "edit" ||
          this.$route.params.editorcreate === "create")
          ? [
              {
                text:
                  this.$route.params.fossilormodern === "fossil"
                    ? "Fossil Pollen Dataset"
                    : "Modern Pollen Dataset",
                disabled: true,
                visible: true,
                to: "breadcrumbs_dashboard",
              },
              {
                text: "DOI",
                disabled: true,
                visible: true,
                to: "breadcrumbs_dashboard",
              },
              {
                text:
                  this.$route.params.editorcreate === "create"
                    ? "Create"
                    : this.$route.params.editorcreate === "edit"
                    ? "Edit"
                    : "",
                disabled: true,
                visible: this.$route.params.editorcreate,
                to: "breadcrumbs_link_1",
              },
            ]
          : [
              {
                text:
                  this.$route.params.fossilormodern === "fossil"
                    ? "Fossil Pollen Dataset"
                    : "Modern Pollen Dataset",
                disabled: true,
                visible: true,
                to: "breadcrumbs_dashboard",
              },
              {
                text: "DOI",
                disabled: true,
                visible: true,
                to: "breadcrumbs_dashboard",
              },
            ];
      return items;
    },
  },
};
</script>

<style>
</style>