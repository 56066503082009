import Api from "./axios"

const measurementTableService = {
    
        getDatasetMeasurementTables(datasetId) {
            return Api.get(`/measurement-tables/?dataset_id=${datasetId}`)
        },
        createMeasurementTable(data) {
            return Api.post('/measurement-tables/', data)
        },
        deleteMeasurementTable(measurementTableId) {
            return Api.delete(`/measurement-tables/${measurementTableId}/`)
        },
        getMeasurementTable(measurementTableId) {
            return Api.get(`/measurement-tables/${measurementTableId}/`)
        },
        getMeasurementTableColumns(measurementTableId) {
            return Api.get(`/measurement-tables/${measurementTableId}/columns/`)
        },
        getMeasurementTableData(measurementTableId) {
            return Api.get(`/measurement-tables/${measurementTableId}/samples_data_dict/`)
        },
        updateMeasurementTable(measurementTableId, data) {
            return Api.put(`/measurement-tables/${measurementTableId}/`, data)
        },
        updateMeasurementTableData(measurementTableId, data) {
            return Api.post(`/measurement-tables/${measurementTableId}/update_data/`, data)
        },
}

export default measurementTableService;