import Api from './axios'

const dataCollectionService = {
    async getDatasetDataCollections(datasetId) {
        return await Api.get(`/datacollections/?dataset_id=${datasetId}`)
    },
    async createDataCollection(data) {
        return await Api.post('/datacollections/', data)
    },
    async deleteDataCollection(dataCollectionId) {
        return await Api.delete(`/datacollections/${dataCollectionId}/`)
    },
    async updateDataCollection(dataCollectionId, data) {
        return await Api.put(`/datacollections/${dataCollectionId}/`, data)
    }
}

export default dataCollectionService;
