<template>
  <v-container>
    <link
      rel="stylesheet"
      href="https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.css"
    />
    <link
      rel="stylesheet"
      href="https://leaflet.github.io/Leaflet.markercluster/dist/MarkerCluster.Default.css"
    />

    <!-- MAP -->
    <v-row align-content="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="10">
        <!-- <v-btn-toggle v-model="toggleExclusive" dense mandatory>
          <v-btn> Satellite </v-btn>

          <v-btn> Political </v-btn>
          <v-btn> Marine Boundaries </v-btn>
          <v-btn> Bathymetry</v-btn>
        </v-btn-toggle> -->
        <div id="container">
          <div id="mapContainer"></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters /* mapActions  */ } from "vuex";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-defaulticon-compatibility";
import * as L1 from "leaflet.markercluster";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import CustomPopup from "./CustomPopup.vue";
import Vue from "vue";
export default {
  name: "DataLibraryMap",
  props: ["dialog", "feature", "selected", "showonlyselected"],
  data: () => ({
    mapDiv: null,
    center: [0, 0],
    toggleExclusive: 0,
    maps_styles: ["mapbox/satellite-v9", "mapbox/streets-v11"],
  }),
  computed: {
    ...mapGetters("publicdatalibrary",["getDatasets"]),
    geoJSONmarkers() {
      var thisData = this.showonlyselected ? this.selected : this.getDatasets;
      let geoJSONdata = thisData.map((x) => {
        /* let data = {
          ...x.geo,
          properties: {
            ...x.geo.properties,
            investigators: x.investigators,
            archiveType: x.archiveType,
            dataset_title: x.dataset_title,
            id_dataset: x.id_dataset,
            dataSetNameComplete: x.dataSetNameComplete,
            samplingDate: x.samplingDate,
          },
        }; */
        let data = {
          ...x.geo,
          properties: {
            ...x,
            ...x.geo.properties
          },
          type: "Feature",
        };
        return data;
      });

      let geoJSONcollection = {
        type: "FeatureCollection",
        features: geoJSONdata,
      };

      const _this = this;

      var geoJsonLayer = L.geoJson(geoJSONcollection, {
        onEachFeature: function (feature, layer) {
          layer
            .bindPopup(() => {
              var ComponentClass = Vue.extend(CustomPopup);
              var isSelected = _this.selected.indexOf(feature.properties) > -1;
              var instance = new ComponentClass({
                propsData: { feature: feature, selected: isSelected  },
              });
              instance.$mount();
              return instance.$el.innerHTML;
            })
            .on("popupopen", (a) => {
              var popUp = a.target.getPopup();

              popUp
                .getElement()
                .querySelector(".opendialog")
                .addEventListener("click", () => {
                  _this.changeRoute(feature.properties.dataset_id);
                });

              popUp
                .getElement()
                .querySelector(".selectmap")
                .addEventListener("click", () => {
                  const index = _this.selectedd.indexOf(feature.properties);
                  if (index > -1) {
                    _this.selectedd.splice(index, 1);
                  } else {
                    _this.selectedd.push(feature.properties);
                  }
                  _this.mapDiv.closePopup();
                });
            });
        },
      });
      /* var geoJsonLayer = L.geoJson(geoJSONcollection); */
      var markers = new L1.MarkerClusterGroup({ showCoverageOnHover: true });
      markers.addLayer(geoJsonLayer);

      return markers;
    },
    sample_data() {
      return this.feature;
    },
    selectedd(){
      return this.selected;
    },
    accessTemplate() {
      let el = this.$refs.popup.$el;
      console.log(el);
      console.log(el.innerHTML);
      return el;
    },
  },

  methods: {
    openDialog(item) {
      this.$router.push({name: 'DatasetDetail', params: { id: item.uuid, id_dataset:item.id_dataset}});
      
    },
    closeDialog() {
      this.$emit("update:dialog", !this.dialog);
    },
    changeRoute(dataset_id) {
      this.$router.push({
        name: 'DatasetDetail',
        params: { id: dataset_id },
      });
    },
    



    setupLeafletMap() {
      const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconAnchor: [12, 41],
      });

      L.Marker.prototype.options.icon = DefaultIcon;
      this.mapDiv = L.map("mapContainer").setView(this.center, 1);

      if (this.toggleExclusive === 2) {
        L.tileLayer
          .wms("https://geo.vliz.be/geoserver/MarineRegions/wms", {
            LAYERS: "eez",
            TRANSPARENT: "TRUE",
            SRS: "EPSG:4326",
            attribution:
                'Exclusive Economic Zones (200 NM) (v11, world, 2019) <a href="http://www.marineregions.org">Marine Regions</a> ',
          })
          .addTo(this.mapDiv);
      } else if (this.toggleExclusive === 3) {
        L.tileLayer
          .wms(
            "https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?",
            {
              layers: "gebco_latest",
              attribution:
                'GEBCO Grid shaded relief <a href="https://www.gebco.net/">General Bathymetric Chart of the Oceans (GEBCO)</a> ',
            }
          )
          .addTo(this.mapDiv);
      } else {
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data (c) <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery (c) <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            minZoom: 3,

            id: this.maps_styles[this.toggleExclusive],
            accessToken:
              "pk.eyJ1IjoiYWxlbW9yaSIsImEiOiJja2U0MmdkZHowcDFyMnhwZGc0dWgxNTIwIn0.AZJzeynr-RH04CF2OEuAoQ",
          }
        ).addTo(this.mapDiv);
      }

      this.mapDiv.scrollWheelZoom.disable();
    },

    populateLeafletMap() {
      this.mapDiv.addLayer(this.geoJSONmarkers);
    },

    async createMap() {

      await this.setupLeafletMap();
      this.populateLeafletMap();
    },
  },
  watch: {
    geoJSONmarkers(newMarkers, oldMarkers) {
      if (this.mapDiv) {
        this.mapDiv.removeLayer(oldMarkers);
        this.mapDiv.addLayer(newMarkers);
      }
    },
    toggleExclusive() {
      this.zoom=this.mapDiv.getZoom()
      this.center=this.mapDiv.getCenter()
      this.mapDiv.off();
      this.mapDiv.remove();
      this.setupLeafletMap();
      this.populateLeafletMap();
    },
  },
  mounted() {
    this.createMap();
  },
  /* created() {
    this.createMap();
  }, */
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  z-index: 0;
  height: 500px;
}
</style>