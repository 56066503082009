import axios from "axios";
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

const state = {
  isApiError: false,
  isLoading: false,
  loadingMessage: "",
  apiErrorMessage: "",
  user: {
    isLoggedIn: false,
    token: null,
  },
};

const getters = {
  getIsLoggedIn: (state) => state.user.isLoggedIn,
  getIsLoading: (state) => state.isLoading,
  getLoadingMessage: (state) => state.loadingMessage,
  getToken: (state) => state.user.token,
  getIsApiError: (state) => state.isApiError,
  getApiErrorMessage: (state) => state.apiErrorMessage,
};

const actions = {
  setApiError({ commit }, isError, errorMessage) {
    commit("setIsApiError", isError);
    commit("setApiErrorMessage", errorMessage);
  },

  async loginRequest({ commit }, credentials) {
    try {
      const response = await axios.post(urljoin(HOST_ROOT, "auth-token/"), {
        username: credentials.username,
        password: credentials.password,
      });
      commit("loginSuccessful", response.data);
    } catch (error) {
      commit("setIsApiError", true);
      commit("setApiErrorMessage", error);
    }
  },
  setIsLoading({commit}, isLoadin){
    commit("setIsLoading", isLoadin)
  },
  setLoadingMessage({commit}, loadingMessage){
    commit("setLoadingMessage", loadingMessage)
  },
  logout({ commit }) {
    commit("logout");
  },
};

const mutations = {
  logout: (state) => (state.user = { token: null, isLoggedIn: false }),
  setIsApiError: (state, isError) => (state.isApiError = isError),
  setApiErrorMessage: (state, errorMessage) =>
    (state.apiErrorMessage = errorMessage),
  setIsLoading: (state, isLoading) => (state.isLoading = isLoading),
  setLoadingMessage: (state, loadingMessage) =>
    (state.loadingMessage = loadingMessage),
  /* LOGIN */
  loginSuccessful: (state, loginResponse) =>
    (state.user = { isLoggedIn: true, token: loginResponse.token }),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
