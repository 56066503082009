<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    hide-overlay
    fullscreen
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title><v-toolbar dark color="#2B2118">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Preview LiPD</v-toolbar-title>
        </v-toolbar>  </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="5" lg="3">
              <v-list subheader>
                <v-list-item @click="viewtype = 'json'">
                  <v-list-item-avatar>
                    <v-icon color="red"> mdi-code-json </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title> metadata.jsonld </v-list-item-title>
                    <v-list-item-subtitle> Metadata file </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon :disabled="viewtype != 'json'">
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-for="paleofile in getPaleoFiles"
                  :key="paleofile.filename"
                  @click="openTable(paleofile)"
                >
                  <v-list-item-avatar>
                    <v-icon color="indigo"> mdi-file-delimited </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="paleofile.filename">
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Paleodata spreadsheet
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon :disabled="viewtype != paleofile.filename">
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-for="chronofile in getChronoFiles"
                  :key="chronofile.filename"
                  @click="openTable(chronofile)"
                  
                >
                  <v-list-item-avatar>
                    <v-icon color="success"> mdi-file-delimited </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="chronofile.filename">
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Chronodata spreadsheet
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon :disabled="viewtype != chronofile.filename">
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="1">
              <v-divider vertical></v-divider>
            </v-col>

            <v-col cols="12" md="6" lg="8">
              <v-row justify="center" align-content="center">
                <v-col>
                  <p v-if="viewtype == 'json'">
                    <pre>
                      {{JSON.stringify(getEditJsonNodata,null, 2)}}
                    </pre>
                  </p>
                  <hot-table
                    v-if="viewtype != 'json'"
                    ref="hotTableComponent"
                    class="hottable"
                    :settings="getHotSettings"
                  ></hot-table>
                  <!-- <PreviewHotTable
                    v-if="viewtype != 'json'"
                    v-bind:measurement="activeTable"
                    v-bind:viewtype="viewtype"
                  ></PreviewHotTable> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog"><v-icon left>mdi-close</v-icon>Close</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { HotTable } from "@handsontable/vue";
/* import PreviewHotTable from "./PreviewHotTable.vue"; */
export default {
  components: { HotTable },
  data() {
    return {
      viewtype: "json",
      csv_data: null,
      csv_header: null,
      csv_title: null,
      hotSettings: {
        data: null,
        readOnly: true,
        colHeaders: true,
        height: "1000",
        width: "auto",
        stretchH: "all",
        licenseKey: "non-commercial-and-evaluation",
      },
      activeTable: { columns: [{ variableName: "0" }], data: [[null], [null]] },
    };
  },
  props: ["dialog"],
  methods: {
    closeDialog() {
      this.$emit("update:dialog", !this.dialog);
    },
    openTable(item) {
      /* this.activeTable = item; */
      this.csv_data = item.data;
      this.csv_header = item.columns.map((x) => x.variableName);
      this.viewtype = item.filename;
    },
  },
  computed: {
    ...mapGetters("admin_pollenmoderne", ["getEditCompleteJson", "getEditJsonNodata"]),
    getPaleoFiles() {
      return this.getEditCompleteJson.paleoData
        .map((x) => x.measurementTable)
        .flat();
    },
    getChronoFiles() {
      return this.getEditCompleteJson.chronData
        .map((x) => x.measurementTable)
        .flat();
    },
    getHotSettings() {
      return {
        data: this.csv_data,
        readOnly: true,
        colHeaders: this.csv_header,
        /* stretchH: "all", */
        height: "500",
        width: "auto",
        licenseKey: "non-commercial-and-evaluation",
      };
    },
  },
  watch: {
    csv_data(newdata) {
      this.$refs.hotTableComponent.hotInstance.updateSettings({
        data: newdata,
        colHeaders: this.csv_header,
      });
    },
    csv_header(newheader) {
      this.$refs.hotTableComponent.hotInstance.updateSettings({
        data: this.csv_data,
        colHeaders: newheader,
      });
    },
  },
};
</script>

<style>
@import "../../../../../node_modules/handsontable/dist/handsontable.full.css";
</style>