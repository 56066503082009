<template>
  <v-dialog
    fullscreen
    hide-overlay
    v-if="dialog"
    v-model="isDialog"
    @click:outside="closeDialog"
  >
    <v-card
      ><v-card-title>
        <v-toolbar
          dark
          :color="
            measurement.filetype === 'c14 measurement'
              ? '#2E7D32'
              : measurement.filetype === 'agebasis'
              ? '#43A047'
              : measurement.filetype === 'interpolated chronology'
              ? '#66BB6A'
              : measurement.filetype === 'raw count'
              ? '#1976D2'
              : measurement.filetype === 'lithology'
              ? '#42A5F5'
              : 'grey'
          "
        >
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ measurement.tableName }} ({{
              measurement.filetype
            }})</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <h4>General Information:</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="measurement.tableName"
                dense
                label="Measurement Name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="measurement.filename"
                label="File Name"
                suffix=".csv"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                label="File Type"
                v-model="measurement.filetype"
                :items="
                  paleochron === 'chron'
                    ? [
                        'c14 measurement',
                        'interpolated chronology',
                        'agebasis',
                        'other',
                      ]
                    : ['raw count', 'lithology', 'other']
                "
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-combobox
                dense
                outlined
                label="Missing value"
                v-model="measurement.missingValue"
                :items="['', 'nan']"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="measurement.notes"
                label="Notes"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <HotTable
            ref="hotTableComponent"
            class="hottable"
            :settings="getHotSettings"
          ></HotTable> -->
          <v-row style="overflow: auto">
            <HotTablee v-bind:measurement="measurement"></HotTablee>
          </v-row>

          <!-- List of variables of the csv data table -->
          <v-row>
            <v-col cols="12" xl="12">
              <v-card
                ><v-card-title>
                  <h4>Variables List</h4>
                  <v-spacer></v-spacer>
                  <!-- <v-btn
                    v-if="measurement.filetype === 'raw count'"
                    color="success"
                    class="mr-2"
                    @click="checkTaxonomyList"
                    ><v-icon left>mdi-playlist-check</v-icon> Check taxonomy
                    list</v-btn
                  > -->
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :items="measurement.columns"
                    :headers="getHeaderz"
                    @toggle-select-all="selectAll"
                    disable-sort
                    v-model="selectedVariables"
                    show-select
                    item-key="variableName"
                  >
                    <template v-slot:top>
                      <v-dialog
                        v-model="dialogEdit"
                        @click:outside="close"
                        max-width="1000px"
                        scrollable
                      >
                        <v-card flat height="600px">
                          <v-card-title>
                            <span class="text-h5">Edit Variable</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <h4>General parameters</h4>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.variableName"
                                    label="Variable Name *"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-combobox
                                    outlined
                                    dense
                                    v-model="editedItem.variableType"
                                    :items="variableTypes"
                                    label="Variable Type"
                                  ></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-combobox
                                    outlined
                                    dense
                                    v-model="editedItem.units"
                                    label="Units *"
                                  ></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-combobox
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.OnProxyObservationProperty
                                    "
                                    label="OnProxyObservationProperty"
                                    :items="
                                      getConfigSelectOptions[
                                        'OnProxyObservationProperty'
                                      ]
                                    "
                                  ></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-combobox
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.OnInferredVariableProperty
                                    "
                                    label="OnInferredVariableProperty"
                                    :items="
                                      getConfigSelectOptions[
                                        'OnInferredVariableProperty'
                                      ]
                                    "
                                  ></v-combobox>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <h4>Interpretation</h4>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.interpretation.basis"
                                    label="Basis"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.interpretation.direction
                                    "
                                    :items="['positive', 'negative']"
                                    label="Direction"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    outlined
                                    dense
                                    v-model="editedItem.interpretation.local"
                                    label="Local"
                                    :items="['local', 'far-field']"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.interpretation.rank"
                                    label="Rank"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    outlined
                                    dense
                                    v-model="editedItem.interpretation.scope"
                                    :items="['climate', 'isotopy', 'ecology']"
                                    label="Scope"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.interpretation.variable"
                                    label="Variable"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.interpretation.variableDetail
                                    "
                                    label="Variable Detail"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <h4>Calibration</h4>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.calibration.equation"
                                    label="Equation"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.calibration.uncertainty"
                                    label="Uncertainty"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.interpretation.uncertaintyType
                                    "
                                    label="Uncertainty Type"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.calibration.notes"
                                    label="Notes"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.calibration.reference"
                                    label="Reference"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <h4>Physical Sample</h4>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.physicalSample.collectionMethod
                                    "
                                    label="Collection Method"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="
                                      editedItem.physicalSample.hasIdentifier
                                    "
                                    label="hasIdentifier"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.physicalSample.hasSign"
                                    label="hasSign"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.physicalSample.hasName"
                                    label="hasName"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                    outlined
                                    dense
                                    v-model="editedItem.physicalSample.housedAt"
                                    label="housedAt"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.number="{ item }">
                      {{ measurement.columns.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.variableName="{ item }">
                      <v-text-field
                        :disabled="!selectedVariables.includes(item)"
                        v-model="item.variableName"
                        outlined
                        dense
                        class="mt-3"
                      >
                      </v-text-field>
                      <!-- <p v-if="!selectedVariables.includes(item)">
                        {{ item.variableName }}
                      </p> -->
                    </template>
                    <template v-slot:item.units="{ item }">
                      <v-combobox
                        :disabled="!selectedVariables.includes(item)"
                        outlined
                        dense
                        single-line
                        class="shrink mt-3"
                        @input="editUnits"
                        v-model="item.units"
                        :items="['', 'm', 'cm']"
                      >
                      </v-combobox>
                      <!-- <p v-if="!selectedVariables.includes(item)">
                        {{ item.units }}
                      </p> -->
                    </template>
                    <template v-slot:item.variableType="{ item }">
                      <v-combobox
                        :disabled="!selectedVariables.includes(item)"
                        outlined
                        dense
                        class="shrink mt-3"
                        v-model="item.variableType"
                        @input="editVariableType"
                        :items="['measured', 'inferred', 'depth', 'time',,'sampleName']"
                      >
                      </v-combobox>
                      <!-- <p v-if="!selectedVariables.includes(item)">
                        {{ item.variableType }}
                      </p> -->
                    </template>
                    <template v-slot:item.OnProxyObservationProperty="{ item }">
                      <v-combobox
                        :disabled="!selectedVariables.includes(item)"
                        outlined
                        dense
                        class="shrink mt-3"
                        v-model="item.OnProxyObservationProperty"
                        @input="editOnProxyObservationProperty"
                        :items="
                          getConfigSelectOptions['OnProxyObservationProperty']
                        "
                      >
                      </v-combobox>
                      <!-- <p v-if="!selectedVariables.includes(item)">
                        {{ item.variableType }}
                      </p> -->
                    </template>
                    <template v-slot:item.OnInferredVariableProperty="{ item }">
                      <v-combobox
                        :disabled="!selectedVariables.includes(item)"
                        outlined
                        dense
                        class="shrink mt-3"
                        v-model="item.OnInferredVariableProperty"
                        @input="editOnInferredVariableProperty"
                        :items="
                          getConfigSelectOptions['OnInferredVariableProperty']
                        "
                      >
                      </v-combobox>
                      <!-- <p v-if="!selectedVariables.includes(item)">
                        {{ item.variableType }}
                      </p> -->
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card-text></v-card
              >
            </v-col>
          </v-row>
          <v-row v-if="measurement.filetype === 'raw count'">
            <v-col cols="12" xl="12">
              <CheckTaxonDict :measurement="measurement"> </CheckTaxonDict> </v-col
          ></v-row>
          <!-- Dialog to show taxons that are not yet in the APD Taxonomy list -->
          <v-dialog v-model="dialogNewTaxons" scrollable width="600">
            <v-card max-height="600px">
              <v-card-title> New Taxonomies </v-card-title>
              <v-card-text>
                <p>
                  {{ newTaxons.length }} new taxonomies were found in this file.
                </p>
                <p>Please enter those records in the APD taxonomies list:</p>
                <ul>
                  <li v-for="t in newTaxons" v-bind:key="t">
                    {{ t }}
                  </li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-spacer> </v-spacer>
                <v-btn color="success" @click="csvNewTaxons"
                  ><v-icon left>mdi-download</v-icon> Download list
                </v-btn>
                <v-btn @click="dialogNewTaxons = false"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* import { HotTable } from "@handsontable/vue"; */
import { mapGetters, mapActions } from "vuex";
import CheckTaxonDict from "./CheckTaxonDict.vue";
import HotTablee from "./HotTablee.vue";

export default {
  props: ["dialog", "measurement", "paleochron"],
  data() {
    return {
      csv_data: null,
      csv_header: null,
      hotRef: null,
      newTaxons: [],
      selectedVariables: [],
      dialogNewTaxons: null,
      olddata: null,
      dialogEdit: false,
      variableTypes: ["measured", "inferred", "time", "depth", "sample_name"],
      editedItem: {
        OnProxyObservationProperty: "",
        OnInferredVariableProperty: "",
        variableName: "",
        variableType: "",
        units: "",
        interpretation: {
          basis: "",
          direction: "",
          local: "",
          rank: "",
          scope: "",
          variable: "",
          variableDetail: "",
        },
        physicalSample: {
          collectionMethod: "",
          hasIdentifier: "",
          hasSign: "",
          hasName: "",
          housedAt: "",
        },
        calibration: {
          equation: "",
          notes: "",
          reference: "",
          uncertainty: "",
          uncertaintyType: "",
        },
      },
      defaultItem: {
        variableName: "",
        variableType: "",
        OnProxyObservationProperty: "",
        OnInferredVariableProperty: "",
        units: "",
        interpretation: {
          basis: "",
          direction: "",
          local: "",
          rank: "",
          scope: "",
          variable: "",
          variableDetail: "",
        },
        physicalSample: {
          collectionMethod: "",
          hasIdentifier: "",
          hasSign: "",
          hasName: "",
          housedAt: "",
        },
        calibration: {
          equation: "",
          notes: "",
          reference: "",
          uncertainty: "",
          uncertaintyType: "",
        },
      },
    };
  },
  components: {
    CheckTaxonDict,
     HotTablee,
  },
  methods: {
    ...mapActions(["fetchAuthorTaxons2"]),
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    inferVariables() {
      this.measurement.columns = this.hotRef.getSourceDataAtRow(0).map((c) => {
        return { variableName: c };
      });
      return;
    },
    editUnits(e) {
      for (let index of this.getselectedVariablesIndex) {
        Object.assign(this.measurement.columns[index], {
          ...this.measurement.columns[index],
          units: e,
        });
      }
    },
    selectAll(event) {
      /* alert(JSON.stringify(event)) */
      if (event.value) {
        this.selectedVariables = this.measurement.columns;
      } else {
        this.selectedVariables = [];
      }
    },
    editVariableType(e) {
      for (let index of this.getselectedVariablesIndex) {
        Object.assign(this.measurement.columns[index], {
          ...this.measurement.columns[index],
          variableType: e,
        });
      }
    },
    editOnProxyObservationProperty(e) {
      for (let index of this.getselectedVariablesIndex) {
        Object.assign(this.measurement.columns[index], {
          ...this.measurement.columns[index],
          OnProxyObservationProperty: e,
        });
      }
    },
    editOnInferredVariableProperty(e) {
      for (let index of this.getselectedVariablesIndex) {
        Object.assign(this.measurement.columns[index], {
          ...this.measurement.columns[index],
          OnInferredVariableProperty: e,
        });
      }
    },
    async checkTaxonomyList() {
      this.setLoadingMessage(
        "Checking taxonomies on dictionary. Please wait..."
      );
      this.setIsLoading(true);
      await this.fetchAuthorTaxons2();
      let taxon_names = this.getAuthorTaxons.map((t) =>
        t.authortaxon_name.toString().trim()
      );
      let file_taxons = this.measurement.columns.map((c) =>
        c.variableName.toString().trim()
      );
      this.setIsLoading(false);
      this.setLoadingMessage("");

      this.newTaxons = file_taxons.filter((t) => !taxon_names.includes(t));
      this.dialogNewTaxons = true;
    },

    csvNewTaxons() {
      let csvContent = "data:text/csv;charset=utf-8,";
      let csvData = this.newTaxons.map((t) => ({
        taxon_name: t,
      }));
      csvContent += [
        Object.keys(csvData[0]).join(";"),
        ...csvData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "new_taxons.csv");
      link.click();
    },

    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },

    closeDialog() {
      this.$emit("update:dialog", !this.dialog);
      this.selectedVariables = [];
    },
    close() {
      this.dialogEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.measurement.columns.indexOf(item);
      this.editedItem = Object.assign(this.editedItem, item);
      console.log(JSON.stringify(this.editedItem));
      this.dialogEdit = true;
    },
    /* deleteItem(item) {
      this.editedIndex = this.measurement.columns.indexOf(item);
      this.measurement.columns.splice(this.editedIndex, 1);
      let newdata = this.measurement.data.map((row) =>
        row.splice(this.editedIndex, 1)
      );
      this.measurement.data = newdata;
      this.editedIndex = -1;
    }, */
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.measurement.columns[this.editedIndex],
          this.editedItem
        );
      } else {
        /* this.olddata = [...this.measurement.data]; */
        this.measurement.columns.push(this.editedItem);
        /* this.measurement.data = this.olddata.map((row) => row.push(null)); */
      }
      this.close();
    },
  },
  computed: {
    ...mapGetters(["getAuthorTaxons"]),
    ...mapGetters("jsonconfig", ["getConfigSelectOptions"]),
    getHeaderz() {
      return [
        {
          text: "Column Index",
          align: "start",
          value: "number",
        },
        { text: "Variable Name", value: "variableName" },
        {
          text:
            this.selectedVariables.length > 0
              ? "Units (Edit all selected items)"
              : "Units",
          value: "units",
        },
        {
          text:
            this.selectedVariables.length > 0
              ? "Variable Type (Edit all selected items)"
              : "Variable Type",
          value: "variableType",
        },
        {
          text:
            this.selectedVariables.length > 0
              ? "OnProxyObservationProperty (Edit all selected items)"
              : "OnProxyObservationProperty",
          value: "OnProxyObservationProperty",
        },
        {
          text:
            this.selectedVariables.length > 0
              ? "OnInferredVariableProperty (Edit all selected items)"
              : "OnInferredVariableProperty",
          value: "OnInferredVariableProperty",
        },
        { text: "Details", value: "actions", align: "end" },
      ];
    },
    isDialog() {
      return this.dialog;
    },
    fileName() {
      let name = this.measurement.tableName + ".csv";
      return name.replace(/ /g, "_");
    },
    getselectedVariablesIndex() {
      return this.selectedVariables.map((v) =>
        this.measurement.columns.indexOf(v)
      );
    },
    getHotSettings() {
      return {
        data: this.measurement.data,
        colHeaders: this.measurement.columns.map((x) => x.variableName),
        colsToRemove: null,
        width: "auto",
        /* startCols: this.measurement.columns.length, */
        height: "350",
        licenseKey: "non-commercial-and-evaluation",
        copyPaste: true,
        stretchH: "all",
        allowRemoveColumn: true,
        contextMenu: {
          items: {
            copy: {
              name: "Copy",
            },
            col_right: {},
            remove_col: {},
          },
        },
        afterChange: () => {
          if (this.hotRef) {
            /* this.measurement.columns = this.hotRef.getColHeader().map((c) => {
              return { variableName: c };
            }); */
            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterCreateCol: (index) => {
          if (this.hotRef) {
            let headers = this.hotRef.getColHeader();
            this.arraymove(headers, -1, index);
            /* this.measurement.columns = headers.map((c) => {
              return { variableName: c };
            }); */
            this.measurement.columns.splice(index, 0, { variableName: index });
            /* this.measurement.columns = this.measurement.columns.filter((c) =>
              headers.includes(c.variableName)
            ); */

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        afterRemoveCol: () => {
          if (this.hotRef) {
            /* let headers = this.hotRef.getColHeader(); */
            this.measurement.columns = this.measurement.columns.filter(
              (c) => c.variableName != this.colsToRemove
            );

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
        beforeRemoveCol: (index) => {
          if (this.hotRef) {
            alert(index);
            this.colsToRemove = this.hotRef.getColHeader()[index];
            /* this.measurement.columns = this.measurement.columns.filter((c) =>
              this.hotRef.getColHeader().includes(c.variableName)
            ); */

            this.measurement.data = this.hotRef.getSourceData();
          }
        },
      };
    },
  },
  watch: {
    fileName(newname) {
      this.measurement.filename = newname;
    },
    dialog() {
      this.hotRef = this.$refs.hotTableComponent.hotInstance;
      this.measurement.data = this.hotRef.getSourceData();
      this.$refs.hotTableComponent.click();
    },
    editedItem(newedited) {
      if (newedited.variableType === "inferred") {
        this.editedItem = { ...newedited, OnProxyObservationProperty: "" };
      }
    },
    /* measurement() {
      this.hotRef = this.$refs.hotTableComponent.hotInstance;
    }, */
  },
  mounted() {
    this.hotRef = this.$refs.hotTableComponent.hotInstance;
    this.measurement.data = this.hotRef.getSourceData();
  },
};
</script>

<style>
@import "../../../../../node_modules/handsontable/dist/handsontable.full.css";
</style>