import Api from './axios'

const columnService = {
    async getMeasurementTableColumns(measurementTableId) {
        return await Api.get(`/columns/?measurement_table_id=${measurementTableId}`)
    },
    async createColumn(data) {
        return await Api.post('/columns/', data)
    },
    async updateColumn(columnId, data) {
        return await Api.put(`/columns/${columnId}/`, data)
    },
}

export default columnService;