<template>
  <v-data-table
    :headers="headers"
    :items="getEditCompleteJson.pub"
    sort-by="calories"
    class="elevation-0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" scrollable max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-circle</v-icon>Add Publication
            </v-btn>
          </template>
          <v-card flat>
            <v-card-title>
              <span class="text-h5">New Publication</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.identifier.id"
                      label="DOI ID"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.identifier.url"
                      label="DOI URL"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <v-btn
                      @click="autocomplete"
                      :disabled="
                        !editedItem.identifier.url && !editedItem.identifier.id
                      "
                      >AUTOCOMPLETE Via DOI</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.title"
                      outlined
                      dense
                      label="Title"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editedItem.author[0].name"
                      label="Author 1"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row
                  v-for="(textField, i) in editedItem.author.slice(1)"
                  :key="i"
                >
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      outlined
                      :label="'Author ' + (i + 2)"
                      v-model="textField.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn @click="removeAuthor(i)" icon
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn color="primary" @click="addAuthor" dark>
                      <v-icon left>mdi-plus-circle</v-icon>Add Author
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.year"
                      label="Year"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.journal"
                      label="Journal"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.issue"
                      label="Issue"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.volume"
                      label="Volume"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.edition"
                      label="Edition"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.pages"
                      label="Pages"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      outlined
                      dense
                      v-model="editedItem.abstract"
                      label="Abstract"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="waitingDOI" persistent max-width="300px">
          <v-card>
            <v-card-text class="text-center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-progress-circular indeterminate></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span>Loading DOI information. Please wait...</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogAbstract"
          scrollable
          width="700px"
          hide-overlay
        >
          <v-card height="500px">
            <v-card-title>Abstract</v-card-title>
            <v-card-text
              ><p v-if="abstractItem">
                {{ abstractItem.abstract }}
              </p></v-card-text
            >
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.identifier="{ item }">
      <a :href="item.identifier.url">{{ item.identifier.id }}</a>
    </template>
    <template v-slot:item.author="{ item }">
      <span>{{ item.author.map((a) => a.name).join("; ") }}</span>
    </template>
    <template v-slot:item.abstract="{ item }">
      <v-icon @click="seeAbstract(item)">mdi-text-box-search</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItemConfirm(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <span>No publications added yet.</span>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      waitingDOI: false,
      authorsTextFields: [],
      dialogAbstract: false,
      abstractItem: null,
      headers: [
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        { text: "Author(s)", value: "author" },
        { text: "Journal", value: "journal" },
        { text: "Year", value: "year" },
        { text: "Volume", value: "volume" },
        { text: "Issue", value: "issue" },
        { text: "Pages", value: "pages" },
        { text: "DOI", value: "identifier" },
        {
          text: "See Abstract",
          value: "abstract",
          align: "right",
          sortable: false,
        },
        { text: "Actions", value: "actions", align: "end" },
      ],

      editedIndex: -1,
      editedItem: {
        author: [{ name: "" }],
        title: "",
        journal: "",
        year: "",
        volume: "",
        issue: "",
        pages: "",
        report: "",
        identifier: {
          id: "",
          type: "doi",
          url: "",
        },
        abstract: "",
        alternate_citation: "",
      },
      defaultItem: {
        author: [{ name: "" }],
        title: "",
        journal: "",
        year: "",
        volume: "",
        issue: "",
        pages: "",
        report: "",
        identifier: {
          id: "",
          type: "doi",
          url: "",
        },
        abstract: "",
        alternate_citation: "",
      },
    };
  },

  methods: {
    addAuthor() {
      this.editedItem.author.push({
        name: "",
      });
    },
    removeAuthor(index) {
      this.editedItem.author.splice(index + 1, 1);
    },
    seeAbstract(item) {
      this.abstractItem = item;
      this.dialogAbstract = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    /* deleteItem(item) {
      
      this.dialogDelete = true;
    }, */

    deleteItemConfirm(item) {
      this.editedIndex = this.getEditCompleteJson.pub.indexOf(item);
      this.getEditCompleteJson.pub.splice(this.editedIndex, 1);
      this.editedIndex = -1;
    },
    editItem(item) {
      this.editedIndex = this.getEditCompleteJson.pub.indexOf(item);
      if (item.author) {
        if (item.author.length < 1) {
          console.log(item.author)
          item.author.push({ name: "" });
        }
      }
      this.editedItem = Object.assign({}, item);
      
      this.dialog = true;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.getEditCompleteJson.pub[this.editedIndex],
          this.editedItem
        );
      } else {
        this.getEditCompleteJson.pub.push(this.editedItem);
      }
      this.close();
    },
    async autocomplete() {
      var url = this.editedItem.identifier.url
        ? this.editedItem.identifier.url
        : "https://doi.org/" + this.editedItem.identifier.id;
      this.waitingDOI = true;
      try {
        const response = await axios.get(url, {
          headers: { Accept: "application/x-bibtex" },
        });
        const data = response.data;
        var varlist = data.slice(9, -2).split(",\n\t").slice(1);
        var dictlist = varlist.map((x) => {
          var items = x.split("=");
          var key = items[0].trim();
          var value = items[1].trim().split(/\{|\}/).join("");
          value =
            key === "author"
              ? value.split(" and ").map((a) => {
                  return { name: a.trim() };
                })
              : value;
          key = key === "doi" ? "id" : key;
          return { [key]: value };
        });
        var dict = Object.assign({}, ...dictlist);
        dict["identifier"] = { id: dict["id"], url: dict["url"], type: "doi" };
        delete dict.id;
        delete dict.url;
        this.waitingDOI = false;
        this.editedItem = dict;
      } catch {
        this.waitingDOI = false;
      }
    },
  },
  computed: {
    ...mapGetters("admin_pollenmoderne", ["getEditCompleteJson"]),
  },
};
</script>

<style>
</style>