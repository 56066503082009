// eslint-disable-next-line no-unused-vars
import axios from "axios";
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

function setDefaultFilters() {
  return {
    famille: [],
    genre: [],
    espece: [],
    motcle_1: [],
    motcle_2: [],
    motcle_3: [],
    motcle_4: [],
    motcle_5: [],
    motcle_6: [],
  };
}

const state = {
  pollens: [],
  motcle: [],
  famille: [],
  characteristique: [],
  genre: [],
  espece: [],
  caracterise: [],
  filters: setDefaultFilters(),
  filtered: [],
  images: [],
  caractGroup: {},
  activeDetail: 0,
  openDetail: false,
  navigation: {
    managePhotoDetail: false,
    manageKeywordsDetail: false,
    adminActivePollenIndex: -1,
  },
  isApiError: false,
  apiErrorMessage: "",
  user: {
    isLoggedIn: false,
    token: null,
  },
};

const getters = {
  getIsLoggedIn: (state) => state.user.isLoggedIn,
  getToken: (state) => state.user.token,
  getCaractGroup: (state) => state.caractGroup,
  getPollensAll: (state) => state.pollens,
  getPollens: (state) =>
    state.filtered.sort(
      (a, b) =>
        cmp(getFamilleName(a.id_famille), getFamilleName(b.id_famille)) ||
        cmp(getGenreName(a.id_genre), getGenreName(b.id_genre)) ||
        cmp(a.espece, b.espece)
    ),
  getFilters: (state) => state.filters,
  getMotcle: (state) => state.motcle,
  getImages: (state) => state.images,
  getCaracterise: (state) => state.caracterise,
  getCharacteristique: (state) => state.characteristique,
  getFamille: (state) => state.famille,
  getGenre: (state) => state.genre,
  getEspece: (state) =>
    [
      ...new Set(
        state.pollens.map((item) => {
          return {
            espece: item.espece,
            id_famille: item.id_famille,
            id_genre: item.id_genre,
          };
        })
      ),
    ].sort((a, b) => (a.espece > b.espece ? 1 : -1)),
  getFamilleGenre: (state) =>
    [
      ...new Set(
        state.pollens.map((item) => {
          return {
            id_famille: item.id_famille,
            id_genre: item.id_genre,
            nom_famille: state.famille.filter(
              (f) => f.id_famille === item.id_famille
            )[0].nom_famille,
            nom_genre: state.genre.filter(
              (f) => f.id_genre === item.id_genre
            )[0].nom_genre,
          };
        })
      ),
    ].sort((a, b) => (a.nom_famille > b.nom_famille ? 1 : -1)),
  getDistinctMotcle: (state) =>
    [
      ...new Set(
        state.caracterise.map((item) => {
          return {
            id_motcle: item.id_motcle,
            id_caract: item.id_caract,
            valeur: state.motcle.filter(
              (m) => m.id_motcle === item.id_motcle
            )[0].valeur,
          };
        })
      ),
    ].sort(),
  getActiveDetail: (state) => state.activeDetail,
  getOpenDetail: (state) => state.openDetail,
  getManagePhotoDetail: (state) => state.navigation.managePhotoDetail,
  getManageKeywordsDetail: (state) => state.navigation.manageKeywordsDetail,
  getAdminActivePollenIndex: (state) => state.navigation.adminActivePollenIndex,
  getIsApiError: (state) => state.isApiError,
  getApiErrorMessage: (state) => state.apiErrorMessage,
};

const actions = {
  async fetchPollens({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/pollens/"));
      commit("setPollens", response.data);
      commit("applyFilters");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchMotcle({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/motcle/"));
      commit("setMotcle", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchCharacteristique({ commit }) {
    try {
      const response = await axios.get(
        urljoin(HOST_ROOT, "api/caracteristique/")
      );
      commit("setCharacteristique", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchFamille({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/famille/"));
      commit("setFamille", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchGenre({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/genre/"));
      commit("setGenre", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchImages({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/images/"));
      commit("setImages", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async fetchCaracterise({ commit }) {
    try {
      const response = await axios.get(urljoin(HOST_ROOT, "api/caracterise/"));
      commit("setCaracterise", response.data);
      commit("setCaractGroup");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  resetFilters({ commit }) {
    commit("resetFilters");
    commit("applyFilters");
  },

  setApiError({ commit }, isError, errorMessage) {
    commit("auth/setIsApiError", isError);
    commit("auth/setApiErrorMessage", errorMessage);
  },

  /* API ADD operations */
  async addFamille({ commit, rootState }, famille) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/famille/"),
        {
          nom_famille: famille,
        },
        {
          headers: headers,
        }
      );
      commit("newFamille", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addGenre({ commit, rootState }, genre) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/genre/"),
        {
          id_famille: genre.id_famille,
          nom_genre: genre.nom_genre,
        },
        {
          headers: headers,
        }
      );
      commit("newGenre", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addCaracterise({ commit, rootState }, caracterise) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/caracterise/"),
        {
          id_motcle: caracterise.id_motcle,
          id_pollen: caracterise.id_pollen,
        },
        {
          headers: headers,
        }
      );
      commit("newCaracterise", response.data);
      commit("setCaractGroup");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addPollen({ commit, rootState }, pollen) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/pollens/"),
        {
          id_famille: pollen.id_famille,
          id_genre: pollen.id_genre,
          espece: pollen.espece,
          lame: pollen.lame,
          labo: pollen.labo,
          herbier: pollen.herbier,
          dimensionp: pollen.dimensionp,
          dimensione: pollen.dimensione,
          biblio: pollen.biblio,
          remarques: pollen.remarques,
          pollenassocies: pollen.pollenassocies,
          nom_revise: pollen.nom_revise,
        },
        {
          headers: headers,
        }
      );
      commit("newPollen", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addImage({ commit, rootState }, uplImage) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + rootState.auth.user.token,
    };

    let formData = new FormData();
    formData.append("chem_ima", uplImage.chem_ima);
    formData.append("id_pollen", uplImage.id_pollen);

    try {
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/images/"),
        formData,
        {
          headers: headers,
        }
      );
      commit("newImage", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async addMotcle({ commit, rootState }, motcle) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/motcle/"),
        {
          valeur: motcle.valeur,
          id_caract: motcle.id_caract,
        },
        {
          headers: headers,
        }
      );
      commit("newMotcle", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* API UPDATE operations */
  async updateFamille({ commit, rootState }, famille) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/famille/${famille.id_famille}/`),
        famille,
        {
          headers: headers,
        }
      );
      commit("updateFamille", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateGenre({ commit, rootState }, genre) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/genre/${genre.id_genre}/`),
        genre,
        {
          headers: headers,
        }
      );
      commit("updateGenre", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updatePollen({ commit, rootState }, pollen) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/pollens/${pollen.id_pollen}/`),
        pollen,
        {
          headers: headers,
        }
      );
      commit("updatePollen", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async updateMotcle({ commit, rootState }, motcle) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.put(
        urljoin(HOST_ROOT, `api/motcle/${motcle.id_motcle}/`),
        motcle,
        {
          headers: headers,
        }
      );
      commit("updateMotcle", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* DELETE actions */
  async deleteImage({ commit, rootState }, imageId) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/images/${imageId}/`), {
        headers: headers,
      });
      commit("removeImage", imageId);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deletePollen({ commit, rootState }, pollenId) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/pollens/${pollenId}/`), {
        headers: headers,
      });
      commit("removePollen", pollenId);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteGenre({ commit, rootState }, genreId) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/genre/${genreId}/`), {
        headers: headers,
      });
      commit("removeGenre", genreId);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteFamille({ commit, rootState }, familleId) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/famille/${familleId}/`), {
        headers: headers,
      });
      commit("removeFamille", familleId);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteCaracterise({ commit, rootState }, caracteriseId) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(
        urljoin(HOST_ROOT, `api/caracterise/${caracteriseId}/`),
        {
          headers: headers,
        }
      );
      commit("removeCaracterise", caracteriseId);
      commit("setCaractGroup");
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  async deleteMotcle({ commit, rootState }, id_motcle) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      await axios.delete(urljoin(HOST_ROOT, `api/motcle/${id_motcle}/`), {
        headers: headers,
      });
      commit("removeMotcle", id_motcle);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },

  /* LOGIN */
  async loginRequest({ commit }, credentials) {
    try {
      const response = await axios.post(urljoin(HOST_ROOT, "auth-token/"), {
        username: credentials.username,
        password: credentials.password,
      });
      commit("loginSuccessful", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true);
      commit("auth/setApiErrorMessage", error);
    }
  },
  logout({ commit }) {
    commit("logout");
  },
};

function getFamilleName(id_famille) {
  return state.famille
    .filter((c) => c.id_famille === id_famille)
    .map((c) => c.nom_famille.toLowerCase())[0];
}

function getGenreName(id_genre) {
  return state.genre
    .filter((c) => c.id_genre === id_genre)
    .map((c) => c.nom_genre.toLowerCase())[0];
}

function cmp(a, b) {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
}

const mutations = {
  logout: (state) => (state.user = { token: null, isLoggedIn: false }),
  setPollens: (state, pollens) => (state.pollens = pollens),
  setMotcle: (state, motcle) => (state.motcle = motcle),
  /* setImages: (state, images) =>
    (state.images = images.map((x) => {
      return {
        ...x,
        chem_ima: x.chem_ima.replace("http://localhost:8000/images/", ""),
      };
    })), */
  setImages: (state, images) => (state.images = images),
  setCaracterise: (state, caracterise) => (state.caracterise = caracterise),
  setCharacteristique: (state, caracteristique) =>
    (state.characteristique = caracteristique),
  setFamille: (state, famille) =>
    (state.famille = famille.sort((a, b) =>
      a.nom_famille > b.nom_famille ? 1 : -1
    )),
  setGenre: (state, genre) =>
    (state.genre = genre.sort((a, b) => (a.nom_genre > b.nom_genre ? 1 : -1))),
  updateFilters: (state, filters) => (state.filters = filters),
  resetFilters: (state) => (state.filters = setDefaultFilters()),
  /* applyFilters: (state) => {
    let checker = (arr, target) => target.every((v) => arr.includes(v));
    var group_caract = state.caracterise.reduce(function(obj, item) {
      obj[item.id_pollen] = obj[item.id_pollen] || [];
      obj[item.id_pollen].push(item.id_motcle);
      return obj;
    }, {});
    state.filtered = state.pollens.filter(
      (r) =>
        (state.filters.famille.length == 0
          ? true
          : state.filters.famille.includes(r.id_famille)
          ? true
          : false) &&
        (state.filters.genre.length == 0
          ? true
          : state.filters.genre.includes(r.id_genre)
          ? true
          : false) &&
        (state.filters.espece.length == 0
          ? true
          : state.filters.espece.includes(r.espece)
          ? true
          : false) &&
        (state.filters.motcle_1.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_1
            )
          ? true
          : false) &&
        (state.filters.motcle_2.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_2
            )
          ? true
          : false) &&
        (state.filters.motcle_3.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_3
            )
          ? true
          : false) &&
        (state.filters.motcle_4.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_4
            )
          ? true
          : false) &&
        (state.filters.motcle_5.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_5
            )
          ? true
          : false) &&
        (state.filters.motcle_6.length == 0
          ? true
          : checker(
              state.caracterise
                .filter((c) => c.id_pollen === r.id_pollen)
                .map((c) => c.id_motcle),
              state.filters.motcle_6
            )
          ? true
          : false)
    );
  }, */

  applyFilters: (state) => {
    let checker = (arr, target) => target.every((v) => arr.includes(v));
    /* var group_caract = state.caracterise.reduce(function(obj, item) {
      obj[item.id_pollen] = obj[item.id_pollen] || [];
      obj[item.id_pollen].push(item.id_motcle);
      return obj;
    }, {}); */
    /* console.log(group_caract); */
    state.filtered = state.pollens.filter(
      (r) =>
        (state.filters.famille.length == 0
          ? true
          : state.filters.famille.includes(r.id_famille)
          ? true
          : false) &&
        (state.filters.genre.length == 0
          ? true
          : state.filters.genre.includes(r.id_genre)
          ? true
          : false) &&
        (state.filters.espece.length == 0
          ? true
          : state.filters.espece.includes(r.espece)
          ? true
          : false) &&
        (state.filters.motcle_1.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_1)
          ? true
          : false) &&
        (state.filters.motcle_2.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_2)
          ? true
          : false) &&
        (state.filters.motcle_3.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_3)
          ? true
          : false) &&
        (state.filters.motcle_4.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_4)
          ? true
          : false) &&
        (state.filters.motcle_5.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_5)
          ? true
          : false) &&
        (state.filters.motcle_6.length == 0
          ? true
          : !(r.id_pollen in state.caractGroup)
          ? false
          : checker(state.caractGroup[r.id_pollen], state.filters.motcle_6)
          ? true
          : false)
    );
  },

  setActiveDetail: (state, index) => (state.activeDetail = index),
  setOpenDetail: (state, isOpen) => (state.openDetail = isOpen),
  setManagePhotoDialog: (state, isOpen) =>
    (state.navigation.managePhotoDetail = isOpen),
  setManageKeywordsDialog: (state, isOpen) =>
    (state.navigation.manageKeywordsDetail = isOpen),
  setAdminActivePollenIndex: (state, index) =>
    (state.navigation.adminActivePollenIndex = index),

  setIsApiError: (state, isError) => (state.isApiError = isError),
  setApiErrorMessage: (state, errorMessage) =>
    (state.apiErrorMessage = errorMessage),

  setCaractGroup: (state) =>
    (state.caractGroup = state.caracterise.reduce(function(obj, item) {
      obj[item.id_pollen] = obj[item.id_pollen] || [];
      obj[item.id_pollen].push(item.id_motcle);
      return obj;
    }, {})),

  /* ADD mutations */
  newFamille: (state, famille) => state.famille.push(famille),
  newGenre: (state, genre) => state.genre.push(genre),
  newCaracterise: (state, caracterise) => state.caracterise.push(caracterise),
  newPollen: (state, pollen) => state.pollens.push(pollen),
  newImage: (state, image) => state.images.push(image),
  newMotcle: (state, motcle) => state.motcle.push(motcle),

  /* UPDATE mutations */
  updateFamille: (state, updFamille) => {
    const index = state.famille.findIndex(
      (fam) => fam.id_famille === updFamille.id_famille
    );

    if (index !== -1) {
      state.famille.splice(index, 1, updFamille);
    }
  },
  updateGenre: (state, updGenre) => {
    const index = state.genre.findIndex(
      (fam) => fam.id_genre === updGenre.id_genre
    );

    if (index !== -1) {
      state.genre.splice(index, 1, updGenre);
    }
  },
  updatePollen: (state, updPollen) => {
    const index = state.pollens.findIndex(
      (fam) => fam.id_pollen === updPollen.id_pollen
    );

    if (index !== -1) {
      state.pollens.splice(index, 1, updPollen);
    }
  },
  updateMotcle: (state, updMotcle) => {
    const index = state.motcle.findIndex(
      (fam) => fam.id_motcle === updMotcle.id_motcle
    );

    if (index !== -1) {
      state.motcle.splice(index, 1, updMotcle);
    }
  },

  /* DELETE mutations */
  removeImage: (state, imageId) =>
    (state.images = state.images.filter((im) => im.id !== imageId)),
  removeCaracterise: (state, caracteriseId) =>
    (state.caracterise = state.caracterise.filter(
      (c) => c.id !== caracteriseId
    )),
  removePollen: (state, pollenId) =>
    (state.pollens = state.pollens.filter((p) => p.id_pollen !== pollenId)),
  removeGenre: (state, genreId) =>
    (state.genre = state.genre.filter((g) => g.id_genre !== genreId)),
  removeFamille: (state, familleId) =>
    (state.famille = state.famille.filter((f) => f.id_famille !== familleId)),
  removeMotcle: (state, id_motcle) =>
    (state.motcle = state.motcle.filter((f) => f.id_motcle !== id_motcle)),

  /* LOGIN */
  loginSuccessful: (state, loginResponse) =>
    (state.user = { isLoggedIn: true, token: loginResponse.token }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
