import Services from "../../../../services";



let userSelectionInit = {
    // datasets info
    "min_lat": -90,
    "max_lat": 90,
    "min_lon": -180,
    "max_lon": 180,
    "min_alt": -9000,
    "max_alt": 9000,
    "archives": [],
    "regions": [],
    "sites": [],
    "projects": ['APDFOSSILE'],
    // pollen sum
    "pollen_sum": {
        "added_habit_types": [],
        "added_pollen_habits": [],
        "added_pollen_families": [],
        "added_pollen_taxons": [],
        "discarded_habit_types": [],
        "discarded_pollen_habits": [],
        "discarded_pollen_families": [],
        "discarded_pollen_taxons": [],
    },
    // taxonomy groups
    "taxonomy_groups": [
        {
            "id": 1, "name": "", "taxons": []
        },
    ],
    // time intervals
    "time_intervals":
        [
            {
                age_min: -50,
                age_max: 5000,
            }
        ],

    /* regular intervals */
    "regular_intervals": {
        "age_min": 0,
        "age_max": 10000,
        "age_step": 1000,
        "interval_size": 200,
    },

    // include columns in spreadsheet output
    "include_columns": {

        "lat": true,
        "lon": true,
        "alt": true,
        "region": true,
        "site_name": true,
        "dataset_name": true,
    },
    "units": {
        is_percent: true,
        depth: "cm",
        age: "calyrbp",
    },
    "visualisation_type": "graph"
};

// Pollen taxonomy levels: HABITTYPE, POLLENHABIT, POLLENFAMILY, POLLENTAXON




const state = {
    // request step
    step: 1,
    stepCompleted: 0,
    isStepValid:{
        1: false,
        2: false,
        3: false,
        4: true,
        5: true,
    },
    

    // datasets info
    archives: [],
    projects: [],
    regions: [],
    sites: [],
    datasets: [],


    // taxonomy info
    taxonomies: [],
    habitTypes: [],
    pollenHabits: [],
    pollenFamilies: [],
    pollenTaxons: [],

    // user selection
    userSelection: userSelectionInit,
    serverResponse: {},
    isLoading: false,
};



const getters = {
    getStep: state => state.step,
    getStepCompleted: state => state.stepCompleted,
    getIsStepValid: state => state.isStepValid,

    getArchives: state => state.archives,
    getTaxonomies: state => state.taxonomies,
    getHabitTypes: state => state.taxonomies.filter(taxonomy => taxonomy.level === 'HABITTYPE'),
    getPollenHabits: state => state.taxonomies.filter(taxonomy => taxonomy.level === 'POLLENHABIT'),
    getPollenFamilies: state => state.taxonomies.filter(taxonomy => taxonomy.level === 'POLLENFAMILY'),
    getPollenTaxons: state => state.taxonomies.filter(taxonomy => taxonomy.level === 'POLLENTAXON'),

    getUserPollenSum: state => state.userSelection.pollen_sum.added_habit_types.concat(
        state.userSelection.pollen_sum.added_pollen_habits,
        state.userSelection.pollen_sum.added_pollen_families,
        state.userSelection.pollen_sum.added_pollen_taxons,),


    getRegularIntervals: state => state.userSelection.regular_intervals,
    



    getTaxonomyGroups: state => state.taxonomyGroups,
    getTaxonomyLevels: state => state.taxonomyLevels,
    getUserSelection: state => state.userSelection,
    getServerResponse: state => state.serverResponse,
    getProjects: state => state.projects,
    getRegions: state => state.regions,
    getSites: state => state.sites,
    getDatasets: state => state.datasets,
    getIsLoading: state => state.isLoading,

};

const actions = {




    async fetchProjects({ commit }) {
        try {
            const response = await Services.project.getProjects();
            commit('setProjects', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchRegions({ commit }) {
        try {
            let options = {
                project_ids: state.userSelection.projects,
            }
            const response = await Services.region.getRegions(options);
            commit('setRegions', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchSites({ commit }) {
        try {
            let region_ids = state.userSelection.regions;
            let project_ids = state.userSelection.projects;
            const response = await Services.site.getSites(region_ids, project_ids);
            commit('setSites', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchDatasets({ commit }) {

        let options = {
            project_ids: ["APDFOSSILE"],
            region_ids: state.userSelection.regions,
            site_ids: state.userSelection.sites,
            material_ids: state.userSelection.materials,
            min_lat: state.userSelection.min_lat,
            max_lat: state.userSelection.max_lat,
            min_lon: state.userSelection.min_lon,
            max_lon: state.userSelection.max_lon,
            min_alt: state.userSelection.min_alt,
            max_alt: state.userSelection.max_alt,
            archive_ids: state.userSelection.archives,
            with_chronology: true,
            with_data: true,
        }
        try {
            const response = await Services.dataset.getDatasetsGeneralInfo(options);
            commit('setDatasets', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchTaxonomies({ commit }) {
        try {

            let options = state.step === 3 ? {
                datasets: state.datasets.map(dataset => dataset.dataset_id),
                with_ancestors: true,
                has_parents: state.userSelection.pollen_sum.added_habit_types.concat(
                    state.userSelection.pollen_sum.added_pollen_habits,
                    state.userSelection.pollen_sum.added_pollen_families,
                    state.userSelection.pollen_sum.added_pollen_taxons,).map(taxon => taxon.taxonomy_id)
            } : {
                datasets: state.datasets.map(dataset => dataset.dataset_id),
                with_ancestors: true,
            }
            const response = await Services.taxonomy.filterTaxonomiesPost(options);
            commit('setTaxonomies', response.data);
        } catch (error) {
            console.error(error);
        }
    },




    async fetchHabitTypes({ commit }) {
        try {
            const response = await Services.taxonomy.getHabitTypes();
            commit('setHabitTypes', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchPollenHabits({ commit }) {
        try {
            const response = await Services.taxonomy.getPollenHabits();
            commit('setPollenHabits', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchPollenFamilies({ commit }) {
        try {
            const response = await Services.taxonomy.getPollenFamilies();
            commit('setPollenFamilies', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchPollenTaxons({ commit }) {
        try {
            const response = await Services.taxonomy.getPollenTaxons();
            commit('setPollenTaxons', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchArchives({ commit }) {
        try {
            const response = await Services.archive.getArchives();
            commit('setArchives', response.data);
        } catch (error) {
            console.error(error);
        }
    },



    /* UPDATE */
    async updateUserSelection({ commit }, userSelection) {
        commit('setUserSelection', userSelection);

    },
    async updateStep({ commit }, step) {
        commit('setStep', step);
    },
    async updateStepCompleted({ commit }, stepCompleted) {
        commit('setStepCompleted', stepCompleted);
    },
    async updateIsStepValid({ commit }, isStepValid) {
        commit('setIsStepValid', isStepValid);
    },
    async updateVisualisationType({ commit }, visualisationType) {
        commit('setVisualisationType', visualisationType);
    },

    async setIsLoading({ commit }, isLoading) {
        commit('setIsLoading', isLoading);
    },

    async updateTimeIntervals({ commit }, timeIntervals) {
        commit('setTimeIntervals', timeIntervals);
    },
    async updateRegularIntervals({ commit }, regularIntervals) {
        commit('setRegularIntervals', regularIntervals);
    },

    /* SEND REQUEST */
    async sendRequest({ commit, state }) {
        try {
            commit('setIsLoading', true);
            let taxonomy_groups_obj = {};
            state.userSelection.taxonomy_groups.forEach(taxonomy_group => {
                taxonomy_groups_obj[taxonomy_group.name] = taxonomy_group.taxons.map(taxon => taxon.taxonomy_id);
            });

            let pollen_sum_list = state.userSelection.pollen_sum.added_habit_types.concat(
                state.userSelection.pollen_sum.added_pollen_habits,
                state.userSelection.pollen_sum.added_pollen_families,
                state.userSelection.pollen_sum.added_pollen_taxons,).map(taxon => taxon.taxonomy_id)

            let pollen_sum_discard = state.userSelection.pollen_sum.discarded_habit_types.concat(
                state.userSelection.pollen_sum.discarded_pollen_habits,
                state.userSelection.pollen_sum.discarded_pollen_families,
                state.userSelection.pollen_sum.discarded_pollen_taxons,).map(taxon => taxon.taxonomy_id)


            let userSelection = {
                min_lat: state.userSelection.min_lat,
                max_lat: state.userSelection.max_lat,
                min_lon: state.userSelection.min_lon,
                max_lon: state.userSelection.max_lon,
                min_alt: state.userSelection.min_alt,
                max_alt: state.userSelection.max_alt,
                regions: state.userSelection.regions,
                sites: state.userSelection.sites,
                taxonomy_groups: taxonomy_groups_obj,
                pollen_sum_include: pollen_sum_list,
                pollen_sum_discard: pollen_sum_discard,
                time_intervals: state.userSelection.time_intervals,
                units: state.userSelection.units,
                include_columns: state.userSelection.include_columns,
                visualisation_type: state.userSelection.visualisation_type,
                regular_interval_age_min: state.userSelection.regular_intervals.age_min,
                regular_interval_age_max: state.userSelection.regular_intervals.age_max,
                regular_interval_age_step: state.userSelection.regular_intervals.age_step,
                regular_interval_size: state.userSelection.regular_intervals.interval_size,
            };

            const response = await Services.query.fossilPollenPercentageQuery(userSelection);
            commit('setServerResponse', response.data);
            commit('setIsLoading', false);

        } catch (error) {
            console.error(error);
        }
    }
};

const mutations = {
    /* SET */

    setProjects: (state, projects) => {
        state.projects = projects;
    },
    setRegions: (state, regions) => {
        state.regions = regions;
    },
    setArchives: (state, archives) => {
        state.archives = archives;
    },
    setSites: (state, sites) => {
        state.sites = sites;
    },
    setDatasets: (state, datasets) => {
        state.datasets = datasets;
    },
    setHabitTypes: (state, habitTypes) => {
        state.habitTypes = habitTypes;
    },
    setPollenHabits: (state, pollenHabits) => {
        state.pollenHabits = pollenHabits;
    },
    setPollenFamilies: (state, pollenFamilies) => {
        state.pollenFamilies = pollenFamilies;
    },
    setPollenTaxons: (state, pollenTaxons) => {
        state.pollenTaxons = pollenTaxons;
    },
    setTaxonomies: (state, taxonomies) => {
        state.taxonomies = taxonomies;
    },

    /* UPDATE */
    setUserSelection: (state, userSelection) => {
        state.userSelection = userSelection;
    },
    /* SERVER RESPONSE */
    setServerResponse: (state, serverResponse) => {
        state.serverResponse = serverResponse;
    },

    setTimeIntervals: (state, timeIntervals) => {
        state.userSelection.time_intervals = timeIntervals;
    },
    setRegularIntervals: (state, regularIntervals) => {
        state.userSelection.regular_intervals = regularIntervals;
    },

    setIsLoading: (state, isLoading) => {
        state.isLoading = isLoading;
    },
    setStep: (state, step) => {
        state.step = step;
    },
    setStepCompleted: (state, stepCompleted) => {
        state.stepCompleted = stepCompleted;
    },
    setIsStepValid: (state, isStepValid) => {
        state.isStepValid = isStepValid;
    },
    setVisualisationType: (state, visualisationType) => {
        state.userSelection.visualisation_type = visualisationType;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};