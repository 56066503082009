<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-avatar dark class="white--text" size="50px">
          <v-icon color="#2B2118" size="35px">mdi-chart-scatter-plot</v-icon>
        </v-avatar>
        <v-row>
          <v-col cols="12">
            <h1>Visualisation Tools</h1>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="color: primary"></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">

            <v-btn :to="{ name: 'VisualisationToolsMenu' }" :disabled="$route.name === 'VisualisationToolsMenu'"
              class="mr-2" color="white"><v-icon>mdi-home</v-icon></v-btn>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <v-alert dense border="bottom" colored-border type="warning" elevation="1">
              Please keep in mind that original age models of sites are included in the database: chronologies have not
              been harmonized between sites!
            </v-alert>
          </v-col>
        </v-row> -->
        
        
        <v-row>
          <v-col cols="12">
            <router-view></router-view>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>


  </div>
</template>

<script>

export default {

  data() {
    return {

    };
  },
};
</script>