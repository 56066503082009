/* import axios from "axios";
var urljoin = require("url-join");

const HOST_ROOT = process.env.VUE_APP_ROOT_API; */

function setDefaultState() {
  return {
    default_pollen_sum: true,
    pollen_habits: {
      mode:'default',
      selected: [],
      discarded: [],
      validated: false,
      completed: false,
    },
  };
}

const state = {
  req: setDefaultState(),
};

const getters = {
  getState: (state) => state.req,
  getDefaultPollenSum: (state) => state.req.default_pollen_sum,
  getPollenHabitsForm: (state) => state.req.pollen_habits,
};

const actions = {
  /* async fetchDatasets({ commit, rootState }) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.get(
        urljoin(HOST_ROOT, "api/fossil-generalinfo/"),
        {
          headers: headers,
        }
      );

      commit("setDatasets", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
    }
  }, */

  setDefaultPollenSum({ commit }, defaultSum) {
    commit("setDefaultPollenSum", defaultSum);
  },

  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setDefaultPollenSum: (state, defaultSum) =>
    (state.req.default_pollen_sum = defaultSum),
  resetState: (state) => (state.req = setDefaultState()),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
