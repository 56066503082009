import Api from "./axios"
import axios from "axios"

const doiOrgInstance = axios.create({
    baseURL: process.env.VUE_APP_DOI_API,
    // other configuration options
});

const doiService = {
    getDatasetsDOI() {
        return Api.get("/datasets-dois/")
    },
    getDatasetDOI(dataset_id) {
        return Api.get(`/datasets-dois/?dataset_id=${dataset_id}`)
    },
    getDOImetadata(doi) {
        let headers = {'Accept': 'application/vnd.citationstyles.csl+json'}
        return doiOrgInstance.get(`${doi}`, {headers:headers})
    },
    registerDOI(data) {
        return Api.post("/datasets-dois/", data)
    },
}

export default doiService;


