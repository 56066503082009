<template>
  <v-app style="background-color: #f7f3e3">
    <!-- APP BAR -->
    <v-app-bar color="#2B2118" app clipped-left flat dark>
      <img
        :src="require('@/assets/bestiole_light_short.png')"
        width="100px"
        alt="logo"
      />

      <div>
        <v-toolbar-title> African Pollen Database </v-toolbar-title>
      </div>

      <v-spacer> </v-spacer>
    </v-app-bar>

    <!-- NAVIGATION DRAWER  -->
    <v-navigation-drawer
      app
      color="#2B2118"
      clipped
      permanent
      expand-on-hover
      flat
      dark
      v-model="drawer"
      :mini-variant.sync="mini"
    >
      <v-list>
        <!-- <v-list-item :to="{ name: 'WelcomePage' }" key="home">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item> -->
        <v-list-group
          :value="false"
          color="white"
          prepend-icon="mdi-home-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>Home</v-list-item-title>
          </template>

          <v-list-item
            dense
            v-for="item in homepages"
            :key="item.title"
            :to="item.route"
            :disabled="item.disabled"
          >
            <v-list-item-icon>
              <v-avatar color="#f7f3e3" size="30" class="black--text">{{
                item.avatar
              }}</v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-list-item
          :key="taxonomies.title"
          :to="taxonomies.route"
          :disabled="taxonomies.disabled"
        >
          <v-list-item-icon>
            <v-icon>{{ taxonomies.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ taxonomies.title }}</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-list-group :value="false" color="white" prepend-icon="mdi-bookshelf">
          <template v-slot:activator>
            <v-list-item-title>Data Libraries</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in libraries"
            :key="item.title"
            :to="item.route"
            :disabled="item.disabled"
          >
            <v-list-item-icon>
              <v-avatar color="#f7f3e3" size="30" class="black--text">{{
                item.avatar
              }}</v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="false" color="white" prepend-icon="mdi-database">
          <template v-slot:activator>
            <v-list-item-title>Database</v-list-item-title>
            
          </template>
          <v-list-item
            v-for="item in databases"
            :key="item.title"
            :to="item.route"
            :disabled="item.disabled"
          >
            <v-list-item-icon>
              <v-avatar color="#f7f3e3" size="30" class="black--text">{{
                item.avatar
              }}</v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="false"
          color="white"
          prepend-icon="mdi-image-search-outline"
        >
          <template v-slot:activator>
            <v-list-item-title>Pollen Images</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in pollenimages"
            :key="item.title"
            :to="item.route"
            :disabled="item.disabled"
          >
            <v-list-item-icon>
              <v-avatar color="#f7f3e3" size="30" class="black--text">{{
                item.avatar
              }}</v-avatar>
            </v-list-item-icon>
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'AdminDatasetsList', params: { modernfossil: 'fossil' } }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Admin Page (LiPD)</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'AdminPhotos' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Admin Pollen Photos</v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutRequest" key="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Logout</v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item v-if="!mini">
          <v-list-item-icon></v-list-item-icon><v-spacer></v-spacer>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <!-- <v-list>
        <v-list-item :to="{ name: 'Home' }" key="home">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Accueil</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          :disabled="item.disabled"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'Admin' }" key="admin-area">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Page Administrateur</v-list-item-content>
        </v-list-item>
        <v-list-item @click="logoutRequest" key="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Se déconnecter</v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-navigation-drawer>

    <!-- MAIN -->
    <v-main>
      <router-view />
      <v-snackbar
        v-model="getIsApiError"
        dark
        color="red darken-2"
        :timeout="3000"
        absoute
      >
        {{ getApiErrorMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="setIsLoading(false); setApiError(false, '')">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog :value="getIsLoading && !getIsApiError" persistent max-width="300px">
        <v-card>
          <v-card-text class="text-center">
            <v-container>
              <v-row>
                <v-col>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>{{ getLoadingMessage }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>

    <v-footer  bottom dark padless color="#2B2118" elevation="0">
      <v-row justify="center" align="center">
        <a href="https://www.belmontforum.org/">
          <img
            class="mt-3 mr-3"
            :src="require('./assets/belmont.png')"
            width="200px"
            height="50px"
            alt="logo"
          />
        </a>
        <a href="https://www.ipsl.fr/">
          <img
            class="mt-3 mr-3"
            :src="require('./assets/ipsl.png')"
            width="80px"
            alt="logo"
          />
        </a>

        <a href="https://www.locean-ipsl.upmc.fr/">
          <img
            class="mt-3"
            :src="require('./assets/locean.png')"
            width="100px"
            alt="logo"
          />
        </a>

        <v-col class="text-center" cols="12">
          {{ new Date().getFullYear() }} — Developed and maintained by A.
          Morichetta
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;
export default {
  name: "App",
  methods: {
    ...mapActions(["setApiError", "logout"]),
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    ...mapActions("jsonconfig", ["fetchConfig"]),
    logoutRequest() {
      this.logout();
      this.$router.push("/").catch((failure) => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          // show a small notification to the user
          console.log(failure);
        }
      });
    },
  },

  data: () => ({
    timeout: 2000,
    drawer: true,
    homepages: [
      {
        title: "Welcome Page",
        route: { name: "WelcomePage" },
        avatar: "W",
        disabled: false,
      },
      {
        title: "About",
        route: { name: "HomeAbout" },
        avatar: "A",
        disabled: false,
      },
      {
        title: "APD Council",
        route: { name: "APDCouncil" },
        avatar: "C",
        disabled: false,
      },
      {
        title: "Protocols",
        route: { name: "Protocols" },
        avatar: "P",
        disabled: false,
      },
      {
        title: "How to contribute",
        route: { name: "HowTo" },
        avatar: "P",
        disabled: false,
      },
      {
        title: "Archives",
        route: { name: "Archives" },
        avatar: "AR",
        disabled: false,
      },
      {
        title: "Links",
        avatar: "L",
        disabled: true,
      },
    ],
    
    libraries: [
      /* {
        title: "Fossil pollen data",
        icon: "mdi-database-clock-outline",
        route: { name: "Fossile" },
        avatar: "FS",
        disabled: false,
      }, */
      {
        title: "Fossil pollen data",
        icon: "mdi-database-clock-outline",
        route: { name: "DataLibrary", params: { modernfossil: "fossil" } },
        avatar: "FS",
        disabled: false,
      },
      {
        title: "Modern pollen data",
        icon: "mdi-database-refresh-outline",
        route: { name: "Moderne" },
        avatar: "MD",
        disabled: false,
      },
    ],
    taxonomies: {
      title: "APD taxonomy list",
      icon: "mdi-order-alphabetical-ascending",
      route: { name: "TaxonDict" },
      avatar: "Dict.",
      disabled: false,
    },
    databases:[
      {
        title: "Visualisation Tools",
        icon: "mdi-image-search-outline",
        route: { name: "VisualisationToolsMenu" },
        avatar: "VT",
        disabled: false,
      },
      /* {
        title: "DB Pollen Fossile",
        icon: "mdi-image-search-outline",
        route: { name: "DBQueryHomepage" },
        avatar: "DT",
        disabled: true,
      } */
    ],
    pollenimages: [
      {
        title: "Determination Tool",
        icon: "mdi-image-search-outline",
        route: { name: "Photos" },
        avatar: "DT",
        disabled: false,
      },
      {
        title: "Pollen Atlases",
        icon: "mdi-image-search-outline",
        route: { name: "PollenAtlases" },
        avatar: "PA",
        disabled: false,
      },
    ],
    mini: true,
  }),
  computed: {
    ...mapGetters("auth", [
      "getIsApiError",
      "getApiErrorMessage",
      "getIsLoggedIn",
      "getIsLoading",
      "getLoadingMessage",
    ]),
  },
  created() {
    this.fetchConfig();
  },
};
</script>


<style>
</style>
