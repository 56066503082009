import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import TaxonDict from "../views/TaxonDict.vue";
import store from "@/store/index";
import PollenFossileEdit from "../components/admin/adminfossile/AdminDatasetDialog.vue";
import PollenModerneEdit from "../components/admin/adminmoderne/AdminDatasetDialog.vue";
import DOIAdminView from "../components/v2/admin/admindois/DOIAdminView.vue";
import DOImain from "../components/v2/admin/admindois/DOImain.vue";
import DOIEditForm from "../components/v2/admin/admindois/DOIEditForm.vue";
/* import DatasetLandingPage from "../components/dataset/DatasetLandingPage.vue"; */
import VisualisationTools from "../views/VisualisationTools.vue";
import VisualisationToolsMenu from "../components/visualisation-tools/VisualisationToolsMenu.vue";
import DataLibraryContainer from "../views/DataLibraryContainer.vue";
import DataLibrary from "../components/v2/datalibrary/DataLibrary.vue";
import LoginLipd from "../views/LoginLipd.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/home",
    component: Home,
    children: [
      {
        path: "home",
        name: "WelcomePage",
        component: () =>
          import(
            /* webpackChunkName: "WelcomePage" */ "../components/home/WelcomePage.vue"
          ),
      },
      {
        path: "about",
        name: "HomeAbout",
        component: () =>
          import(
            /* webpackChunkName: "HomeAbout" */ "../components/home/HomeAbout.vue"
          ),
      },
      {
        path: "apd-council",
        name: "APDCouncil",
        component: () =>
          import(
            /* webpackChunkName: "APDCouncil" */ "../components/home/APDCouncil.vue"
          ),
      },
      {
        path: "protocols",
        name: "Protocols",
        component: () =>
          import(
            /* webpackChunkName: "Protocols" */ "../components/home/Protocols.vue"
          ),
      },
      {
        path: "archives",
        name: "Archives",
        component: () =>
          import(
            /* webpackChunkName: "Archives" */ "../components/home/Archives.vue"
          ),
      },
      {
        path: "how-to-contribute-query",
        name: "HowTo",
        component: () =>
          import(
            /* webpackChunkName: "HowTo" */ "../components/home/HowTo.vue"
          ),
      },
    ],
  },
  /*  {
     path: "/dataset/:modernfossil/:uuid",
     name: "DatasetLandingPage",
     component: DatasetLandingPage,
   }, */
  {
    path: "/photos",
    name: "Photos",
    component: () =>
      import(/* webpackChunkName: "Photos" */ "../views/MainComponent.vue"),
  },
  {
    path: "/viztry",
    name: "VizTry",
    component: () =>
      import(/* webpackChunkName: "VizTry" */ "../views/VizTry.vue"),
  },
  {
    path: "/fossil-db-query",
    component: () =>
      import(
        /* webpackChunkName: "FossileDB" */ "../views/PollenFossileDB.vue"
      ),
    redirect: "",
    children: [
      {
        path: "",
        name: "DBQueryHomepage",
        component: () =>
          import(
            /* webpackChunkName: "DBQueryHomepage" */ "../components/db/DBQueryHomepage.vue"
          ),
      },
      {
        path: "pollen-percentage/",
        component: () =>
          import(
            /* webpackChunkName: "PollenPercentage" */ "../components/db/DBPercentageForm.vue"
          ),
        children: [
          {
            path: "",
            redirect: "pollen-sum-definition", // default child path
          },
          {
            path: "pollen-sum-definition",
            name: "DefinitionPollenSum",
            component: () =>
              import(
                /* webpackChunkName: "DefinitionPollenSum" */ "../components/db/DefinitionPollenSum.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/visualisation-tools",
    component: VisualisationTools,
    children: [
      {
        path: "",
        name: "VisualisationToolsMenu",
        component: VisualisationToolsMenu,
      },
      {
        path: "fossil-pollen-percentage",
        name: "FossilPollenPercentage",
        props: true,
        component: () =>
          import(/* webpackChunkName: "FossilPollenPercentage" */ "../components/visualisation-tools/fossil-pollen-percentage/FossilPollenPercentageMain.vue"),
      },
    ],
  },
  {
    path: "/admin-v2",
    name: "AdminV2",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "admin-v2" */ "../components/v2/admin/Admin.vue"),
    children: [
      {
        meta: { requiresAuth: true },
        path: "datasets/:modernfossil",
        component: () =>
          import(
                /* webpackChunkName: "admin-datasets-main" */ "../components/v2/admin/AdminDatasetsMain.vue"
          ),
        children: [
          {
            meta: { requiresAuth: true },
            path: "",
            name: "AdminDatasetsList",
            component: () =>
              import(
                    /* webpackChunkName: "admin-datasets-list" */ "../components/v2/admin/AdminDatasetsList.vue"
              ),
          },
          {
            path: "edit-dataset/:id",
            name: "AdminNewDataset",
            meta: { requiresAuth: true },
            component: () =>
              import(
                    /* webpackChunkName: "admin-new-dataset" */ "../components/v2/admin/AdminNewDataset.vue"
              ),
          },
          {
            path: "edit-dataset/:id/edit-table/:table_id",
            name: "AdminEditTable",
            meta: { requiresAuth: true },
            component: () =>
              import(
                    /* webpackChunkName: "admin-edit-table" */ "../components/v2/admin/edit-dataset/edit-table/EditTableMain.vue"
              ),
          },
          {
            path: "edit-doi/:uuid",
            name: "DOIAdminView",
            meta: { requiresAuth: true },
            redirect: {
              name: "DOImain",
            },
            component: DOIAdminView,

            children: [
              {
                path: "",
                name: "DOImain",
                meta: { requiresAuth: true },
                component: DOImain,

              },
              {
                path: ":editorcreate",
                name: "DOIEditForm",
                meta: { requiresAuth: true },
                component: DOIEditForm,

              },
              {
                path: "associate-existing",
                name: "DOIAssociateExisting",
                meta: { requiresAuth: true },
                component: () =>
                  import(
                    /* webpackChunkName: "DOIAssociateExisting" */ "../components/v2/admin/admindois/DOIAssociateExisting.vue"
                  ),

              },
            ],
          },

        ]

      }


    ]

  },
  {
    path: "/dataset/:modernfossil",
    component: DataLibraryContainer,

    children: [
      {
        path: "",
        name: "DataLibrary",
        component: DataLibrary,

      },
      {
        path: ":id",
        name: "DatasetDetail",
        props: true,
        component: () =>
          import(/* webpackChunkName: "DatasetDetail" */ "../components/v2/datalibrary/dataset/DatasetDetail.vue"),
      },

    ],
  },
  {
    path: "/pollen-atlases",
    name: "PollenAtlases",
    component: () =>
      import(/* webpackChunkName: "Photos" */ "../views/PollenAtlases.vue"),
  },
  {
    path: "/pollen-fossile",
    name: "Fossile",
    component: () =>
      import(/* webpackChunkName: "Fossile" */ "../views/PollenFossile.vue"),
  },
  {
    path: "/pollen-moderne",
    name: "Moderne",
    component: () =>
      import(
        /* webpackChunkName: "Moderne" */ "../views/LipdPollenModerne.vue"
      ),
  },
  {
    path: "/pollen-moderne/detail/:idsample",
    name: "PollenModerneDetail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PollenModerneDetail" */ "../components/pollenmoderne/PollenModerneDetailDialog.vue"
      ),
  },
  {
    path: "/taxon-dict",
    name: "TaxonDict",
    // component: () => import(/* webpackChunkName: "TaxonDict" */ "../views/TaxonDict.vue"),
    component: TaxonDict,
  },

  {
    path: "/admin-area",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin-area" */ "../views/Admin.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "pollen-photos",
        name: "AdminPhotos",
        component: () =>
          import(
            /* webpackChunkName: "AdminPhotos" */ "../components/admin/adminphotos/AdminPhotos.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "taxon-dict",
        name: "AdminTaxonDict",
        component: () =>
          import(
            /* webpackChunkName: "AdminTaxonDict" */ "../components/admin/admintaxondict/AdminTaxonDict.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "unregistered-taxons",
        name: "UnregisteredTaxons",
        component: () =>
          import(
            /* webpackChunkName: "UnregisteredTaxons" */ "../components/admin/admintaxondict/UnregisteredTaxons.vue"
          ),
      },
      {
        path: "fossil-pollen/edit-dataset",
        name: "PollenFossileEdit",
        component: PollenFossileEdit,
        meta: { requiresAuth: true },
      },

      {
        path: "fossil-pollen",
        name: "AdminFossil",
        component: () =>
          import(
            /* webpackChunkName: "AdminFossil" */ "../components/admin/adminfossile/AdminDatasets.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "modern-pollen",
        name: "AdminModerne",
        component: () =>
          import(
            /* webpackChunkName: "AdminModerne" */ "../components/admin/adminmoderne/AdminDatasets.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "modern-pollen/edit-dataset",
        name: "PollenModerneEdit",
        component: PollenModerneEdit,
        meta: { requiresAuth: true },
      },
      {
        path: ":fossilormodern/:uuid/doi/",
        name: "DOIAdminView1",
        redirect: {
          name: "DOImain1",
        },
        component: DOIAdminView,

        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: "DOImain1",
            component: DOImain,
            meta: { requiresAuth: true },
          },
          {
            path: ":editorcreate",
            name: "DOIEditForm1",
            component: DOIEditForm,
            meta: { requiresAuth: true },
          },
          {
            path: "associate-existing",
            name: "DOIAssociateExisting1",
            component: () =>
              import(
                /* webpackChunkName: "DOIAssociateExisting" */ "../components/admin/admindois/DOIAssociateExisting.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login-lipd",
    name: "LoginLipd",
    component: LoginLipd,
  },
];

const router = new VueRouter({
  /* mode: 'history', */
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    console.log(to)
    if (to.path.includes("admin-v2")) {
      if (!store.getters["authlipd/getToken"]) {
        next({
          name: "LoginLipd",
        });
      } else {
        next();
      }
    } else {
      if (!store.getters["auth/getToken"]) {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    }

  } else {
    next();
  }
});
export default router;
