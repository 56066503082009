<template>
  <v-container>
    <v-row align-content="center" justify="center">
      <v-col xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card flat>
          <v-card-title>
            <v-toolbar dark color="#2B2118">
              <v-btn icon dark @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{
                getIsEdit === "edit"
                  ? "Edit dataset"
                  : getIsEdit === "create"
                  ? "Add new dataset"
                  : ""
              }}</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!-- SPEED DIAL MENU AT RIGHT BOTTOM OF THE PAGE -->
              <v-speed-dial v-model="fab" bottom right fixed>
                <template v-slot:activator>
                  <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab"> mdi-close </v-icon>
                    <v-icon v-else> mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-tooltip v-if="getIsEdit === 'edit'" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      small
                      color="#757575"
                      v-bind="attrs"
                      v-on="on"
                      @click="saveEditDataset(getEditJsonNodata.id_dataset)"
                      :disabled="!getEditJsonNodata.dataSetName"
                    >
                      <v-icon>mdi-content-save-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Save modifications</span>
                </v-tooltip>
                <v-tooltip
                  v-if="getIsEdit === 'create' && getEditJsonNodata.dataSetName"
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      small
                      color="#757575"
                      v-bind="attrs"
                      v-on="on"
                      @click="saveNewDataset()"
                      :disabled="!getEditJsonNodata.dataSetName"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save new dataset</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      v-bind="attrs"
                      v-on="on"
                      @click="previewDialog = true"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Preview LiPD</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      small
                      color="red"
                      @click="resetState()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-undo-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset All</span>
                </v-tooltip>
              </v-speed-dial>

              <v-row align-content="center" justify="center">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="10">
                  <!-- Load from file -->
                  <v-row
                    v-if="getIsEdit === 'create'"
                    align-content="center"
                    justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Load from file
                        </v-card-title>
                        <v-card-subtitle>
                          Load from external file (.xlsx template or .lpd
                          archive)
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="getIsEdit === 'create'"
                    align-content="center"
                    justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <v-btn-toggle
                            mandatory
                            v-model="lpdorxlsx"
                            color="white"
                          >
                            <v-btn
                              :color="lpdorxlsx === 1 ? 'success' : ''"
                              :value="1"
                              ><v-icon
                                :color="lpdorxlsx === 1 ? 'white' : ''"
                                left
                                >mdi-file-excel-outline</v-icon
                              >.xlsx</v-btn
                            ><v-spacer></v-spacer>
                            <v-btn
                              :color="lpdorxlsx === 2 ? 'warning' : ''"
                              :value="2"
                              ><v-icon
                                :color="lpdorxlsx === 2 ? 'white' : ''"
                                left
                                >mdi-folder-zip-outline</v-icon
                              >.lpd</v-btn
                            >
                          </v-btn-toggle>
                          <v-spacer></v-spacer>
                          <v-file-input
                            :accept="
                              lpdorxlsx === 1
                                ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                : '.lpd'
                            "
                            :label="
                              lpdorxlsx === 2
                                ? 'Upload your .lpd archive here.'
                                : 'Upload your .xlsx file here.'
                            "
                            v-model="inputFile"
                          ></v-file-input>
                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="!inputFile"
                            :dark="!!inputFile"
                            @click="submitFile()"
                            ><v-icon left>mdi-table-arrow-down</v-icon>Load LiPD
                            data</v-btn
                          >
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- GENERAL INFORMATION -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          General Information
                        </v-card-title>
                        <v-card-subtitle>
                          Dataset name, archive type, investigators, ...
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="6"
                              ><v-text-field
                                dense
                                @input="setGeneralInfo($event, 'dataSetName')"
                                v-model="getEditGeneralInfo.dataSetName"
                                outlined
                                label="Dataset Name *"
                              >
                              </v-text-field
                            ></v-col>
                            <v-col cols="12" sm="6"
                              ><v-combobox
                                @input="setGeneralInfo($event, 'createdBy')"
                                v-model="getEditGeneralInfo.createdBy"
                                outlined
                                dense
                                clearable
                                :items="['Python', 'Matlab']"
                                label="Created by"
                              ></v-combobox
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12"
                              ><v-text-field
                                @input="setGeneralInfo($event, 'investigators')"
                                v-model="getEditGeneralInfo.investigators"
                                hint="Use the format: LastName, FirstName; LastName, FirstName; ..."
                                outlined
                                dense
                                label="Investigators *"
                              >
                              </v-text-field
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="4"
                              ><v-combobox
                                outlined
                                @input="setGeneralInfo($event, 'archiveType')"
                                v-model="getEditGeneralInfo.archiveType"
                                clearable
                                dense
                                :items="getConfigSelectOptions['archiveType']"
                                label="Archive Type *"
                              ></v-combobox
                            ></v-col>
                            <v-col cols="12" sm="4"
                              ><v-text-field
                                outlined
                                @input="setGeneralInfo($event, 'archiveType2')"
                                v-model="getEditGeneralInfo.archiveType2"
                                dense
                                label="Archive Type - Details"
                              ></v-text-field
                            ></v-col>
                            <v-col cols="12" sm="4"
                              ><v-text-field
                                outlined
                                @input="setGeneralInfo($event, 'archiveType3')"
                                v-model="getEditGeneralInfo.archiveType3"
                                dense
                                label="Archive Type - Notes"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="8"
                              ><v-text-field
                                outlined
                                v-model="
                                  getEditGeneralInfo.samplingDevice.device
                                "
                                clearable
                                dense
                                label="Sampling Device"
                              ></v-text-field
                            ></v-col>
                            <v-col cols="12" sm="4"
                              ><v-text-field
                                outlined
                                v-model="
                                  getEditGeneralInfo.samplingDevice
                                    .coreDiameterCm
                                "
                                type="number"
                                dense
                                suffix="cm"
                                label="Sampling Device - Core Diameter"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12"
                              ><v-text-field
                                dense
                                outlined
                                label="Notes"
                                @input="setGeneralInfo($event, 'notes')"
                                v-model="getEditGeneralInfo.notes"
                              >
                              </v-text-field
                            ></v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Site information -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Site Information
                        </v-card-title>
                        <v-card-subtitle>
                          Coordinates, Country, Site Name, Site Description, ...
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <FormSite></FormSite>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Publication(s) -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Publication(s)
                        </v-card-title>
                        <v-card-subtitle>
                          Author, Title, Year, Journal, ...
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <FormPublication></FormPublication>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Funding-->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Funding(s)
                        </v-card-title>
                        <v-card-subtitle>
                          Agency, Grant, Country, ...
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <FormFunding></FormFunding>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Paleodata -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#E3F2FD" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Paleodata
                        </v-card-title>
                        <v-card-subtitle>
                          Enter here paleoenvironmental data (raw counts,
                          lithology, ...)
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <FormPaleodata></FormPaleodata>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Chrondata -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#E8F5E9" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Chrondata
                        </v-card-title>
                        <v-card-subtitle>
                          Enter here chronology data (default chronology, C14
                          measurements, agebasis measurements, ...)
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <FormChrondata></FormChrondata>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <!-- Is public / private -->
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card color="#F5F5F5" class="mb-0" flat>
                        <v-card-title
                          class="text-h5"
                          style="word-break: keep-all"
                        >
                          Is public/private
                        </v-card-title>
                        <v-card-subtitle>
                          Set the dataset as public or private
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" justify="center"
                    ><v-col justify="center" md="12" lg="12">
                      <v-card
                        color="rgb(255, 0, 0, 0)"
                        class="mb-12"
                        elevation="1"
                      >
                        <v-card-text>
                          <v-switch
                            v-model="getEditGeneralInfo.is_public"
                            :label="`Is public: ${
                              getEditGeneralInfo.is_public !== undefined
                                ? getEditGeneralInfo.is_public.toString()
                                : ''
                            }`"
                          ></v-switch>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <DialogPreview :dialog.sync="previewDialog"></DialogPreview>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- </v-dialog> -->
</template>

<script>
import DialogPreview from "./edit-dataset/DialogPreview.vue";
import FormSite from "./edit-dataset/FormSite.vue";
import FormPublication from "./edit-dataset/FormPublication.vue";
import FormPaleodata from "./edit-dataset/FormPaleodata.vue";
import FormChrondata from "./edit-dataset/FormChrondata.vue";
import FormFunding from "./edit-dataset/FormFunding.vue";
import JSZip from "jszip";
import axios from "axios";
import urljoin from "url-join";
const HOST_ROOT = process.env.VUE_APP_ROOT_API;
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: true,
      tab: null,
      inputFile: null,
      fab: false,
      lpdorxlsx: 1,
      previewDialog: false,
    };
  },
  components: {
    FormSite,
    FormPublication,
    FormFunding,
    FormPaleodata,
    FormChrondata,
    DialogPreview,
  },
  computed: {
    ...mapGetters("admin_fossilpollen", [
      "getEditCompleteJson",
      "getEditGeneralInfo",
      "getEditJsonNodata",
      "getEditGeo",
      "getIsEdit",
      "getEditDialog",
    ]),
    ...mapGetters("jsonconfig", ["getConfigSelectOptions"]),
  },
  methods: {
    ...mapActions("admin_fossilpollen", [
      "editDataset",
      "fetchDatasets",
      "addNewDataset",
      "setEditedItem",
      "setEditDialog",
    ]),
    ...mapActions("auth", ["setIsLoading", "setLoadingMessage"]),
    closeDialog() {
      this.setEditDialog(false);
      this.$router.go(-1);
    },
    async saveNewDataset() {
      this.setLoadingMessage(
        "Saving new dataset in the database. Please wait..."
      );
      this.setIsLoading(true);
      await this.addNewDataset();
      /* await this.fetchDatasets(); */
      await this.closeDialog();
      this.setIsLoading(false);
      this.setLoadingMessage("");
    },
    async saveEditDataset(id_dataset) {
      this.setLoadingMessage(
        "Saving dataset information. Please wait..."
      );
      this.setIsLoading(true);
      await this.editDataset(id_dataset);
      /* await this.fetchDatasets(); */
      await this.closeDialog();
      this.setIsLoading(false);
      this.setLoadingMessage("");
    },
    setGeneralInfo(e, varName) {
      this.$store.commit("admin_fossilpollen/setEditGeneralInfo", {
        ...this.getEditGeneralInfo,
        [varName]: e,
      });
    },

    async submitFile() {
      /* let _this = this; */
      let zip = null;
      if (this.lpdorxlsx == 2) {
        zip = await JSZip.loadAsync(this.inputFile);
      } else if (this.lpdorxlsx === 1) {
        const headers = {};
        let formData = new FormData();
        formData.append("file", this.inputFile);

        let response = await axios.post(
          urljoin(HOST_ROOT, "load-xlsx"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
            responseType: "arraybuffer",
          }
        );

        zip = await JSZip.loadAsync(response.data);
      }
      let zipfiles = Object.keys(zip.files);
      let jsonfile = zipfiles.find(
        (f) => f.endsWith("jsonld") || f.endsWith("json")
      );
      let txtData = await zip.file(jsonfile).async("text");
      let jsonData = JSON.parse(txtData);
      console.log(jsonData);
      let paleoData = jsonData.paleoData.map(async (m) => {
        return {
          ...m,
          measurementTable: await Promise.all(
            m.measurementTable.map(async (t) => {
              var paleofile = zipfiles.find((f) => f.endsWith(t.filename));
              if (paleofile === undefined) {
                throw "Couldn't find csv file: " + t.tableName;
              }
              function processs(dataString) {
                var lines = dataString
                  .split(/\n/) // Convert to one string per line
                  .map(function (lineStr) {
                    return lineStr.split(","); // Convert each line to array (,)
                  });

                return lines;
              }
              let csvdata = await zip.file(paleofile).async("text");
              return {
                ...t,
                data: processs(csvdata),
                filetype: null,
              };
            })
          ),
        };
      });

      let chronData = jsonData.chronData.map(async (m) => {
        return {
          ...m,
          measurementTable: await Promise.all(
            m.measurementTable.map(async (t) => {
              var paleofile = zipfiles.find((f) => f.endsWith(t.filename));
              if (paleofile === undefined) {
                throw "Couldn't find csv file: " + t.tableName;
              }
              function processs(dataString) {
                var lines = dataString
                  .split(/\n/) // Convert to one string per line
                  .map(function (lineStr) {
                    return lineStr.split(","); // Convert each line to array (,)
                  });

                return lines;
              }
              let csvdata = await zip.file(paleofile).async("text");
              return {
                ...t,
                data: processs(csvdata),
                filetype: null,
              };
            })
          ),
        };
      });

      jsonData.paleoData = await Promise.all(paleoData);
      jsonData.chronData = await Promise.all(chronData);

      this.setEditedItem(jsonData);

      this.inputFile = null;
    },
  },
  watch: {
    lpdorxlsx() {
      this.inputFile = null;
    },
  },
};
</script>

<style>
</style>