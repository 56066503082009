import axios from "axios";
import store from "@/store/index";


const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_PALEODATAIPSL_API
    // other configuration options
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Modify the request config here (e.g. add headers, authentication tokens, etc.)
        if (store.getters["authlipd/getToken"]) {
            let token = 'Token '+store.getters["authlipd/getToken"];
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g. parse, transform, etc.)
        return response;
    },
    (error) => {
        // Handle response error here
        alert('Error: '+error.response.data.detail)
        return Promise.reject(error);
    }
);

export default axiosInstance;

