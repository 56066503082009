<template>
  <v-container>

    <!-- FILTERS PANEL -->
    <DataLibraryFilters></DataLibraryFilters>

    <v-row align-content="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="10" class="text-center">
        {{ `${getDatasets.length} datasets corresponding to filters.` }}
      </v-col>
    </v-row>

    <v-row align-content="center" justify="center">
      <v-col cols="12" justify="center" xs="12" sm="12" md="12" lg="12" xl="12">
        <DataLibraryMap :dialog.sync="dialog" :feature.sync="activeDataset" :selected.sync="selected"
          :showonlyselected.sync="showonlyselected"></DataLibraryMap>
      </v-col></v-row>

    <!-- DATA TABLE -->
    <v-row align-content="center" justify="center">
      <v-col cols="12" justify="center" xs="12" sm="12" md="12" lg="12" xl="10">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search datasets..." single-line
              hide-details></v-text-field>
            <v-spacer></v-spacer>
            <!-- <v-btn v-if="selected.length > 0" color="success"
              >Show only selected</v-btn
            > -->
            <v-btn @click="showonlyselected = !showonlyselected" text class="ma-2"
              :color="showonlyselected ? '' : 'success'">
              <v-icon left> mdi-eye </v-icon>{{
                showonlyselected
                  ? `Show All (${getDatasets.length})`
                  : `Show only selected (${selected.length})`
              }}</v-btn>
            <v-btn :disabled="selected.length === 0" text @click="clearSelection" class="ma-2" color="error"><v-icon
                left>
                mdi-close-box-outline </v-icon>Clear
              Selection</v-btn>
            <v-btn :disabled="selected.length === 0" text class="ma-2"><v-icon left>
                mdi-download </v-icon>{{ `Download selected (${selected.length})` }}</v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table dense v-model="selected" show-select item-key="id_dataset" :headers="headers" :search="search"
              :items="showonlyselected ? selected : getDatasets" :sort-by="['name', 'geo.siteName']"
              :items-per-page="15">
              <template v-slot:item.name="{ item }">
                <span>{{
                  item.name
                    ? item.name.length > 30
                      ? item.name.slice(0, 27) + "..."
                      : item.name
                    : ""
                }}</span>
              </template>
              <template v-slot:item.archive="{ item }">
                <v-chip dense small>
                  {{
                    item.archive
                      ? item.archive.length > 30
                        ? item.archive.slice(0, 27) + "..."
                        : item.archive
                      : ""
                  }}
                </v-chip>
              </template>

              <template v-slot:item.avg_lat="{ item }">
                <span>{{
                  item.avg_lat
                    ? parseFloat(parseFloat(item.avg_lat).toFixed(5))
                    : ""
                }}</span>
              </template>
              <template v-slot:item.avg_lon="{ item }">
                <span>{{
                  item.avg_lon
                    ? parseFloat(parseFloat(item.avg_lon).toFixed(5))
                    : ""
                }}</span>
              </template>
              <template v-slot:item.altitude="{ item }">
                <span>{{
                  item.altitude
                    ? parseFloat(parseFloat(item.altitude).toFixed(1))
                    : ""
                }}</span>
              </template>
              <template v-slot:item.project="{ item }">
                <v-chip dense small :color="'#424242'" dark>{{
                  item.project
                  }}</v-chip>
              </template>
              <template v-slot:item.has_data="{ item }">
                <v-chip small :color="item.has_data ? 'success' : 'error'" dark>{{ item.has_data ? 'Yes' : 'No' }}</v-chip>
              </template>
              <template v-slot:item.has_chron="{ item }">
                <v-chip small :color="item.has_chron ? 'success' : 'error'" dark>{{ item.has_chron ? 'Yes' : 'No' }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn :href="getLipdURL(item.dataset_id)" icon download small>
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>


                <v-btn small icon :to="{
                  name: 'DatasetDetail',
                  params: { id: item.dataset_id },
                }">
                  <v-icon> mdi-magnify</v-icon>

                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataLibraryMap from "./DataLibraryMap.vue";
import DataLibraryFilters from "./DataLibraryFilters.vue";
import urlJoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_PALEODATAIPSL_API;

export default {
  components: {
    DataLibraryMap,
    DataLibraryFilters
  },
  name: "DataLibrary",
  data: () => ({
    search: "",
    activeDataset: null,
    dialog: false,
    selected: [],
    showonlyselected: false,
    /* headers: [

      ì
      {
        text: "Title",
        align: "start",
        value: "name",
      },
      {
        text: "Site Name",
        align: "start",
        value: "site_name",
      },
      {
        text: "Archive",
        align: "start",
        value: "archive",
      },
      {
        text: "Author(s)",
        align: "start",
        value: "investigators",
      },
      {
        text: "Country / Ocean",
        value: "region",
      },
      {
        text: "Latitude (°N)",
        value: "avg_lat",
      },
      {
        text: "Longitude  (°E)",
        value: "avg_lon",
      },
      {
        text: "Has data",
        value: "has_data",
      },
      {
        text: "Has chron",
        value: "has_chron",
      },



      { text: "Actions", value: "actions", align: "right", sortable: false },
    ], */
  }),
  methods: {
    ...mapActions("publicdatalibrary", ["fetchDatasets", "fetchArchives", "fetchRegions", "fetchProjects", "setAllowedProjects"]),
    openDialog(item) {
      this.$router.push({ name: 'DatasetDetail', params: { id: item.uuid, id_dataset: item.id_dataset } });

    },
    getLipdURL(dataset_id) {
      let downloadURL = urlJoin(
        HOST_ROOT,
        "datasets",
        dataset_id.toString(),
        "lipd/"
      );
      return downloadURL;
    },
    getProjectColor(project) {
      if (project == "Climate Proxies Finder") {
        return "#1976D2";
      } else if (project == "APD-fossile") {
        return "#2E7D32";
      } else if (project == "APD-modern") {
        return "#43A047"
      }
    },
    closeDialog() {
      this.activeDataset = null;
      this.dialog = false;
    },

    clearSelection() {
      this.selected = [];
      this.showonlyselected = false;
    },
    onMounted() {
      if (this.getModernFossil === "modern") {
        this.setAllowedProjects(["APDMODERNE"])
      } else if (this.getModernFossil === "fossil") {
        this.setAllowedProjects(["APDFOSSILE"])
      }
      this.fetchDatasets();
      this.fetchArchives();
      this.fetchRegions();
      this.fetchProjects();
    },


  },
  computed: {
    ...mapGetters("publicdatalibrary", ["getDatasets"]),
    getModernFossil() {
      return this.$route.params.modernfossil
    },
    headers() {

      let headers = [

        /* {
          text: "UUID",
          align: "start",
          value: "dataset_id",
        }, */
        {
          text: "Title",
          align: "start",
          value: "name",
        },
        {
          text: "Site Name",
          align: "start",
          value: "site_name",
        },
        {
          text: "Archive",
          align: "start",
          value: "archive",
        },
        {
          text: "Author(s)",
          align: "start",
          value: "investigators",
        },
        {
          text: "Country / Ocean",
          value: "region",
        },
        {
          text: "Latitude (°N)",
          value: "avg_lat",
        },
        {
          text: "Longitude  (°E)",
          value: "avg_lon",
        },
        /* {
          text: "Altitude (m)",
          value: "avg_alt",
        }, */
        
      ]

      if (this.getModernFossil === "fossil") {
        headers.push({
          text: "Has data",
          value: "has_data",
        })
        headers.push({
          text: "Has chron",
          value: "has_chron",
        })
      }

      headers.push({
        text: "Actions",
        value: "actions",
        align: "right",
        sortable: false
      })
      return headers;
    }
  },
  watch: {
    $route() {
      this.onMounted();
    }
  },
  mounted() {
    this.onMounted();
  },
};
</script>

<style></style>