<template>
  <v-container>
    <v-row
      ><v-col justify="center" md="12" lg="12">
        <v-card color="rgb(255, 0, 0, 0)">
          <v-card-title class="text-h5" style="word-break: keep-all"
            ><v-avatar dark class="white--text">
              <v-icon color="#2B2118">mdi-database-clock</v-icon>
            </v-avatar>
            {{ getModernOrFossil === "modern" ? "Modern Data Library" : "Fossil Data Library" }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <keep-alive include="DataLibrary">
      <router-view :key="$route.fullPath"> </router-view>
    </keep-alive>
  </v-container>
</template>

<script>

export default {
  computed: {
    getModernOrFossil() {
      return this.$route.query.modernfossil;
    },
  },
};
</script>

<style>
</style>