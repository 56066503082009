import axios from "axios";
import urljoin from "url-join";

const HOST_ROOT = process.env.VUE_APP_ROOT_API;

function setDefaultFilters() {
  return {
    projects: [],
    sites: [],
    archives: [],
    proxies: [],
    longitude: [-180, 180],
    latitude: [-90, 90],
    age: [0, 32720],
    tempRes: [0, 23],
    depth: [-10000, 10000],
  };
}

function setDefaultGeneralInfo() {
  return {
    "@context": "jsonld",
    archiveType: "Other",
    dataSetName: null,
    lipdVersion: "1.3",
    createdBy: null,
    investigators: null,
    samplingDevice: { device: null, coreDiameterCm: null },
    notes: null,
  };
}

function setDefaultGeo() {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [0, 0, 0],
    },
    siteName: null,
    properties: {
      entloc: null,
      localveg: null,
      location: null,
      surroundveg: null,
      physiography: null,
      sitedescript: null,
      vegformation: null,
    },
  };
}

function arrayToCsv(filename, rows) {
  var processRow = function(row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvdata = "";

  /* let hasCols = true;
  for (const r of rows) {
    if (!Array.isArray(r)) {
      hasCols = false;
      break;
    }
  }

  if (hasCols) {
    for (var i = 0; i < rows.length; i++) {
      csvdata += processRow(rows[i]);
    }
  } else {
    csvdata = processRow(rows);
  }
 */
  for (var i = 0; i < rows.length; i++) {
    csvdata += processRow(rows[i]);
  }

  var csvfile = new File([csvdata], filename, {
    type: "text/csv",
  });

  return csvfile;
}

const state = {
  editDialog: false,
  paleodata: [],
  datasets: [],
  filters: setDefaultFilters(),
  filtered: [],
  originalItem: {
    paleofiles: [],
    chronfiles: [],
  },
  isEdit: null,
  editedItem: {
    ...setDefaultGeneralInfo(),
    geo: setDefaultGeo(),
    pub: [],
    funding: [],
    paleoData: [],
    chronData: [],
  },
};

const getters = {
  getDatasets: (state) => state.datasets,
  getFilters: (state) => state.filters,
  getFiltered: (state) => state.filtered,
  getIsEdit: (state) => state.isEdit,
  getEditDialog: (state) => state.editDialog,
  getEditCompleteJson: (state) => {
    return {
      ...state.editedItem,
      geo: state.editedItem.geo,
      pub: state.editedItem.pub,
      funding: state.editedItem.funding,
      paleoData: state.editedItem.paleoData,
      chronData: state.editedItem.chronData,
    };
  },
  getEditJsonNodata: (state) => {
    // eslint-disable-next-line no-unused-vars
    const { is_public, ..._state } = state.editedItem;
    return {
      ..._state,
      pub: _state.pub,
      geo: _state.geo,
      funding: _state.funding,
      paleoData: _state.paleoData.map((x) => {
        return {
          ...x,
          measurementTable: x.measurementTable.map((t) => {
            // eslint-disable-next-line no-unused-vars
            const { data, ...rest } = t;

            return rest;
          }),
        };
      }),
      chronData: _state.chronData.map((x) => {
        return {
          ...x,
          measurementTable: x.measurementTable.map((t) => {
            // eslint-disable-next-line no-unused-vars
            const { data, ...rest } = t;
            return rest;
          }),
        };
      }),
    };
  },

  /* get paleo files to edit, add or delete after editing */
  getEditPaleoDataFlat: (state) =>
    state.editedItem.paleoData.map((p) => p.measurementTable).flat(),
  getPaleoFilesToDelete: (state, getters) =>
    state.originalItem.paleofiles.filter(
      (f) =>
        !getters.getEditPaleoDataFlat
          .map((p) => p.tableName)
          .includes(f.paleodata_title)
    ),
  getPaleoFilesToEdit: (state, getters) =>
    state.originalItem.paleofiles.filter((f) =>
      getters.getEditPaleoDataFlat
        .map((p) => p.tableName)
        .includes(f.paleodata_title)
    ),
  getNewPaleoFiles: (state, getters) =>
    getters.getEditPaleoDataFlat.filter(
      (p) =>
        !state.originalItem.paleofiles
          .map((f) => f.paleodata_title)
          .includes(p.tableName)
    ),

  /* get chrono files to edit, add or delete after editing */
  /* getEditChronoDataFlat: (state) =>
    state.editedItem.chronData.map((p) => p.measurementTable).flat(),
  getChronoFilesToDelete: (state, getters) =>
    state.originalItem.chronfiles.filter(
      (f) =>
        !getters.getEditChronoDataFlat
          .map((p) => p.tableName)
          .includes(f.chronodata_title)
    ),
  getChronoFilesToEdit: (state, getters) =>
    state.originalItem.chronfiles.filter((f) =>
      getters.getEditChronoDataFlat
        .map((p) => p.tableName)
        .includes(f.chronodata_title)
    ),
  getNewChronoFiles: (state, getters) =>
    getters.getEditChronoDataFlat.filter(
      (p) =>
        !state.originalItem.chronfiles
          .map((f) => f.chronodata_title)
          .includes(p.tableName)
    ), */

  getEditGeneralInfo: (state) => state.editedItem,
  getEditGeo: (state) => state.editedItem.geo,
};

const actions = {
  async fetchDatasets({ commit, rootState }) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.get(
        urljoin(HOST_ROOT, "api/modern-generalinfo/"),
        {
          headers: headers,
        }
      );

      commit("setDatasets", response.data);
    } catch (error) {
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
    }
  },

  async loadDataset({ commit, rootState }, dataset_id) {
    commit(
      "auth/setLoadingMessage",
      "Loading dataset information. Please wait...",
      { root: true }
    );
    commit("auth/setIsLoading", true, { root: true });

    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };
      const response = await axios.get(
        urljoin(HOST_ROOT, "api/modern-dataset-complete", dataset_id.toString()),
        {
          headers: headers,
        }
      );

      /* Adding the csv data as a variable in the json_metadata, in order to show it in the editor */
      const dict = {
        ...response.data.json_metadata,
        id_dataset: response.data.id_dataset,
        is_public: response.data.is_public,
        paleoData: response.data.json_metadata.paleoData.map((m) => {
          return {
            ...m,
            measurementTable: m.measurementTable.map((t) => {
              var paleofile = response.data.paleo_files.find(
                (f) => f.paleodata_title === t.tableName
              );
              if (paleofile === undefined) {
                throw "Couldn't find csv file: " + t.tableName;
              }
              return {
                ...t,
                data: paleofile.csv_data,
                filetype: paleofile.filetype,
              };
            }),
          };
        }),
        chronData: [],
        /* chronData: response.data.json_metadata.chronData.map((m) => {
          return {
            ...m,
            measurementTable: m.measurementTable.map((t) => {
              let chronofile = response.data.chrono_files.find(
                (f) => f.chronodata_title === t.tableName
              );
              if (chronofile === undefined) {
                throw "Couldn't find csv file: " + t.tableName;
              }
              return {
                ...t,
                data: chronofile.csv_data,
                filetype: chronofile.filetype,
              };
            }),
          };
        }), */
      };

      commit("setOriginalChronFiles", response.data.chrono_files);
      commit("setOriginalPaleoFiles", response.data.paleo_files);
      commit("setEditedItem", dict);
      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
      return true;
    } catch (error) {
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
      return false;
    }
  },

  async editDataset({ commit, rootState, getters }, id_dataset) {
    /* commit("auth/setLoadingMessage", "Saving modifications. Please wait...", {
      root: true,
    });
    commit("auth/setIsLoading", true, { root: true }); */

    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };

      /* Deleting paleodata files */
      for (const todelpaleo of getters.getPaleoFilesToDelete) {
        await axios.delete(
          urljoin(
            HOST_ROOT,
            "api/modern-paleodata",
            todelpaleo.id_paleodata.toString()
          ),
          {
            headers: headers,
          }
        );
      }
      /* Updating paleodata files */
      for (const toeditfile of getters.getPaleoFilesToEdit) {
        let editedfile = getters.getEditPaleoDataFlat.find(
          (p) => p.tableName === toeditfile.paleodata_title
        );

        var csvfile = arrayToCsv(editedfile.filename, editedfile.data);

        let formData = new FormData();
        formData.append("filetype", editedfile.filetype);
        formData.append("paleodata_title", editedfile.tableName);
        formData.append("paleodata_file", csvfile);
        await axios.put(
          urljoin(
            HOST_ROOT,
            "api/modern-paleodata",
            toeditfile.id_paleodata.toString() + "/"
          ),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      }
      /* Uploading newly created paleodata files */
      for (const tocreatefile of getters.getNewPaleoFiles) {
        let csvfile = arrayToCsv(tocreatefile.filename, tocreatefile.data);
        let formData = new FormData();
        formData.append("id_dataset", id_dataset);
        formData.append("filetype", tocreatefile.filetype);
        formData.append("paleodata_title", tocreatefile.tableName);
        formData.append("paleodata_file", csvfile);
        await axios.post(
          urljoin(HOST_ROOT, "api/modern-paleodata/"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      }

      /* Deleting chrono files */
      /* for (const todelchrono of getters.getChronoFilesToDelete) {
        await axios.delete(
          urljoin(
            HOST_ROOT,
            "api/fossil-chronodata",
            todelchrono.id_chronodata.toString()
          ),
          {
            headers: headers,
          }
        );
      } */
      /* Updating chronodata files */
      /* for (const toeditfile of getters.getChronoFilesToEdit) {
        let editedfile = getters.getEditChronoDataFlat.find(
          (p) => p.tableName === toeditfile.chronodata_title
        );

        let formData = new FormData();
        formData.append("filetype", editedfile.filetype);
        formData.append("chronodata_title", editedfile.tableName);
        formData.append(
          "chronodata_file",
          arrayToCsv(editedfile.filename, editedfile.data)
        );
        await axios.put(
          urljoin(
            HOST_ROOT,
            "api/fossil-chronodata",
            toeditfile.id_chronodata.toString() + "/"
          ),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      } */
      /* Uploading newly created chronodata files */
      /* for (const tocreatefile of getters.getNewChronoFiles) {
        let formData = new FormData();
        formData.append("id_dataset", id_dataset);
        formData.append("filetype", tocreatefile.filetype);
        formData.append("chronodata_title", tocreatefile.tableName);
        formData.append(
          "chronodata_file",
          arrayToCsv(tocreatefile.filename, tocreatefile.data)
        );
        await axios.post(
          urljoin(HOST_ROOT, "api/fossil-chronodata/"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      } */

      /* Updating  */
      await axios.put(
        urljoin(HOST_ROOT, "api/modern-dataset", id_dataset.toString() + "/"),
        {
          dataset_title: getters.getEditJsonNodata.dataSetName,
          is_apd: true,
          is_public: getters.getEditCompleteJson.is_public,
          json_metadata: getters.getEditJsonNodata,
        },
        {
          headers: headers,
        }
      );
      /* commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true }); */
    } catch (error) {
      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
    }
  },

  async deleteDataset({ commit, rootState }, id_dataset) {
    commit(
      "auth/setLoadingMessage",
      "Deleting dataset and its files. Please wait...",
      {
        root: true,
      }
    );
    commit("auth/setIsLoading", true, { root: true });

    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };

      /* Adding dataset (metadata)  */
      await axios.delete(
        urljoin(HOST_ROOT, "api/modern-dataset", id_dataset.toString() + "/"),
        {
          headers: headers,
        }
      );

      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
    } catch (error) {
      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
    }
  },

  async addNewDataset({ commit, rootState, getters }) {
    try {
      const headers = {
        Authorization: "Token " + rootState.auth.user.token,
      };

      /* Adding dataset (metadata)  */
      const response = await axios.post(
        urljoin(HOST_ROOT, "api/modern-dataset/"),
        {
          dataset_title: getters.getEditJsonNodata.dataSetName,
          is_apd: false,
          is_public: getters.getEditCompleteJson.is_public,
          json_metadata: getters.getEditJsonNodata,
        },
        {
          headers: headers,
        }
      );

      let id_dataset = response.data.id_dataset;

      /* Uploading newly created paleodata files */
      for (const tocreatefile of getters.getEditPaleoDataFlat) {
        let csvfile = arrayToCsv(tocreatefile.filename, tocreatefile.data);
        let formData = new FormData();
        formData.append("id_dataset", id_dataset);
        formData.append("filetype", tocreatefile.filetype);
        formData.append("paleodata_title", tocreatefile.tableName);
        formData.append("paleodata_file", csvfile);
        await axios.post(
          urljoin(HOST_ROOT, "api/modern-paleodata/"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      }

      /* Uploading newly created chronodata files */
      /* for (const tocreatefile of getters.getEditChronoDataFlat) {
        let formData = new FormData();
        formData.append("id_dataset", id_dataset);
        formData.append("filetype", tocreatefile.filetype);
        formData.append("chronodata_title", tocreatefile.tableName);
        formData.append(
          "chronodata_file",
          arrayToCsv(tocreatefile.filename, tocreatefile.data)
        );
        await axios.post(
          urljoin(HOST_ROOT, "api/fossil-chronodata/"),
          formData,
          {
            headers: { ...headers, "Content-Type": "multipart/form-data" },
          }
        );
      } */
    } catch (error) {
      commit("auth/setIsLoading", false, { root: true });
      commit("auth/setLoadingMessage", "", { root: true });
      commit("auth/setIsApiError", true, { root: true });
      commit("auth/setApiErrorMessage", error, { root: true });
    }
  },

  async loadDefaultDataset({ commit }) {
    commit(
      "auth/setLoadingMessage",
      "Loading default dataset information. Please wait...",
      { root: true }
    );
    commit("auth/setIsLoading", true, { root: true });
    await commit("setEditedItem", {
      ...setDefaultGeneralInfo(),
      is_public: true,
      id_dataset: null,
      geo: setDefaultGeo(),
      pub: [],
      funding: [],
      paleoData: [],
      chronData: [],
    });
    commit("auth/setIsLoading", false, { root: true });
    commit("auth/setLoadingMessage", "", { root: true });
  },

  /* reset the state of the editedItem*/
  resetEditState({ commit }) {
    commit("resetEditGeneralInfo");
    commit("resetEditGeo");
    commit("resetEditPub");
    commit("resetEditFunding");
    commit("resetEditPaleoData");
    commit("resetEditChronData");
  },

  setIsEdit({ commit }, isEdit) {
    commit("setIsEdit", isEdit);
  },

  setEditedItem({ commit }, jsondata) {
    commit("setEditedItem", jsondata);
  },

  applyFilters({ commit }) {
    commit("applyFilters");
  },

  resetFilters({ commit }) {
    commit("resetFilters");
    commit("applyFilters");
  },

  setEditDialog({ commit }, editDialog) {
    commit("setEditDialog", editDialog);
  },
};

const mutations = {
  setEditDialog: (state, editDialog) => (state.editDialog = editDialog),
  /* SET mutations */
  setDatasets: (state, datasets) => (state.datasets = datasets),

  setEditedItem: (state, editedItem) => (state.editedItem = editedItem),

  setEditGeneralInfo: (state, generalInfo) => (state.editedItem = generalInfo),
  setEditGeo: (state, geo) => (state.editedItem.geo = geo),
  setIsEdit: (state, isEdit) => (state.isEdit = isEdit),
  resetGeneralInfo: (state) =>
    (state.editedItem.generalInfo = setDefaultGeneralInfo()),
  resetGeo: (state) => (state.editedItem.geo = setDefaultGeo()),
  resetPub: (state) => (state.editedItem.pub = []),
  resetFunding: (state) => (state.editedItem.funding = []),
  resetPaleoData: (state) => (state.editedItem.paleoData = []),
  resetChronData: (state) => (state.editedItem.chronData = []),

  setOriginalPaleoFiles: (state, paleofiles) =>
    (state.originalItem.paleofiles = paleofiles),
  setOriginalChronFiles: (state, chronfiles) =>
    (state.originalItem.chronfiles = chronfiles),

  /* FILTERS mutations */
  updateFilters: (state, filters) => (state.filters = filters),
  resetFilters: (state) => (state.filters = setDefaultFilters()),
  applyFilters: (state) =>
    (state.filtered = state.paleodata.filter(
      (r) =>
        r.longitude <= state.filters.longitude[1] &&
        r.longitude >= state.filters.longitude[0] &&
        r.latitude <= state.filters.latitude[1] &&
        r.latitude >= state.filters.latitude[0] &&
        r.depth <= state.filters.depth[1] &&
        r.depth >= state.filters.depth[0] &&
        /*         r.ResolutionTemporell <= state.filters.tempRes[1] &&
        r.ResolutionTemporell >= state.filters.tempRes[0] && */
        (state.filters.archives.length == 0
          ? true
          : state.filters.archives.includes(
              r.archive.charAt(0).toUpperCase() + r.archive.slice(1)
            )
          ? true
          : false) &&
        (state.filters.proxies.length == 0
          ? true
          : state.filters.proxies.includes(
              r.proxy.charAt(0).toUpperCase() + r.proxy.slice(1)
            )
          ? true
          : false) &&
        (state.filters.projects.length == 0
          ? true
          : state.filters.projects.includes(r.pub["Project(s)"])
          ? true
          : false) &&
        (state.filters.sites.length == 0
          ? true
          : state.filters.sites.includes(r.site_name)
          ? true
          : false)
    )),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
