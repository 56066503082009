<template>
  <div>
    <v-card flat>

      <v-card-text>
        <!-- <v-row>
          <v-col cols="12" sm="12">
            <p>
              Several tools are available to facilitate the search and visualization of paleoclimatic data that may
              interest you:
            </p>
            <p>
              • Downcore plots of paleoclimate records from one or several selected sites
            </p>
            <p>
              • Surface mapping of specific variables for a selected geographical area
            </p>
            <p>
              • Depth sections of specific variables across the marine realm
            </p>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col sm="4" cols="12" v-for="(panel, index) in panelData" v-bind:key="index">
            <!-- <VizToolPanel :key="index" :imageSrc="panel.imageSrc" :routeName="panel.routeName"
          :description="panel.title" :disabled="panel.disabled" />
        <h4 class="mt-2 text-center" v-if="!panel.disabled">{{ panel.title }}</h4> -->
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card class="mx-auto" max-width="344">
                  <v-img :src="require(`@/assets/${panel.imageSrc}`)" class="panel-image"></v-img>

                  <v-card-text>
                    <h2 class="text-h6 primary--text">
                      {{ panel.title }}
                    </h2>
                    {{ panel.description }}
                  </v-card-text>


                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#036358">
                      <v-btn :to="{ name: panel.routeName }">{{ panel.title }}</v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </div>
</template>

<script>
/* import VizToolPanel from './VizToolPanel.vue'; */

export default {
  /* components: {
    VizToolPanel,
  }, */
  data() {
    return {
      panelData: [

        {
          imageSrc: 'fossil-pollen-percentage.png',
          routeName: 'FossilPollenPercentage',
          title: 'Fossil pollen percentage',
          description: 'This tool allows you to visualize the percentage of specific fossil pollen groups with respect to a defined pollen sum.',
          disabled: false,
        },

      ],
    };
  },
  methods: {
    /* getImage(image) {
      return require(`./../../../public/${image}`)
    } */
  },
};
</script>

<style scoped>
.panel-image {
  width: 100%;
  /* Ensure the image takes the full width */
  height: 200px;
  /* Ensure the image takes the full height */


  top: 0;
  /* Position at the top of the panel */
  left: 0;
  /* Position at the left of the panel */
}
</style>