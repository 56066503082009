<template>
  <v-container>
    <v-row
      class="text-center"
      align-content="center"
      align="center"
      justify="center"
      ><v-col justify="center" class="text-center" lg="6">
        <v-sheet elevation="2" color="rgb(255, 0, 0, 0)"
          ><v-breadcrumbs
            :items="breadcrumbs"
            large
          ></v-breadcrumbs
        ></v-sheet> </v-col
    ></v-row>
    <router-view :key="$route.path"> </router-view>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: { name: "WelcomePage" },
        },
        {
          text: "About",
          disabled: false,
          to: { name: "HomeAbout" },
        },
        {
          text: "APD Council",
          disabled: false,
          to: { name: "APDCouncil" },
        },
        {
          text: "Protocols",
          disabled: false,
          to: { name: "Protocols" },
        },
        {
          text: "How to contribute and query",
          disabled: false,
          to: { name: "HowTo" },
        },
        {
          text: "Archives",
          disabled: false,
          to: { name: "Archives" },
        },
        {
          text: "Links",
          disabled: true,
          to: { name: "HomeAbout" },
        },
      ],
    };
  },
};
</script>

<style>
</style>