import services from "../../../../services";



/* const allowedProjects=['APDMODERNE,APDFOSSILE'] */

function setDefaultFilters() {
    return {
        archives: [],
        regions: [],
        projects: [],
        longitude: [-180, 180],
        latitude: [-90, 90],
        altitude: [-7000, 7000]
    }
}

const state = {
    datasets: [],
    archives: [],
    regions: [],
    projects: [],
    filters: setDefaultFilters(),
};

const getters = {
    getDatasets: state => state.datasets,
    getArchives: state => state.archives,
    getRegions: state => state.regions,
    getProjects: state => state.projects,
    getFilters: state => state.filters,
};

const actions = {
    async fetchDatasets({ commit }) {
        try {
            let options = {
                archive_ids: state.filters.archives,
                region_ids: state.filters.regions,
                project_ids: state.projects.map(project => project.project_id),
                min_lon: state.filters.longitude[0],
                max_lon: state.filters.longitude[1],
                min_lat: state.filters.latitude[0],
                max_lat: state.filters.latitude[1],
                min_alt: state.filters.altitude[0],
                max_alt: state.filters.altitude[1],
            };
            const response = await services.dataset.getDatasetsGeneralInfo(options);
            commit('setDatasets', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchProjects({ commit }, project_id) {
        try {
            const response = await services.project.getProjects();
            commit('setProjects', response.data.filter(project => project.project_id === project_id));
        } catch (error) {
            console.error(error);
        }
    },
    async fetchArchives({ commit }) {
        try {
            let project_ids = state.projects.map(project => project.project_id);
            const response = await services.archive.getArchives(project_ids);
            commit('setArchives', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchRegions({ commit }) {
        try {
            let options = { project_ids: state.projects.map(project => project.project_id) };
            const response = await services.region.getRegions(options);
            commit('setRegions', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    resetFilters({ commit }) {
        commit('resetFilters');
    },
    // eslint-disable-next-line
    deleteDataset({ }, dataset_id) {
        try {
            services.dataset.deleteDataset(dataset_id);
            
        } catch (error) {
            console.error(error);
        }
    }
};


const mutations = {
    setDatasets: (state, datasets) => state.datasets = datasets,
    setArchives: (state, archives) => state.archives = archives,
    setRegions: (state, regions) => state.regions = regions,
    setProjects: (state, projects) => state.projects = projects,

        
    resetFilters: (state) => state.filters = setDefaultFilters(),

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
