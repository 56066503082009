<template>
  <v-container>
    <!-- HEADER -->
    <v-row
      ><v-col justify="center" md="12" lg="12">
        <v-card color="rgb(255, 0, 0, 0)">
          <v-card-title class="text-h5" style="word-break: keep-all"
            ><v-avatar dark class="white--text">
              <v-icon color="#2B2118">mdi-order-alphabetical-ascending</v-icon>
            </v-avatar>
            APD Taxonomy List<v-btn
              v-if="!info"
              class="ml-3"
              text
              rounded
              @click="info = !info"
              ><v-icon left>mdi-information-outline</v-icon> View Info</v-btn
            >
            <v-btn class="ml-3" v-if="info" text rounded @click="info = !info"
              ><v-icon left>mdi-information-outline</v-icon> Hide Info</v-btn
            >
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="info" align-content="center" justify="center"
      ><v-col
        cols="12"
        justify="center"
        md="12"
        lg="12"
        xl="7"
        class="text-center"
      >
        <p>
          The APD taxonomy list is elaborated to allow easy extraction of data
          at any taxonomic level: species, genus or family, pollen types etc. It
          includes all taxa determined in the modern and fossil pollen samples
          from sub-Saharan Africa.
        </p>
        <p>
          APD's principle is to (1) not modify the nomenclature used by the
          authors even in case of spelling mistakes (2) propose a revised name
          based on the up-to-date nomenclature of "<a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.ville-ge.ch/musinfo/bd/cjb/africa/recherche.php?langue=fr"
            >Base de données des plantes d’Afrique</a
          >” published by Conservatoire et Jardins botaniques de la Ville de
          Genève based on the work of Lebrun and Stork after identification of
          misspellings, synonyms and other potential errors.
        </p>
        <p>
          A type is added to the genus or to the species when several genera (in
          the same family) or several species (in the same genus) present a same
          pollen morphology (Vincens A., Lézine A.-M., Buchet G., Lewden D., Le
          Thomas A. 2007. African pollen database inventory of tree and shrub
          pollen types. Review of Palaeobotany and Palynology 145(1-2),135-41)
        </p>
        <p>
          The original nomenclature of the authors can be found in column “Taxon
          (original name)”; the APD nomenclature (revised in 2020) in column
          “Taxon (revised nomenclature)”. Pollen and Plant habit are also
          indicated.
        </p>
        <p>
          The APD taxonomy list was initiated by A. Vincens. It is maintained by
          A.-M. Lézine. Pollen types are determined after study of the reference
          slide collections of the herbarium of the National Museum of Natural
          History in Paris, the pollen slide collections of LOCEAN, CEREGE and
          ISEM and specialized literature.
        </p>
        <p>
          This list should be regularly updated and the whole xls file can be
          downloaded.
        </p>
        <p>
          The APD taxonomy list can be downloaded here or can be quiered by
          revised or unrevised taxa, or by categories (pollen habit).
        </p>
      </v-col></v-row
    >


    <!-- FILTERS -->
    <v-row align-content="center" justify="center">
      <v-col
        cols="12"
        justify="center"
        md="12"
        lg="12"
        xl="9"
        class="text-center"
      >
        <v-menu
          bottom
          right
          nudge-bottom="10"
          transition="scale-transition"
          offset-y
          rounded="lg"
          origin="center center"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="#2B2118"
              :dark="getTaxonsFilters.families.length > 0"
              :outlined="getTaxonsFilters.families.length == 0"
              rounded
              class="ma-2"
              v-on="on"
            >
              <!-- <v-avatar left>
                <v-icon>mdi-book-open-page-variant</v-icon> </v-avatar
              > -->Families
              {{
                getTaxonsFilters.families.length > 0
                  ? " (" + getTaxonsFilters.families.length + ")"
                  : " (" + getTaxonFamilies.length + ")"
              }}
            </v-btn>
          </template>
          <v-card width="400" v-on:click.stop>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-autocomplete
                    :items="getTaxonFamilies"
                    v-model="getTaxonsFilters.families"
                    chips
                    background-color="grey lighten-4"
                    outlined
                    deletable-chips
                    label="Family"
                    item-text="family_name"
                    item-value="id_family"
                    multiple
                    @input="setFilterFamilies"
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          right
          nudge-bottom="10"
          transition="scale-transition"
          offset-y
          rounded="lg"
          origin="center center"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="#2B2118"
              :dark="getTaxonsFilters.taxons.length > 0"
              :outlined="getTaxonsFilters.taxons.length == 0"
              rounded
              class="ma-2"
              v-on="on"
            >
              <!-- <v-avatar left>
                <v-icon>mdi-book-open-page-variant</v-icon> </v-avatar
              > -->Taxons (revised)
              {{
                getTaxonsFilters.taxons.length > 0
                  ? " (" + getTaxonsFilters.taxons.length + ")"
                  : " (" + getTaxons.length + ")"
              }}
            </v-btn>
          </template>
          <v-card width="400" v-on:click.stop>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-autocomplete
                    :items="
                      getTaxonsFilters.families.length === 0
                        ? getTaxons
                        : getTaxons.filter((e) =>
                            getTaxonsFilters.families.includes(
                              e.id_family.id_family
                            )
                          )
                    "
                    v-model="getTaxonsFilters.taxons"
                    chips
                    outlined
                    background-color="grey lighten-4"
                    deletable-chips
                    label="Taxon (revised nomenclature)"
                    item-text="taxon_name"
                    item-value="id_taxon"
                    multiple
                    @input="setFilterTaxons"
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          right
          nudge-bottom="10"
          transition="scale-transition"
          offset-y
          rounded="lg"
          origin="center center"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="#2B2118"
              :dark="getTaxonsFilters.authortaxons.length > 0"
              :outlined="getTaxonsFilters.authortaxons.length == 0"
              rounded
              class="ma-2"
              v-on="on"
            >
              <!-- <v-avatar left>
                <v-icon>mdi-book-open-page-variant</v-icon> </v-avatar
              > -->Taxons (original)
              {{
                getTaxonsFilters.authortaxons.length > 0
                  ? " (" + getTaxonsFilters.authortaxons.length + ")"
                  : " (" + getAuthorTaxons.length + ")"
              }}
            </v-btn>
          </template>
          <v-card width="400" v-on:click.stop>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-autocomplete
                    v-model="getTaxonsFilters.authortaxons"
                    :items="
                      getTaxonsFilters.taxons.length === 0
                        ? getAuthorTaxons
                        : getAuthorTaxons.filter((e) =>
                            getTaxonsFilters.taxons.includes(
                              e.id_taxon.id_taxon
                            )
                          )
                    "
                    chips
                    outlined
                    background-color="grey lighten-4"
                    deletable-chips
                    label="Taxon (original name)"
                    item-text="authortaxon_name"
                    item-value="id_authortaxon"
                    @input="setFilterAuthorTaxons"
                    multiple
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          right
          nudge-bottom="10"
          transition="scale-transition"
          offset-y
          rounded="lg"
          origin="center center"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              color="#2B2118"
              :dark="getTaxonsFilters.pollen_habit.length > 0"
              :outlined="getTaxonsFilters.pollen_habit.length == 0"
              rounded
              class="ma-2"
              v-on="on"
            >
              <!-- <v-avatar left>
                <v-icon>mdi-book-open-page-variant</v-icon> </v-avatar
              > -->Pollen Habit
              {{
                getTaxonsFilters.pollen_habit.length > 0
                  ? " (" + getTaxonsFilters.pollen_habit.length + ")"
                  : " (" + getPollenHabits.length + ")"
              }}
            </v-btn>
          </template>
          <v-card width="400" v-on:click.stop>
            <v-card-text>
              <v-col>
                <v-row>
                  <v-autocomplete
                    :items="getPollenHabits"
                    v-model="getTaxonsFilters.pollen_habit"
                    chips
                    outlined
                    deletable-chips
                    background-color="grey lighten-4"
                    label="Pollen Habit"
                    item-text="pollen_habit_code"
                    item-value="id_pollen_habit"
                    multiple
                    @input="setFilterPollenHabit"
                  ></v-autocomplete>
                </v-row>
              </v-col>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-btn
          rounded
          color="error"
          small
          dark
          class="ma-2"
          @click="resetFilters"
        >
          <v-avatar left> <v-icon>mdi-undo-variant</v-icon> </v-avatar>Reset
          filters
        </v-btn>
      </v-col>
    </v-row>

    <v-row align-content="center" justify="center">
      <v-col cols="12" justify="center" sm="6" class="text-center">
        <v-text-field
          v-model="search"
          rounded
          dense
          append-icon="mdi-magnify"
          :label="`Chercher ${getFiltered.length} taxons...`"
          single-line
          background-color="grey lighten-4"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn color="#2B2118" dark rounded @click="csvExport()">
          <v-icon left>mdi-file-download-outline</v-icon>
          Download table [.csv]
        </v-btn>
      </v-col>
    </v-row>

    <!-- DATA TABLE -->
    <v-row align-content="center" justify="center">
      <v-col cols="12" justify="center" md="12" lg="12" xl="9">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="getFiltered"
              :search="search"
              :sort-by="['id_taxon.id_family.family_name','id_taxon.taxon_name', 'authortaxon_name']"
              ><template slot="no-data">
                <v-progress-circular
                  indeterminate
                  color="#2B2118"
                ></v-progress-circular>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- LEGENDE -->
    <v-row align-content="center" justify="center">
      <v-col cols="12" justify="center" lg="9">
        <v-sheet elevation="4" color="transparent">
          <v-row align-content="center" justify="center">
            <v-col cols="12" justify="center" lg="10">
              <v-row align-content="center" justify="center">
                <v-col cols="12" justify="center" lg="12">
                  <div class="text-center">
                    <p class="text-h6">
                      <span style="font-weight: bold"
                        >Pollen habit legend:</span
                      >
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" justify="center" lg="4">
                  <div>
                    <v-item-group>
                      <v-item
                        v-for="p in splitToChunks(
                          (a = getPollenHabits),
                          (n = 3),
                          (balanced = true)
                        )[0]"
                        :key="p.id_pollen_habit"
                      >
                        <p>
                          {{ p.pollen_habit_code }} : {{ p.pollen_habit_name }}
                        </p>
                      </v-item>
                    </v-item-group>
                  </div>
                </v-col>
                <v-col cols="12" justify="center" lg="4">
                  <div>
                    <v-item-group>
                      <v-item
                        v-for="p in splitToChunks(
                          (a = getPollenHabits),
                          (n = 3),
                          (balanced = true)
                        )[1]"
                        :key="p.id_pollen_habit"
                      >
                        <p>
                          {{ p.pollen_habit_code }} : {{ p.pollen_habit_name }}
                        </p>
                      </v-item>
                    </v-item-group>
                  </div>
                </v-col>
                <v-col cols="12" justify="center" lg="4">
                  <div>
                    <v-item-group>
                      <v-item
                        v-for="p in splitToChunks(
                          (a = getPollenHabits),
                          (n = 3),
                          (balanced = true)
                        )[2]"
                        :key="p.id_pollen_habit"
                      >
                        <p>
                          {{ p.pollen_habit_code }} : {{ p.pollen_habit_name }}
                        </p>
                      </v-item>
                    </v-item-group>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TaxonDict",
  data: () => ({
    ncols: 3,
    info: false,
    search: "",
    headers: [
      {
        text: "Taxon (original name)",
        align: "start",
        value: "authortaxon_name",
      },
      {
        text: "Taxon (revised nomenclature)",
        value: "id_taxon.taxon_name",
      },
      {
        text: "Family",

        value: "id_taxon.id_family.family_name",
      },

      {
        text: "Pollen habit",
        value: "id_taxon.id_pollen_habit.pollen_habit_code",
      },
      {
        text: "Plant habit",
        filterable: false,
        value: "id_taxon.plant_habit",
      },
    ],
  }),

  methods: {
    ...mapActions([
      "fetchTaxons",
      "fetchAuthorTaxonsDeep",
      "fetchPollenHabits",
      "fetchHabitTypes",
      "fetchTaxonFamilies",
      "resetTaxonsFilters",
    ]),
    async loadData() {
      this.fetchHabitTypes();
      this.fetchPollenHabits();
      this.fetchTaxonFamilies();
      this.fetchTaxons();
      this.fetchAuthorTaxonsDeep();
    },
    resetFilters() {
      this.search = "";
      this.resetTaxonsFilters();
    },
    splitToChunks(a, n, balanced) {
      if (n < 2) return [a];

      var len = a.length,
        out = [],
        i = 0,
        size;

      if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
          out.push(a.slice(i, (i += size)));
        }
      } else if (balanced) {
        while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, (i += size)));
        }
      } else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0) size--;
        while (i < size * n) {
          out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
      }

      return out;
    },
    setFilterTaxons(e) {
      this.$store.commit("updateTaxonsFilters", {
        ...this.getTaxonsFilters,
        taxons: e,
      });
      this.$store.commit("applyTaxonsFilters");
    },
    setFilterAuthorTaxons(e) {
      this.$store.commit("updateTaxonsFilters", {
        ...this.getTaxonsFilters,
        authortaxons: e,
      });
      this.$store.commit("applyTaxonsFilters");
    },
    setFilterFamilies(e) {
      this.$store.commit("updateTaxonsFilters", {
        ...this.getTaxonsFilters,
        families: e,
      });
      this.$store.commit("applyTaxonsFilters");
    },
    setFilterPollenHabit(e) {
      this.$store.commit("updateTaxonsFilters", {
        ...this.getTaxonsFilters,
        pollen_habit: e,
      });
      this.$store.commit("applyTaxonsFilters");
    },
    csvExport() {
      let csvContent = "data:text/csv;charset=utf-8,";
      let csvData = this.getFiltered.map((pet) => ({
        "Taxon (original name)": pet.authortaxon_name,
        "Taxon (revised nomenclature)": pet.id_taxon.taxon_name,
        "Family name": pet.id_taxon.id_family.family_name,
        "Pollen habit (code)": pet.id_taxon.id_pollen_habit.pollen_habit_code,
        "Pollen habit (name)": pet.id_taxon.id_pollen_habit.pollen_habit_name,
        "Plant habit": pet.id_taxon.plant_habit,
      }));
      csvContent += [
        Object.keys(csvData[0]).join(";"),
        ...csvData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "APD_dictionnary_export.csv");
      link.click();
    },
  },

  computed: {
    ...mapGetters([
      "getTaxonsComplete",
      "getPollenHabits",
      "getTaxonFamilies",
      "getTaxons",
      "getTaxonsFilters",
      "getFiltered",
      "getAuthorTaxons",
    ]),
  },
  created() {
    this.loadData();
  },
};
</script>

<style>
</style>