import Api from "./axios"

const archiveService = {

    getArchives(project_ids=[])  {
        let projects=JSON.stringify(project_ids)
        return Api.get("/archives/", {params: {project_ids: projects}})

    },
    getArchive(archive_id) {
        return Api.get(`/archives/${archive_id}/`)
    }
}

export default archiveService;