import services from '@/services';

// Import necessary dependencies


function defaultRegionInfo() {
    return {
        name: '',
    }
}

function defaultSiteInfo() {
    return {
        name: '',
        properties: {},
        /* geo is a sample GeoJSON object */
        geo: {
            type: "Feature",
            geometry: {
                type: "Point",  
                coordinates: [0, 0, 0]
            },
        },
        region: null
    }
}

function defaultMeasurementTableInfo() {   
    return {
        data_collection: null,
        name: '',
        measurement_type: null,
        depth_unit: null,
        age_unit: null,
        depth_id: '',
        age_id: '',
        sample_name_id: '',
        model_id: '',
        optional_data: {},
    }
}





function setDefaultUserSelection() {
    return {
        isNewSite: false,
        datasetInfo: {
            data: {
                name: '',
                project: null,
                investigators: "",
                site: null,
                archive: null,
                pub: [],
                funding: [],
            },
            rules: {
                name: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 80) || 'Name must be less than 80 characters'
                ],
                site: [
                    v => !!v || 'Site is required'
                ],
                archive: [
                    v => !!v || 'Archive is required'
                ],
            },
            valid: false


        },

        siteInfo: {
            data: defaultSiteInfo(),
            rules: {
                name: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 80) || 'Name must be less than 80 characters'
                ],
                region: [
                    v => !!v || 'Region is required'
                ],
                latitude: [
                    v => !!v || 'Latitude is required',
                    v => (v && v >= -90 && v <= 90) || 'Latitude must be between -90 and 90'
                ],
                longitude: [
                    v => !!v || 'Longitude is required',
                    v => (v && v >= -180 && v <= 180) || 'Longitude must be between -180 and 180'
                ],
            }
        },

        regionInfo: {
            data: defaultRegionInfo(),
            rules: {
                name: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 80) || 'Name must be less than 80 characters'
                ],
            }
        },

        dataCollections: {
            data: [],
        },

        measurementTableInfo: {
            data: defaultMeasurementTableInfo(),
        },

        measurementTableColumns: {
            data: [

            ]

        },
        measurementTableSamples: {
            data: [{}]
        },

        editedTableColumns: [],
        editedTableData: [[]],

        isTableValid: true,
        areColumnsValid: true,

        isLoading: false,

    }
}

// Define the initial state
const state = {
    projects: [],
    regions: [],
    archives: [],
    sites: [],
    measurement_types: [],
    variables: [],
    materials: [],
    units: [],
    taxonomies:[],
    taxonomy_levels: [],
    /* assign defaultUserSelection to userSelection object */
    userSelection: setDefaultUserSelection(),
    // Add your initial state properties here
};

// Define the getters
const getters = {
    getProjects: state => state.projects,
    getRegions: state => state.regions,
    getArchives: state => state.archives,
    getSites: state => state.sites,
    getVariables: state => state.variables,
    getMaterials: state => state.materials,
    getUnits: state => state.units,

    getIsLoading: state => state.userSelection.isLoading,
    getTaxonomies: state => state.taxonomies,
    getTaxonomyLevels: state => state.taxonomy_levels,


    getUserSelection: state => state.userSelection,
    getDatasetInfoData: state => state.userSelection.datasetInfo.data,
    getDatasetInfoRules: state => state.userSelection.datasetInfo.rules,
    getDatasetInfoIsValid: state => state.userSelection.datasetInfo.valid,
    getSiteInfoData: state => state.userSelection.siteInfo.data,
    getSiteInfoRules: state => state.userSelection.siteInfo.rules,
    getSiteInfoIsValid: state => {
        let rules = state.userSelection.siteInfo.rules;
        let data = state.userSelection.siteInfo.data;
        for (let rule in rules) {
            for (let i = 0; i < rules[rule].length; i++) {
                if (!rules[rule][i](data[rule])) {
                    return false;
                }
            }
        }
        return true;
    },
    getRegionInfoData: state => state.userSelection.regionInfo.data,
    getIsNewSite: state => state.userSelection.isNewSite,
    getDataCollections: state => state.userSelection.dataCollections.data,

    getMeasurementTableInfo: state => state.userSelection.measurementTableInfo.data,
    getMeasurementTableColumns: state => state.userSelection.measurementTableColumns.data,
    getMeasurementTableSamples: state => state.userSelection.measurementTableSamples.data,


    getMeasurementTypes: state => state.measurement_types,

    getEditedTableColumns: state => state.userSelection.editedTableColumns,
    getEditedTableData: state => state.userSelection.editedTableData,


    getIsTableValid: state => state.userSelection.isTableValid,
    getAreColumnsValid: state => state.userSelection.areColumnsValid,






    // Add your getters here
};

// Define the actions
const actions = {
    async fetchProjects({ commit }, ) {
        try {
            const response = await services.project.getProjects();
            commit('setProjects', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchRegions({ commit }) {
        try {
            let options = {}
            const response = await services.region.getRegions(options);
            commit('setRegions', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchArchives({ commit }) {
        try {

            const response = await services.archive.getArchives();
            commit('setArchives', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchDatasetInfo({ commit }, dataset_id) {
        try {
            const response = await services.dataset.getDataset(dataset_id)
            commit('setDatasetInfo', response.data)
        } catch (error) {
            console.error(error)
        }
    },

    async fetchSites({ commit }) {
        try {
            const response = await services.site.getSites();
            commit('setSites', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchVariables({ commit }) {
        try {
            const response = await services.variable.getVariables();
            commit('setVariables', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchMaterials({ commit }) {
        try {
            let options = {}
            const response = await services.material.getMaterials(options);
            commit('setMaterials', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchTaxonomies({ commit }) {
        try {

            let options =  {
                with_ancestors: true,
            } 
            commit('setIsLoading', true);
            const response = await services.taxonomy.filterTaxonomiesPost(options);
            commit('setTaxonomies', response.data);
            commit('setIsLoading', false);
        } catch (error) {
            commit('setIsLoading', false);
            console.error(error);
        }
    },
    async fetchTaxonomyLevels({ commit }) {
        try {
            const response = await services.taxonomy.getTaxonomyLevels();
            commit('setTaxonomyLevels', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchUnits({ commit }) {
        try {
            const response = await services.unit.getUnits();
            commit('setUnits', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchSiteInfo({ commit }, site_id) {
        try {
            const response = await services.site.getSite(site_id);
            commit('setSiteInfo', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchRegionInfo({ commit }, region_id) {
        try {
            const response = await services.region.getRegion(region_id);
            commit('setRegionInfo', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchDataCollections({ commit }, dataset_id) {
        try {
            const response = await services.data_collection.getDatasetDataCollections(dataset_id, true);
            commit('setDataCollections', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async createSite({ commit }) {
        try {
            let data = state.userSelection.siteInfo.data;
            
            const response = await services.site.createSite(data);
            commit('setSiteInfo', response.data);
            commit('setDatasetSite', response.data.site_id)

        } catch (error) {
            console.error(error);
        }
    },
    async createDataset({ commit }) {
        try {
            let data = state.userSelection.datasetInfo.data;
            const response = await services.dataset.createDataset(data);
            commit('setDatasetInfo', response.data)
        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async createDataCollection({ }, data) {
        try {

            await services.data_collection.createDataCollection(data);
            /* commit('setDataCollections', response.data) */
        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async deleteDataCollection({ }, dataCollectionId) {
        try {
            await services.data_collection.deleteDataCollection(dataCollectionId);

        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async createMeasurementTable({ }, data) {
        try {
            await services.measurement_table.createMeasurementTable(data);
        } catch (error) {
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async deleteMeasurementTable({ }, measurementTableId) {
        try {
            await services.measurement_table.deleteMeasurementTable(measurementTableId);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchMeasurementTableInfo({ commit }, measurementTableId) {
        try {
            const response = await services.measurement_table.getMeasurementTable(measurementTableId);
            commit('setMeasurementTableInfo', response.data);
        } catch (error) {
            console.error(error);
        }
    },

    async fetchMeasurementTypes({ commit }) {
        try {
            const response = await services.measurement_type.getMeasurementTypes();
            commit('setMeasurementTypes', response.data);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchMeasurementTableColumns({ commit }, measurementTableId) {
        try {
            commit('setIsLoading', true);
            const response = await services.measurement_table.getMeasurementTableColumns(measurementTableId);
            commit('setMeasurementTableColumns', response.data);
            commit('setEditedTableColumns', response.data);
            commit('setIsLoading', false);
        } catch (error) {
            commit('setIsLoading', false);
            console.error(error);
        }
    },
    async fetchMeasurementTableSamples({ commit }, measurementTableId) {
        try {
            commit('setIsLoading', true);
            const response = await services.measurement_table.getMeasurementTableData(measurementTableId);
            commit('setMeasurementTableSamples', response.data.data);
            commit('setIsLoading', false);
        } catch (error) {
            commit('setIsLoading', false);
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async updateDataCollection({  }, data) {
        try {
            await services.data_collection.updateDataCollection(data.data_collection_id, data);
        } catch (error) {
            console.error(error);
        }
    },
    setDatasetInfoData({ commit }, data) {
        commit('setDatasetInfo', data);
    },
    setIsLoading({ commit }, isLoading) {
        commit('setIsLoading', isLoading);
    },

    async updateDatasetInfo({ commit }){
        try {
            let data = state.userSelection.datasetInfo.data;
            let dataset_id = data.dataset_id;
            const response = await services.dataset.updateDataset(dataset_id,data);
            commit('setDatasetInfo', response.data)
        } catch (error) {
            console.error(error);
        }
    },
    async updateMeasurementTableInfo({ commit }, data) {
        try {
            commit('setIsLoading', true);
            let measurementTableId = data.measurement_table_id;
            const response = await services.measurement_table.updateMeasurementTable(measurementTableId, data);
            commit('setMeasurementTableInfo', response.data);
            commit('setIsLoading', false);
        } catch (error) {
            commit('setIsLoading', false);
            console.error(error);
        }

    },
    // eslint-disable-next-line
    async updateMeasurementTableData({commit}, data) {
        try {
            commit('setIsLoading', true);
            let measurementTableId = data.measurement_table_id;
            await services.measurement_table.updateMeasurementTableData(measurementTableId, data);
            commit('setIsLoading', false);
        } catch (error) {
            commit('setIsLoading', false);
            console.error(error);
        }
    },
    // eslint-disable-next-line
    async updateColumn({  }, data) {
        try {
            let columnId = data.column_id;
            await services.column.updateColumn(columnId, data);
        } catch (error) {
            console.error(error);
        }
    },

    // eslint-disable-next-line
    async createTaxonomy({ }, data) {
        try {
            await services.taxonomy.addTaxonomy(data);
        } catch (error) {
            console.error(error);
        }
    },

    setIsTableValid({ commit }, isValid) {
        commit('setIsTableValid', isValid);
    },
    setAreColumnsValid({ commit }, isValid) {
        commit('setAreColumnsValid', isValid);
    },

    resetUserSelection({ commit }) {
        console.log('resetting user selection')
        commit('resetUserSelection');
        console.log('user selection reset')
    },
    resetSiteInfo({ commit }) {
        commit('resetSiteInfo');
    },
    resetRegionInfo({ commit }) {
        commit('resetRegionInfo');
    },
    setDatasetInfoIsValid({ commit }, isValid) {
        commit('setDatasetInfoIsValid', isValid);
    },
    setIsNewSite({ commit }, isNewSite) {
        commit('setIsNewSite', isNewSite);
    }
    // Add your actions here
};

// Define the mutations
const mutations = {
    setProjects: (state, projects) => state.projects = projects,
    setRegions: (state, regions) => state.regions = regions,
    setArchives: (state, archives) => state.archives = archives,
    setSites: (state, sites) => state.sites = sites,
    setSiteInfo: (state, site) => state.userSelection.siteInfo.data = site,
    setRegionInfo: (state, region) => state.userSelection.regionInfo.data = region,
    resetSiteInfo: (state) => state.userSelection.siteInfo.data = defaultSiteInfo(),
    resetRegionInfo: (state) => state.userSelection.regionInfo.data = defaultRegionInfo(),
    setDatasetInfo: (state, dataset) => state.userSelection.datasetInfo.data = dataset,
    setDatasetInfoIsValid: (state, isValid) => state.userSelection.datasetInfo.valid = isValid,
    setDatasetSite: (state, site) => state.userSelection.datasetInfo.data.site = site,
    setIsNewSite: (state, isNewSite) => state.userSelection.isNewSite = isNewSite,
    setDataCollections: (state, dataCollections) => state.userSelection.dataCollections.data = dataCollections,
    setMeasurementTableInfo: (state, measurementTable) => state.userSelection.measurementTableInfo.data = measurementTable,
    setMeasurementTypes: (state, measurementTypes) => state.measurement_types = measurementTypes,
    setMeasurementTableColumns: (state, columns) => state.userSelection.measurementTableColumns.data = columns,
    setMeasurementTableSamples: (state, samples) => state.userSelection.measurementTableSamples.data = samples,
    setMaterials: (state, materials) => state.materials = materials,
    setVariables: (state, variables) => state.variables = variables,
    setUnits: (state, units) => state.units = units,
    setEditedTableColumns: (state, columns) => state.userSelection.editedTableColumns = columns,
    setEditedTableData: (state, data) => state.userSelection.editedTableData = data,
    /* define a whole function for resetUserSelection */
    resetUserSelection: (state) => {
        console.log('before:', state.userSelection)

        state.userSelection = setDefaultUserSelection();
        console.log('after:', state.userSelection)
    },
    setIsTableValid: (state, isValid) => state.userSelection.isTableValid = isValid,
    setAreColumnsValid: (state, isValid) => state.userSelection.areColumnsValid = isValid,
    // Add your mutations here
    setIsLoading: (state, isLoading) => state.userSelection.isLoading = isLoading,
    setTaxonomies: (state, taxonomies) => state.taxonomies = taxonomies,
    setTaxonomyLevels: (state, taxonomy_levels) => state.taxonomy_levels = taxonomy_levels
};

// Export the module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};